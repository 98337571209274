import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import { withTranslation, TFunction } from "react-i18next";
import {
  Alert,
  Button,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Form,
} from "react-bootstrap";
import {
  GearWideConnected,
  ShieldLock,
} from "react-bootstrap-icons";

import { IServerResponse, Role, SecObject, Action } from "../../../interfaces";
import useRoleService from "../../../services/role.service";
import useLocalStorage from "../../../hook/useLocalStorage";

interface PermissionManagerProps {
  Actions: Array<Action.IAction>;
  SecObject: SecObject.IObject;
  children?: React.ReactNode;
  Role: Role.IRole;
  t: TFunction;
}

const PermissionManager = ({
  Actions,
  SecObject,
  Role,
  t,
}: PermissionManagerProps) => {
  const { storedValue: userHierarchy } = useLocalStorage<Role.Hierarchy>(
    "UserHierarchy",
    {}
  );

  const {
    getAllObjectPermissions,
    getRoleObjectPermissions,
    updateRoleObjectPermissions,
  } = useRoleService();

  const [objectPermissions, setObjectPermissions] = useState<
    Array<SecObject.IObjectPermission>
  >([]);
  const [rolePermissions, setRolePermissions] = useState<number>(0);

  const [modifiedPermissions, setModifiedPermissions] =
    useState<number>(rolePermissions);

  const getHierarchyTable = useCallback(
    (item, schema, action) => {
      // | roleId | position | item  | schema | action | hasPermission |
      // | ------ | -------- | ----- | ------ | ------ | ------------ |
      // | 1      | 23       | user  | system | read   | true        |
      // | 2      | 22       | cat   | public | read   | true        |
      // | 3      | 21       | time  | system | read   | true        |

      // ? Build the hierarchy table
      const hierarchyTable: Array<Array<string | number | boolean>> = [];
      for (const role of userHierarchy["names"]) {
        const roleId = userHierarchy["roles"][role].id;
        const position = userHierarchy["roles"][role].position;

        userHierarchy["permissions"][role].forEach((permission) => {
          hierarchyTable.push([
            Number(roleId),
            Number(position),
            permission.object_item,
            permission.object_type,
            permission.action_item,
            permission.active,
          ]);
        });
      }

      let filter: Array<Role.HierarchyTableRow> = [];

      hierarchyTable
        // ? Filter the hierarchy table by the item, schema and action
        .filter(
          (row) =>
            row[2].toString() === item &&
            row[3].toString() === schema &&
            row[4].toString() === action
        )

        // ? Sort the hierarchy table by the position
        .sort((a, b) => Number(a[1]) - Number(b[1]))

        // ? Return filtered hierarchy table
        .forEach((row) =>
          filter.push({
            roleId: Number(row[0]),
            item: row[2].toString(),
            position: Number(row[1]),
            schema: row[3].toString(),
            action: row[4].toString(),
            hasPermission: Boolean(row[5]),
          })
        );

      return filter;
    },
    [userHierarchy]
  );

  useEffect(() => {
    getAllObjectPermissions<IServerResponse<SecObject.IObjectPermission>>({
      params: SecObject,
    })
      .then((r) => r.data)
      .then(setObjectPermissions)
      .catch((e: Error) => {
        setObjectPermissions([]);
        console.error(e);
      });

    getRoleObjectPermissions<IServerResponse<Role.IRolePermission>>({
      params: {
        roleId: Role.id,
        type: SecObject.type,
        item: SecObject.item,
      },
    })
      .then((r) => r.data[0])
      .then((r) => Number(r.permissions))
      .then(setRolePermissions)
      .catch((e: Error) => {
        setRolePermissions(0);
        console.error(e);
      });
  }, [
    Actions,
    SecObject,
    Role,
    getAllObjectPermissions,
    getRoleObjectPermissions,
  ]);

  useEffect(() => {
    setModifiedPermissions(rolePermissions);
  }, [rolePermissions]);

  const handleSubmitChanges = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (modifiedPermissions !== rolePermissions) {
        updateRoleObjectPermissions<
          IServerResponse<SecObject.IObjectPermission>
        >({
          params: {
            roleId: Role.id,
            type: SecObject.type,
            item: SecObject.item,
            permissions: modifiedPermissions,
          },
        })
          .then((r) => r.data)
          .then((response) => {
            setObjectPermissions(
              (previous: Array<SecObject.IObjectPermission>) => {
                return previous.map((permissions) => {
                  const newPermission = response.find(
                    (r: SecObject.IObjectPermission) => r.id === permissions.id
                  );

                  if (newPermission) {
                    return newPermission;
                  }

                  return permissions;
                });
              }
            );
            setRolePermissions(modifiedPermissions);
            setModifiedPermissions(rolePermissions);
          })
          .catch((e: Error) => {
            console.error(e);
          });
      }
    },
    [
      Role.id,
      SecObject.item,
      SecObject.type,
      modifiedPermissions,
      rolePermissions,
      updateRoleObjectPermissions,
    ]
  );

  const actions = useMemo(() => {
    const perms: Array<{
      modifiable: boolean;
      hasPermission: boolean;
      action: Action.IAction;
      permission: SecObject.IObjectPermission;
    }> = [];

    Actions.filter((action) => {
      const permission = objectPermissions.find(
        (p) => Number(p.action_id) === Number(action.id)
      );

      if (!permission) {
        return false;
      }

      return true;
    }).forEach((action) => {
      let hasPermission: number = Number(modifiedPermissions);
      hasPermission &= Number(action.id);

      const permission = objectPermissions.find(
        (p) => Number(p.action_id) === Number(action.id)
      );

      if (!permission) {
        return false;
      }

      const hTable = getHierarchyTable(
        SecObject.item,
        SecObject.type,
        action.item
      );

      const [topRole] = hTable.filter((row) => row.hasPermission);

      if (topRole) {
        // ? If the top role has the permission, then the action is not modifiable
        perms.push({
          modifiable:
            Number(topRole.roleId) !== Number(Role.id) &&
            Number(topRole.position) > Number(Role.position),
          hasPermission: Boolean(hasPermission),
          permission,
          action,
        });
      } else {
        perms.push({
          modifiable: false,
          hasPermission: Boolean(hasPermission),
          permission,
          action,
        });
      }
    });

    return perms;
  }, [
    Actions,
    Role,
    SecObject.item,
    SecObject.type,
    getHierarchyTable,
    modifiedPermissions,
    objectPermissions,
  ]);

  return (
    <Fragment>
      {modifiedPermissions !== rolePermissions && (
        <Alert variant="dark" className="mt-2 py-2">
          <Row>
            <Col
              xxl={9}
              xl={8}
              lg={7}
              md={6}
              sm={12}
              xs={12}
              className="d-flex align-items-center"
            >
              <span>{t("pending_changes")}</span>
            </Col>
            <Col
              xxl={3}
              xl={4}
              lg={5}
              md={6}
              sm={12}
              xs={12}
              className="d-flex align-items-center"
            >
              <Button
                type="submit"
                variant="success"
                className="m-0 w-100"
                onClick={handleSubmitChanges}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Alert>
      )}
      {actions.map((action) => {
        return (
          <Alert
            key={action.permission.id}
            variant={action.hasPermission ? "info" : "dark"}
            className="my-2 p-2"
          >
            <Alert.Heading className="p-1">
              <Row className="d-flex justify-content-between">
                <Col className="d-flex justify-content-start">
                  {action.action.item}
                </Col>
                <Col className="d-flex justify-content-end">
                  {/* If the action is not modifiable, then the checkbox is disabled and lock icon is shown */}
                  <Form.Switch
                    type="checkbox"
                    checked={action.hasPermission}
                    name={`role.permission.${action.permission.id}`}
                    onChange={(e: any) => {
                      const newPermissions =
                        Number(modifiedPermissions) ^ Number(action.action.id);
                      setModifiedPermissions(newPermissions);
                    }}
                    disabled={!action.modifiable}
                    label={
                      !action.modifiable ? (
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              {t("permission_not_modifiable")}
                            </Tooltip>
                          }
                        >
                          <ShieldLock />
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              {t("click_to_toggle_permission")}
                            </Tooltip>
                          }
                        >
                          <GearWideConnected />
                        </OverlayTrigger>
                      )
                    }
                  />
                </Col>
              </Row>
            </Alert.Heading>
            <p>{action.permission.description}</p>
            <hr />
            <p>
              {t("from_object")} {t(SecObject.item)}
            </p>
          </Alert>
        );
      })}
    </Fragment>
  );
};

export default withTranslation()(PermissionManager);
