import { useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { withTranslation, TFunction } from "react-i18next";
import { Role, User } from "src/interfaces";

interface RoleActionsProps {
  Role: Role.IRole;
  onRoleDelete: (role: Role.IRole) => void;
  t: TFunction;
  members: User.IUser[];
}

const RoleActions = ({ t, Role, onRoleDelete, members }: RoleActionsProps) => {
  const deleteRoleAction = useCallback(() => {
    if (members.length === 0) onRoleDelete(Role);
  }, [Role, members.length, onRoleDelete]);

  return (
    <Container fluid className="p-4">
      <Row className="p-1">
        <Col xxl={10} xl={9} lg={8} md={7} sm={12} xs={12}>
          <div className="w-100">{t("roles.role_delete")}</div>
        </Col>
        <Col xxl={2} xl={3} lg={4} md={5} sm={12} xs={12}>
          <Button
            variant="primary"
            className="w-100"
            onClick={deleteRoleAction}
            disabled={members.length !== 0}
            children={t("label.action.delete", { ns: "application.misc" })}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation()(RoleActions);
