import React, { useRef, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { GridOrder } from "../../../../../models/Admin/GridOrder";
import useDomainsService from "../../../../../services/domains.service";
import MoreButton from "./MoreButton";

export default function Domain(props: any) {
  const { t } = useTranslation();
  const id = useRef<number>(props.id);
  const { getOne } = useDomainsService();
  const [showsubdomains, setShowsubdomains] = useState<boolean>(false);
  const [subdomains, setSubdomains] = useState([]);

  useEffect(() => {
    if (!showsubdomains) {
      return;
    }
    getOne({
      fn: (data: any) => {
        setSubdomains(data.data.subDomains);
      },
      id: id.current,
    });
  }, [getOne, showsubdomains]);

  function handleCheck(domainId: number, checked?: boolean) {
    props.onCheck(domainId, checked);
  }
  const previousNestedLevel = props.nestedLeveL ?? 1;
  const nestedLevel = previousNestedLevel + 1;
  const gridOrder = useMemo(() => new GridOrder(nestedLevel), [nestedLevel]);
  let areSubdomainsPresent = false;

  if (subdomains && showsubdomains && subdomains.length)
    areSubdomainsPresent = true;
  return (
    <React.Fragment>
      <tr
        key={id.current}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(12,1fr)",
        }}
      >
        <td style={props.gridOrder.active}>
          <div style={{ display: "flex" }}>
            <MoreButton
              onClick={() => {
                setShowsubdomains((previousValue: boolean) => !previousValue);
              }}
            ></MoreButton>
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              checked={props.checked}
              onChange={(e: any) => {
                const checked = e.target.checked;
                props.onCheck(id.current, checked);
              }}
              disabled={props.disabled}
            />
          </div>
        </td>
        <td style={props.gridOrder.name}>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            {props.name}
          </label>
        </td>
        <td style={props.gridOrder.description}>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            {props.description}
          </label>
        </td>
      </tr>

      <div>
        {areSubdomainsPresent && (
          <tr
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(12,1fr)",
            }}
          >
            <th style={gridOrder.active}>{t("active")}</th>
            <th style={gridOrder.name}>{t("name")}</th>
            <th style={gridOrder.description}>{t("description")}</th>
          </tr>
        )}
        {areSubdomainsPresent &&
          subdomains.map((subdomain: any) => {
            const checked = props.userDomains.find(
              (userDomain: number) => userDomain === subdomain.id
            );
            return (
              <Domain
                {...subdomain}
                checked={checked}
                onCheck={handleCheck}
                updateDomains={props.updateDomains}
                userDomains={props.userDomains}
                nestedLeveL={nestedLevel}
                gridOrder={gridOrder}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
}
