import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface DomainState {
    id: string;
    name: string;
}

const domainLS = localStorage.getItem('domain') ? JSON.parse(localStorage.getItem('domain') || '') : {id: '', name: ''};

const initialState: DomainState = domainLS;



export const domainSlice = createSlice({
    name: 'domain',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<DomainState>) => {
            state.name = action.payload.name;
            state.id = action.payload.id;
                    
        }
    }
})


export const { update } = domainSlice.actions;

export const selectDomain = (state: RootState) => state.domain;

export default domainSlice.reducer;
