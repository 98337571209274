import { FC, useCallback, useState, useEffect } from "react";
import { TFunction, withTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PersonUser from "src/models/Config/User/PersonUser";
import { useFormPerson } from "../Form";
import UserItem from "./UserItem";

interface UsersManagerProps {
  users: Array<PersonUser>;
  contact_id: number;
  t: TFunction;
  [key: string]: any;
}

const UsersManager: FC<UsersManagerProps> = ({ contact_id, t }) => {
  const [users, setUsers] = useState<Array<PersonUser>>([]);
  const { users: list } = useFormPerson();

  const addUserToPerson = useCallback(() => {
    const u = new PersonUser({ contact_id });
    setUsers((users) => [...users, u]);
  }, [contact_id]);

  useEffect(() => {
    if (list instanceof Array) {
      const users: Array<PersonUser> = [];
      list.forEach((item) => {
        const u = new PersonUser(item);
        u.setSaved(true);
        users.push(u);
      });
      setUsers((prev) => [...users]);
    }
  }, [list]);

  return (
    <Container fluid>
      {users.map((user, index) => (
        <Row key={user.id || index + "index"}>
          <UserItem user={user} key={user.id || index + "index"} />
        </Row>
      ))}
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <Button variant="outline-primary" onClick={addUserToPerson}>
            {t("label.action.add", { ns: "application.misc" })}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation()(UsersManager);
