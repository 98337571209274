import { useCallback, useMemo } from "react";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Eye as IconEye } from "react-bootstrap-icons";

import useMenuSettings from "../../../hook/useMenuSettings";

export default function Home() {
  const menuSettings = useMenuSettings();
  const { t, i18n } = useTranslation();

  const loadEntityNamespace = useCallback(
    (bundle: string = "*") => {
      const ns = "entities." + bundle;
      if (!i18n.hasResourceBundle(i18n.language, ns)) {
        i18n.loadNamespaces(ns);
      }
      return ns;
    },
    [i18n]
  );

  const cards = useMemo<Array<JSX.Element>>(() => {
    if (menuSettings && menuSettings.data.main_menu.entities) {
      if (menuSettings.data.main_menu.entities.length === 0) {
        return [
          <Col key="home.no_entities" className="d-flex justify-content-center">
            {t("home.no_entities")}
          </Col>,
        ];
      }

      return menuSettings.data.main_menu.entities.map((item, index) => (
        <Col xxl={2} xl={3} lg={4} md={6} sm={12} key={index}>
          <Card className="p-1 m-1 shadow rounded">
            <Card.Header>
              <Link
                to={`/${item.base_entity.object_name}`}
                style={{ textDecoration: "none" }}
              >
                <span className="text-truncate">
                  {capitalize(
                    t("label", {
                      ns: loadEntityNamespace(item.base_entity.object_name),
                      defaultValue: item.base_entity.object_name.replace(
                        /_/g,
                        " "
                      ),
                    })
                  )}
                  {item.base_entity.use_adapter
                    ? `(${item.base_entity.datasource})`
                    : ""}
                </span>
                <IconEye style={{ float: "right" }} />
              </Link>
            </Card.Header>
            <Card.Body>
              <Form.Select
                defaultValue="default_selection_menu_items"
                onChange={(event: any): void => {
                  event.preventDefault();
                  // TODO: Menu selection actions, render routes after selection.
                }}
              >
                <option value="default_selection_menu_items" hidden>
                  {capitalize(t("home.select_action", { count: 1 }))}
                </option>
                {item.menu_items.map((itemMenu, index: number): JSX.Element => {
                  // TODO: Menu selection actions and translation, render routes after selection.
                  return (
                    <option key={index}>
                      {capitalize(
                        t(
                          "entities.actions.".concat(itemMenu.list.url_action),
                          {
                            defaultValue: itemMenu.list.url_action.replaceAll(
                              "_",
                              " "
                            ),
                          }
                        )
                      )}
                    </option>
                  );
                })}
              </Form.Select>
            </Card.Body>
          </Card>
        </Col>
      ));
    }

    return [
      <Col
        key="home.loading_entities"
        className="d-flex justify-content-center"
      >
        <Spinner animation="border" />
      </Col>,
    ];
  }, [menuSettings, t, loadEntityNamespace]);

  return (
    <Container fluid className="p-4">
      <Row>
        <h1>{t("home.home")}</h1>
        <h6>{t("home.welcome")}</h6>
      </Row>
      <hr />
      <Row>{cards}</Row>
    </Container>
  );
}
