import { Button, Col, Container, Row } from "react-bootstrap";
import Table from "rsuite/Table";
import { Trash } from "react-bootstrap-icons";
import { UsersByDomainType } from "./types";
import useUserService from "../../../../services/users.service";
import useDomainsService from "src/services/domains.service";
import { useCallback, useEffect, useState } from "react";
import { IServerResponse, User } from "../../../../interfaces";
import ModalForm from "src/components/UI/Modal/ModalForm/ModalForm";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { setUsersByDomain } from "src/reducers/domain/domains";
import { withTranslation, TFunction } from "react-i18next";

interface UsersByDomainProps {
  users: UsersByDomainType[];
  t: TFunction;
}

interface UsersModalProps {
  showUserModal: boolean;
  handleClose: () => void;
  users: User.IUser[];
  onRowClick: (row: any) => void;
  t: TFunction;
}

interface DeleteUserModalProps {
  showDeleteUserModal: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  t: TFunction;
}

const DeleteUserModal = ({ showDeleteUserModal = false, handleCancel, handleConfirm, t }: DeleteUserModalProps) => {
  const modalFooter = (
    <>
      <Button variant="tertiary" onClick={handleCancel}>{t("label.action.cancel", { ns: "application.misc" })}</Button>
      <Button onClick={handleConfirm}>{t("label.action.delete", { ns: "application.misc" })}</Button>
    </>
  );
  return (
    <ModalForm
      show={showDeleteUserModal}
      header={t("domains.users_delete_modal.users_delete_modal_title")}
      handleClose={handleCancel}
      footer={modalFooter}
    >
      {t("domains.users_delete_modal.users_delete_modal_message")}
    </ModalForm>
  );
};

const UsersModal = ({ showUserModal = false, handleClose, users, onRowClick, t }: UsersModalProps) => (
  <ModalForm
    show={showUserModal}
    header={t("domains.users_modal.users_modal_title")}
    handleClose={handleClose}
    size="lg"
  >
    <Table data={users} autoHeight onRowClick={onRowClick}>
      <Table.Column align="center" fixed>
        <Table.HeaderCell>{t("domains.users_table.id")}</Table.HeaderCell>
        <Table.Cell dataKey="id" />
      </Table.Column>
      <Table.Column width={150} sortable>
        <Table.HeaderCell>{t("domains.users_table.username")}</Table.HeaderCell>
        <Table.Cell dataKey="username" />
      </Table.Column>
      <Table.Column width={250} sortable>
        <Table.HeaderCell>{t("domains.users_table.email")}</Table.HeaderCell>
        <Table.Cell dataKey="email" />
      </Table.Column>
    </Table>
  </ModalForm>
);

const UsersByDomain = ({ users, t }: UsersByDomainProps) => {
  const dispatch = useAppDispatch();
  const { domainIdSelected } = useAppSelector(state => state.domains);
  const [allActiveUsers, setUsers] = useState<User.IUser[]>([]);
  const [isUsersModalOpen, setUsersModalOpen] = useState<boolean>(false);
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | number | null>();
  const { getAllActiveUsers } = useUserService();
  const { setUserByDomain, deleteUserByDomain } = useDomainsService();

  const handleAddUserClick = useCallback(() => {
    getAllActiveUsers<IServerResponse<User.IUser>>({})
      .then(r => r.data)
      .then((users) => {
        setUsers(users);
      })
      .catch((e: Error) => {
        setUsers([]);
        setUsersModalOpen(false);
        console.error(e);
      });
  }, [getAllActiveUsers]);

  useEffect(() => {
    if (allActiveUsers.length) {
      setUsersModalOpen(true);
    }
  }, [allActiveUsers]);

  const onRowClick = useCallback((row) => {
    setUserByDomain({
      fn: ({ data }: any) => {
        dispatch(setUsersByDomain(data));
        setUsersModalOpen(false);
      },
      domain: domainIdSelected,
      user: row.id
    })
  }, [setUserByDomain, domainIdSelected, dispatch]);

  const handleUsersModalCancel = () => {
    setUsersModalOpen(false);
    setUsers([]);
  };

  const handleDeleteUserModalCancel = () => {
    setDeleteUserModalOpen(false);
    setUserId(null);
  };

  const handleDeleteUserConfirm = useCallback(() => {
    deleteUserByDomain({
      fn: ({ data }: any) => {
        dispatch(setUsersByDomain(data));
        handleDeleteUserModalCancel();
      },
      domain: domainIdSelected,
      user: userId
    });
  }, [domainIdSelected, dispatch, deleteUserByDomain, userId]);

  const ActionsButtons = ({ rowData, dataKey, ...props }: any) => {
    const handleOnClick = () => {
      setDeleteUserModalOpen(true);
      setUserId(rowData.id);
    };
    return (
      <Table.Cell {...props} style={{padding: '8px'}}>
        <Button onClick={handleOnClick} title={t("label.action.delete", { ns: "application.misc" })} size="sm"><Trash /></Button>
      </Table.Cell>
    );
  };

  return (
    <Container fluid className="mt-5">
      <Row className="d-flex align-items-center mb-3">
        <Col xs={12} sm={9}><h2>{t("domains.users_title")}</h2></Col>
        <Col xs={12} sm={3} className="d-flex justify-content-end"><Button onClick={() => handleAddUserClick()}>{t("domains.action.add_user")}</Button></Col>
      </Row>
      <Row>
        <Col>
          {users?.length ? (
            <Table data={users} autoHeight>
              <Table.Column align="center" fixed>
                <Table.HeaderCell>{t("domains.users_table.id")}</Table.HeaderCell>
                <Table.Cell dataKey="id" />
              </Table.Column>
              <Table.Column width={150} sortable>
                <Table.HeaderCell>{t("domains.users_table.username")}</Table.HeaderCell>
                <Table.Cell dataKey="username" />
              </Table.Column>
              <Table.Column width={150} sortable>
                <Table.HeaderCell>{t("domains.users_table.first_name")}</Table.HeaderCell>
                <Table.Cell dataKey="firstName" />
              </Table.Column>
              <Table.Column width={150} sortable>
                <Table.HeaderCell>{t("domains.users_table.last_name")}</Table.HeaderCell>
                <Table.Cell dataKey="lastName" />
              </Table.Column>
              <Table.Column width={250} sortable>
                <Table.HeaderCell>{t("domains.users_table.email")}</Table.HeaderCell>
                <Table.Cell dataKey="email" />
              </Table.Column>
              <Table.Column fixed="right">
                <Table.HeaderCell>{t("domains.users_table.actions")}</Table.HeaderCell>
                <ActionsButtons dataKey="id" />
              </Table.Column>
            </Table>
          ) : t("domains.users_table.no_data")}
        </Col>
      </Row>
      <UsersModal
        showUserModal={isUsersModalOpen}
        handleClose={handleUsersModalCancel}
        users={allActiveUsers}
        onRowClick={onRowClick}
        t={t}
      />
      <DeleteUserModal
        showDeleteUserModal={isDeleteUserModalOpen}
        handleCancel={handleDeleteUserModalCancel}
        handleConfirm={handleDeleteUserConfirm}
        t={t}
      />
    </Container>
  );
};

export default withTranslation()(UsersByDomain);
