import { useEffect, useState, useCallback, FormEvent, useRef } from "react";
import { withTranslation, TFunction } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";

import { parseDotStringToObject } from "../../../../utils/utils";
import { useAuth } from "../../../../hook/useAuth";
import RoleList from "../../../UI/RoleEditor/RoleList";
import useRoleService from "../../../../services/role.service";
import useLocalStorage from "../../../../hook/useLocalStorage";
import MemberManager from "../../../UI/RoleEditor/MemberManager";
import PermissionManager from "../../../UI/RoleEditor/PermissionManager";
import {
  IServerResponse,
  Role,
  SecObject,
  Action,
  User,
} from "../../../../interfaces";
import DomainsByRole from "./DomainsByRole";
import RoleActions from "src/components/UI/RoleEditor/RoleActions";

interface RolesProps {
  t: TFunction;
}

const Roles = ({ t }: RolesProps) => {
  const { storeValue: setUserHierarchy } = useLocalStorage<Role.Hierarchy>(
    "UserHierarchy",
    {}
  );
  const { user } = useAuth();
  const previousUserRef = useRef(user);

  const {
    createRole,
    deleteRole,
    updateRole,
    getAllRoles,
    getAllObjects,
    getAllActions,
    getUsersFromRole,
  } = useRoleService();

  const [currentObject, setCurrentObject] = useState<SecObject.IObject | null>(
    null
  );
  const [listOfObjects, setListOfObjects] = useState<Array<SecObject.IObject>>(
    []
  );
  const [listOfActions, setListOfActions] = useState<Array<Action.IAction>>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<Role.IRole | null>(null);
  const [listOfRoles, setListOfRoles] = useState<Array<Role.IRole>>([]);
  const [members, setMembers] = useState<User.IUser[]>([]);

  const getRolesPaginated = useCallback(
    (page: number, limit: number) => {
      getAllRoles<IServerResponse<Role.IRole>>({
        page,
        limit,
      })
        .then((r) => {
          if (r.pagination.nextPage) {
            getRolesPaginated(r.pagination.nextPage, limit);
          }
          return r.data;
        })
        .then((d) => setListOfRoles((p) => [...p, ...d]))
        .catch((e: Error) => {
          setListOfRoles([]);
          console.error(e);
        });
    },
    [getAllRoles, setListOfRoles]
  );

  useEffect(() => {
    if (user.active && previousUserRef.current !== user) {
      setUserHierarchy<Role.Hierarchy>(user.roles || ({} as Role.Hierarchy));
      previousUserRef.current = user;
    }
  }, [user, setUserHierarchy]);

  useEffect(() => {
    getRolesPaginated(0, 0);

    getAllObjects<IServerResponse<SecObject.IObject>>({})
      .then((r) => r.data)
      .then((d) => setListOfObjects(d))
      .catch((e: Error) => {
        setListOfObjects([]);
        console.error(e);
      });

    getAllActions<IServerResponse<Action.IAction>>({})
      .then((r) => r.data)
      .then((d) => setListOfActions(d))
      .catch((e: Error) => {
        setListOfActions([]);
        console.error(e);
      });
  }, [getAllActions, getAllObjects, getRolesPaginated]);

  useEffect(() => {
    if (currentRole) {
      if (currentRole.id !== 0) {
        const role = listOfRoles.find(
          (r: Role.IRole) => r.id === currentRole.id
        );
        if (role && !isEqual(role, currentRole)) {
          setUnsavedChanges(true);
        } else {
          setUnsavedChanges(false);
        }
      }
    }
  }, [currentRole, listOfRoles]);

  const handleCreateRoleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.target as HTMLFormElement);
      const formParams = new URLSearchParams(formData as any);

      const { role } = parseDotStringToObject(
        Array.from(formParams.entries()).map(([key, value]) => ({
          path: key,
          value,
        }))
      );

      await createRole<IServerResponse<Role.IRole>>({
        params: role,
      });
      setListOfRoles([]);
      getRolesPaginated(0, 0);
      setUnsavedChanges(false);
    },
    [createRole, getRolesPaginated]
  );

  const handleOnClickRoleDeref = useCallback(
    (role: Role.IRole) => setCurrentRole(cloneDeep<Role.IRole>(role)),
    [setCurrentRole]
  );

  const handleOnUpdateRoleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.target as HTMLFormElement);
      const formParams = new URLSearchParams(formData as any);

      const { role } = parseDotStringToObject(
        Array.from(formParams.entries()).map(([key, value]) => ({
          path: key,
          value,
        }))
      );

      await updateRole<IServerResponse<Role.IRole>>({
        params: role,
      });
      setListOfRoles([]);
      getRolesPaginated(0, 0);
      setUnsavedChanges(false);
    },
    [getRolesPaginated, updateRole]
  );

  const handleOnRoleDragEnd = useCallback(
    async ({ source, destination }: DropResult) => {
      try {
        if (source == null || destination == null) {
          throw new Error("Source or destination is null");
        }

        let list: Array<Role.IRole> = [...listOfRoles];
        const item: Role.IRole = list.splice(source.index, 1)[0];
        list.splice(destination.index, 0, item);

        list = list.reverse();
        list = list.map((r: Role.IRole, index: number) => ({
          ...r,
          position: index,
        }));
        const update = list.find((r: Role.IRole) => r.id === item.id);
        if (!update) {
          throw new Error("Item to update not found");
        }

        await updateRole<IServerResponse<Role.IRole>>({
          params: Object.assign(
            update,
            { roleId: update.id },
            { name: update.item },
            { is_admin: update.is_admin }
          ),
        });
        setListOfRoles([]);
        getRolesPaginated(0, 0);
        setUnsavedChanges(false);
      } catch (e: unknown) {
        console.error(e);
      }
    },
    [getRolesPaginated, listOfRoles, updateRole]
  );

  const handleOnDeleteRole = useCallback(
    async (role: Role.IRole) => {
      await deleteRole<IServerResponse<Role.IRole>>({
        params: Object.assign({ roleId: role.id }),
      }).then((_) => {
        setListOfRoles([]);
        getRolesPaginated(0, 0);
        setUnsavedChanges(false);
        setCurrentRole(null);
      });
    },
    [deleteRole, getRolesPaginated]
  );

  const handleChange = useCallback(
    (e: any) => {
      setCurrentObject(() => {
        return listOfObjects.length > 0
          ? listOfObjects[
              listOfObjects.findIndex(
                (entity) => e.value === entity.id.toString()
              ) || 0
            ]
          : null;
      });
    },
    [listOfObjects, setCurrentObject]
  );

  const loadOptions = useCallback(
    async (
      search: string,
      _: any,
      additional: { page: number } | undefined
    ) => {
      let { page } = additional || { page: 0 };
      page = isNaN(Number(page)) ? 1 : Number(page) + 1;
      const options = [];
      try {
        const response = await getAllObjects<
          IServerResponse<SecObject.IObject>
        >({
          filter: "&".concat("search", "=", search),
          limit: 10, // ! Static limit.
          page,
        });
        setListOfObjects((prevState: Array<SecObject.IObject>) => {
          return [...prevState, ...response.data];
        });

        for (const entry of response.data) {
          options.push({
            value: entry.id,
            label: t(entry.item),
          });
        }

        return {
          options,
          additional: { page },
          hasMore: response.data.length === 10,
        };
      } catch (e) {
        console.error(e);
        return {
          options: [],
          hasMore: true,
        };
      }
    },
    [t, getAllObjects]
  );

  const reloadMembers = useCallback(() => {
    getUsersFromRole<IServerResponse<User.IUser>>({
      params: {
        roleId: currentRole?.id,
      },
    })
      .then((r) => r.data)
      .then(setMembers)
      .catch((e: Error) => {
        setMembers([]);
        console.error(e);
      });
  }, [currentRole?.id, getUsersFromRole]);

  useEffect(() => {
    if (currentRole?.id) reloadMembers();
  }, [currentRole?.id, reloadMembers]);

  return (
    <DragDropContext onDragEnd={handleOnRoleDragEnd}>
      <Container className="p-2" fluid>
        <Row>
          <Col xxl={3} xl={4} lg={5} md={6} sm={12} xs={12}>
            <Tab.Container
              defaultActiveKey={"role.".concat(
                currentRole?.id.toString() || "0"
              )}
            >
              <Droppable
                droppableId="RoleList-IRole-wrapper"
                children={(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={snapshot.isDraggingOver ? "dragging-over" : ""}
                  >
                    <RoleList
                      roles={listOfRoles}
                      provided={provided}
                      selected={currentRole}
                      onRoleDelete={handleOnDeleteRole}
                      onRoleClick={handleOnClickRoleDeref}
                      onRoleCreate={handleCreateRoleSubmit}
                    />
                  </div>
                )}
              />
            </Tab.Container>
          </Col>
          <Col xxl={9} xl={8} lg={7} md={6} sm={12} xs={12}>
            {currentRole && currentRole.id && (
              <Form onSubmit={handleOnUpdateRoleSubmit}>
                <Row>
                  {unsavedChanges && (
                    <Alert variant="dark" className="mt-2 py-2">
                      <Row>
                        <Col
                          xxl={9}
                          xl={8}
                          lg={7}
                          md={6}
                          sm={12}
                          xs={12}
                          className="d-flex align-items-center"
                        >
                          <span>{t("roles.pending_changes")}</span>
                        </Col>
                        <Col
                          xxl={3}
                          xl={4}
                          lg={5}
                          md={6}
                          sm={12}
                          xs={12}
                          className="d-flex align-items-center"
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="m-0 w-100"
                          >
                            {t("label.action.save", {
                              ns: "application.misc",
                            })}
                          </Button>
                        </Col>
                      </Row>
                    </Alert>
                  )}
                </Row>
                <Row>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="d-flex align-items-center"
                  >
                    <Form.Group controlId="role.item" className="w-100">
                      <Form.Label>
                        {t("roles.role_name", { context: "label" })}
                      </Form.Label>
                      <Form.Control
                        placeholder={t("roles.role_name_placeholder")}
                        value={currentRole.item}
                        name="role.item"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setCurrentRole({
                            ...currentRole,
                            item: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <input
                      value={currentRole.id}
                      name="role.roleId"
                      type="text"
                      hidden
                    />
                    {/* TODO: Implement is_admin edit functionality */}
                    <input
                      type="text"
                      name="role.is_admin"
                      value={String(currentRole.is_admin)}
                      hidden
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Group controlId="role.description" className="py-2">
                    <Form.Label>
                      {t("roles.role_description", { context: "label" })}
                    </Form.Label>
                    <Form.Control
                      placeholder={t("roles.role_description_placeholder")}
                      value={currentRole.description}
                      name="role.description"
                      as="textarea"
                      rows={5}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setCurrentRole({
                          ...currentRole,
                          description: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <input
                    value={currentRole.position}
                    name="role.position"
                    type="text"
                    hidden
                  />
                </Row>
                <hr className="mt-2" />
                <Row>
                  <Tab.Container defaultActiveKey="role.permissions">
                    <Container>
                      <Nav fill variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="role.permissions">
                            {t("roles.permission", { count: 0 })}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="role.domains">
                            {t("roles.domain", { count: 0 })}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="role.members">
                            {t("roles.member", { count: 0 })}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="role.actions">
                            {t("roles.actions")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Container>
                    <Tab.Content>
                      <Tab.Pane eventKey="role.permissions">
                        <Form.Group className="pt-1">
                          <AsyncPaginate
                            noOptionsMessage={() =>
                              t("label.help.no_options_available", {
                                ns: "application.misc",
                              })
                            }
                            loadOptionsOnMenuOpen={true}
                            loadOptions={loadOptions}
                            onChange={handleChange}
                            name="role.entity"
                            additional={{
                              page: 0,
                            }}
                          />
                        </Form.Group>
                        {currentObject &&
                          currentRole &&
                          listOfActions.length && (
                            <PermissionManager
                              SecObject={currentObject}
                              Actions={listOfActions}
                              Role={currentRole}
                            />
                          )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="role.domains">
                        <DomainsByRole roleId={currentRole.id} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="role.members">
                        <MemberManager
                          Role={currentRole}
                          updateRole={() => reloadMembers()}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="role.actions">
                        <RoleActions
                          onRoleDelete={handleOnDeleteRole}
                          Role={currentRole}
                          members={members}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </DragDropContext>
  );
};

export default withTranslation()(Roles);
