import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, Col, Row } from "react-bootstrap";

import useEntityService from "src/services/entity.service";
import RelationsSearchBar from "./../../pages/Entity/SingleEntity/RelationsSearchBar";
import {
  Entity,
  Field,
  IPagination,
  IServerResponse,
  Relationship,
  RelationshipKey,
} from "src/interfaces";
import CreateRelationshipModal from "../../pages/Entity/SingleEntity/CreateRelationshipModal";

interface RelationTableProps {
  handleReloadSingleEntity: () => void;
  relationship: Relationship;

  [key: string]: any;
}

export default function RelationTable(properties: RelationTableProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { entity, id } = useParams();
  const { relationship, handleReloadSingleEntity } = properties;
  const { getFields, getRelationshipsWithKey } = useEntityService();

  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  const [fields, setFields] = useState<Array<Field>>([]);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [isNewRecord, setIsNewRecord] = useState(false);

  const [entities, setEntities] = useState<Array<Entity.IEntity>>([]);

  const [linkedRelationship] = useState<RelationshipKey.ILinkedRelationship>({
    pagination: {} as IPagination,
    name: relationship.name,
    entity: "",
    data: [],
    label: relationship.label,
  });

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    limit: 10,
    hasPreviousPage: false,
    hasNextPage: false,
    previousPage: null,
    nextPage: null,
    startIndex: 0,
    numPages: 0,
    recordsTotal: 0,
  });

  const linkedEntity = useMemo(() => {
    if (relationship.l_entity === entity) {
      return relationship.r_entity;
    } else {
      return relationship.l_entity;
    }
  }, [entity, relationship]);

  const columns = useMemo(() => {
    const columns: Array<TableColumn<Entity.IEntity>> = [
      { name: "ID", selector: (row: Entity.IEntity) => row["id"] },
    ];

    fields.forEach((field) => {
      columns.push({
        name: tWithEntityNamespace("fields.".concat(field.name)),
        selector: (row: Entity.IEntity) => {
          const key: Entity.keyPrefix<"data_", string> = `data_${field.name}`;
          return row[key];
        },
      });
    });

    return columns;
  }, [fields, tWithEntityNamespace]);

  const handleSearch = useCallback((value: string) => {}, []);

  const handlePageChange = useCallback((limit: number, page: number) => {
    console.warn("handlePageChange", limit, page);
    setPagination((prev) => {
      return {
        ...prev,
        limit: limit,
        currentPage: page,
      };
    });
  }, []);

  // ? Fetch the relationship entities from the server with pagination.
  useEffect(() => {
    if (!entity || isNaN(Number(id))) return;
    getRelationshipsWithKey({
      entity: entity,
      id: Number(id),
      key: relationship.name,
      page: pagination.currentPage,
      limit: pagination.limit,
    })
      .then((response) => {
        const linkedRelationship = response.data[0];
        const entities = linkedRelationship.data;
        setEntities(entities);

        const pagination = linkedRelationship.pagination;
        setPagination(pagination);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [
    entity,
    id,
    relationship.name,
    getRelationshipsWithKey,
    pagination.currentPage,
    pagination.limit,
  ]);

  // ? Fetch the linked entity fields from the server.
  useEffect(() => {
    if (!linkedEntity) return;
    const fn = (response: IServerResponse<Field>) => setFields(response.data);
    getFields({ entity: linkedEntity, fn });
  }, [linkedEntity, getFields]);

  return (
    <Card className="m-2">
      <Card.Body>
        <RelationsSearchBar
          handleSearchValue={handleSearch}
          tableName={relationship.label}
        />
        <DataTable
          onChangePage={handlePageChange.bind(null, pagination.limit)}
          onRowClicked={(row) => navigate(`/${linkedEntity}/show/${row["id"]}`)}
          noDataComponent={t("relationship_table.no_items_to_display")}
          paginationDefaultPage={pagination.currentPage}
          paginationTotalRows={pagination.recordsTotal}
          onChangeRowsPerPage={handlePageChange}
          columns={columns}
          data={entities}
          selectableRowsSingle
          paginationServer
          highlightOnHover
          selectableRows
          pagination
          paginationComponentOptions={{
            rowsPerPageText: t("label.pagination.rows_per_page", {
              ns: "application.misc",
            }),
            rangeSeparatorText: t("label.pagination.of", {
              ns: "application.misc",
            }),
          }}
        />
      </Card.Body>

      <Card.Footer>
        <Row className="mb-3">
          <Col>
            <Button
              children={t("label.action.add", { ns: "application.misc" })}
              onClick={() => {
                setModalShow(true);
                setIsNewRecord(true);
              }}
              variant="primary"
              className="w-100"
            />
          </Col>
          <Col>
            <Button
              children={t("label.action.select", { ns: "application.misc" })}
              onClick={() => {
                setModalShow(true);
                setIsNewRecord(false);
              }}
              variant="primary"
              className="w-100"
            />
          </Col>
        </Row>
      </Card.Footer>

      <CreateRelationshipModal
        onClose={() => setModalShow(false)}
        current={linkedRelationship}
        relationship={relationship}
        show={modalShow}
        records={isNewRecord}
        setRecords={setIsNewRecord}
        onConfirm={() => {
          handleReloadSingleEntity();
          setModalShow(false);
        }}
      />
    </Card>
  );

  // const [columns, setColumns] = useState<any>([]);
  // const [data, setData] = useState<any>([]);
  // const [searchValue, setSearchValue] = useState({ name: '', value: '' })

  // const handleSearchValue = (value: any, name: any) => {
  //   setSearchValue({ value: value, name: name })
  // }

  // return (

  //       <Row>
  //         <Col
  //           className='mt-2 mb-2'
  //           xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
  //
  //         </Col>
  //         <Col
  //           className='mt-2 mb-2'
  //           xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>

  //         </Col>
  //       </Row>
  //     </Card.Header>
  //     <Card.Body>
  //       {
  //         data instanceof Array
  //           ? (
  //             <>

  //               {
  //                 selectedRows[item.name]?.length > 0 && (
  //                   <Row className='m-3'>
  //                     <Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
  //                       {selectedRows[item.name]?.length} {t('relationship_table.selected')}
  //                     </Col>
  //                     <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}>
  //                       <Button
  //                         onClick={() => handleDeleteRelation(item.name)}
  //                         key="delete_selected"
  //                         className='w-100'
  //                         variant='danger' >
  //                         {t('relationship_table.delete_selected')}
  //                       </Button>
  //                     </Col>
  //                   </Row>
  //                 )
  //               }
  //             </>
  //           )
  //           : (
  //             <Alert variant='warning'>
  //               {t('relationship_table.no_items_to_display')}
  //             </Alert>
  //           )
  //       }
  //     </Card.Body>
  //   </Card>
  // );
}
