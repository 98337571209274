import ModalForm from "../../UI/Modal/ModalForm/ModalForm";
import styles from "./DeleteModal.module.css";
import Button from "react-bootstrap/Button";

function DeleteModal(props: any) {
  function handleCloseButton() {
    props.handleClose();
  }

  function handleContinueButton() {
    props.onContinueDelete(props.idToDelete);
  }
  return (
    <ModalForm
      show={props.isModalShowing}
      onHide={props.handleClose}
      handleClose={props.onClose}
      header={props.header}
      size={props.size ? props.size : "sm"}
    >
      <div>
        <h5>{`${props.tableTitle}: ${props.idToDelete} will be removed`}</h5>
        <div className={styles.wrapper}>
          <div>
            <Button onClick={handleCloseButton} variant="primary">
              Cancel
            </Button>
          </div>
          <div>
            <Button onClick={handleContinueButton} variant="success">
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
}

export default DeleteModal;
