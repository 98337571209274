import { NavLink, useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import DymForm from '../../../UI/Form/DymForm';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

export default function AddEntity() {
    const { t } = useTranslation();
    const { entity } = useParams();

    if (!entity) {
        return null;
    }

    return (
        <Container fluid className='p-3'>
            <div className='d-flex justify-content-between align-items-center'>
                <h1>{t("Add").concat(' ', t(`${entity}`))}</h1>
                <NavLink to={`/${entity}`}
                    className='btn btn-secondary'
                    children={
                        capitalize(
                            t(
                              "label.action.cancel",
                              { ns: "application.misc" },
                            )
                        )
                    }
                />
            </div>
            <DymForm
                type="PUT"
                entity={entity}
                onSubmit={function (...args: any[]) {
                    throw new Error('Function not implemented.');
                } }
                fetchNewData={function (...args: any[]) {
                    throw new Error('Function not implemented.');
                } }
                setExternalErrors={function (errors: string[]) {
                    throw new Error('Function not implemented.');
                } }
      />
        </Container>
    )
}
