import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";
import Input from "rsuite/Input";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { Relationship, Entity, RelationshipKey, IServerResponse } from "src/interfaces";
import useEntityService from "src/services/entity.service";
import DymSelect from "src/components/UI/Input/DymSelect";
import DymForm from "src/components/UI/Form/DymForm";

interface CreateRelationshipModalProps {
  onClose: (relationship: string, entity?: string) => void;
  onConfirm: (item: any, relationship: string) => void;
  current: RelationshipKey.ILinkedRelationship;
  relationship: Relationship;
  children?: ReactNode;
  show: boolean;
  records: boolean;
  setRecords: (value: boolean) => void;
  t: TFunction;
}

const CreateRelationshipModal = ({ onConfirm, onClose, relationship, current, show, records, setRecords,  t }: CreateRelationshipModalProps) => {
  const { entity, id } = useParams();

  const { createRelationship, getSingleRecordWithRelationships } = useEntityService();


  const [currentEntity, setCurrentEntity] = useState<Entity.IEntity>();
  const [relationships, setRelationships] = useState<{ [key: string]: Array<Entity.IEntity> }>({});
  const [record, setRecord] = useState<boolean>(records);
  
  const payload = useMemo(() => {
    const payload: any = {
      relationships: [],
    };

    const { l_entity, r_entity, name } = relationship;

    if (currentEntity) {
      for (const key in currentEntity) {
        if (key.startsWith("data_")) {
          const suffix = key.replace(/^data_/i, "");
          payload[suffix] = currentEntity[`data_${suffix}`];
        }
      }
    }

    const listOfRelationships = relationships
      .hasOwnProperty(name)
      ? relationships[name]
      : [];

    payload.relationships.push({
      data: listOfRelationships,
      name: name,
      entity: entity === l_entity
        ? r_entity
        : l_entity,
    });

    return payload;
  }, [relationship, currentEntity, relationships, entity]);

  const onSubmitRelationships = useCallback(() => {
    if (!currentEntity || !entity) return;
    const { id } = currentEntity;
    const { name } = relationship;

    createRelationship({
      id,
      data: payload,
      entity: entity,
      fn: onConfirm.bind(null, payload),
    })
      .then(() => {
        setRecord(false)
        onClose(name);
      })
      .catch((e) => console.error(e));
  }, [createRelationship, currentEntity, entity, onClose, onConfirm, payload, relationship]);

  const generateInputs = (name: any) => {
    return relationship.fields
      .map(
        field => (
          <Input
            key={field.id}
            onChange={() => { }}
          // * onChange={setFieldsState}
          />
        )
      );
  };



  // ? Effect to apply the entity data.
  useEffect(() => {
    setRecord(records);
    if (!entity || !id) return;

    const fn = (response: IServerResponse<Entity.IEntity>) => {
      const [entityData] = response.data;
      setCurrentEntity(entityData);
    };
    getSingleRecordWithRelationships({ entity, id: id, fn });

  }, [entity, id, getSingleRecordWithRelationships, records]);

  const handleSwitchToggle = () => {
    setRecord(!record);
    setRecords(!record);
  };

  if (!currentEntity) return null;
  // ? DA-267: Remove ModalForm from CreateRelationshipModal
  return (
    <Modal show={show} onHide={() => onClose(relationship.name)} size="xl">
      <Modal.Header>
        <Modal.Title>
          <Form.Check
            type="switch"
            onChange={handleSwitchToggle}
            checked={record}
            id="switch-new-record"
            label={
              <Trans
                i18nKey={`create_relationship.${record ? 'add_entities' : 'add_records'}`}
                defaults={record ? 'Add entities' : 'Add records'}
              />
            }
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          relationship.type === "many-to-many"
          && (
            <Row
              className="d-flex justify-content-center m-2"
              children={generateInputs(relationship.name)} />
          )
        }
        <Row className="d-flex justify-content-center  m-2">
          {
            record
              ? (
                <DymForm
                  onSubmit={onConfirm.bind(null, relationship.name)}
                  entity={
                    relationship.r_entity === entity
                      ? relationship.l_entity
                      : relationship.r_entity
                  }
                  setExternalErrors={() => { }}
                  originRelationship={[{
                    name: relationship.name,
                    pagination: {} as any,
                    data: [currentEntity],
                    entity: entity,
                    label: relationship.label
                  }]}
                  type="POST"

                  fetchNewData={function (...args: any[]) {
                    throw new Error("Function not implemented.");
                  }} />
              )
              : (
                <React.Fragment>
                  <div>
                    <DymSelect
                      targetEntity={
                        relationship &&
                          relationship.l_entity === entity
                          ? relationship.r_entity
                          : relationship.l_entity
                      }

                      relationshipsKeys={relationship}
                      onChange={setRelationships}
                      skipRecords={current.data}
                      singleSelect={true}
                    />
                  </div>
                  <Row>
                    <Button
                      onClick={(e) => onSubmitRelationships()}
                      variant="primary"
                    >
                      {t("label.action.select", { ns: "application.misc" })}
                    </Button>
                  </Row>
                </React.Fragment>
              )
          }
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(CreateRelationshipModal);