import React, { useCallback, useEffect, useState } from "react";
import { Plus, Dash, Search } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, ListGroup } from "react-bootstrap";

import { capitalizeFirstLetter } from "../../../utils/utils";
import { useEntityTable } from "../EntityTable/EntityTable";
import Filter from "./filter";

const FilterContainer = () => {
  const { t } = useTranslation();

  const { rows, currentView, dispatchCurrentView } = useEntityTable();

  const [key, setKey] = useState<string>("");
  const [operator, setOperator] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [filterArray, setFilterArray] = useState([] as any);
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  const updateFilterData = useCallback(
    (data: any) => {
      dispatchCurrentView({
        filters: data,
      });
    },
    [dispatchCurrentView]
  );

  let submitType = "";

  const handleOperator = (operator: any) => {
    setDisplayAlert(false);
    setOperator(operator);
  };

  const handleKey = (key: any) => {
    setDisplayAlert(false);
    setKey(key);
  };

  const handleSearchValue = (searchValue: any) => {
    setDisplayAlert(false);
    setSearchValue(searchValue);
    setHasFilter(true);
  };

  const clearData = () => {
    setKey("");
    setOperator("");
    setSearchValue("");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (submitType === "search") {
      setDisplayAlert(false);
      if (filterArray.length > 0) {
        const query: any[] = [];
        filterArray.forEach(
          (obj: { key: any; operator: any; searchValue: any; column: any }) => {
            const filter = {
              field: obj.key,
              operator: obj.operator,
              value: obj.searchValue,
            };
            query.push(filter);
          }
        );
        updateFilterData(query);
        clearData();
      } else {
        setDisplayAlert(true);
      }
    } else if (submitType === "add") {
      setDisplayAlert(false);

      const filterValues = {
        key: key,
        operator: operator,
        searchValue: searchValue,
      };
      const filterArrayCopy = filterArray;

      if (key !== "" && operator !== "" && searchValue !== "") {
        setFilterArray([...filterArrayCopy, filterValues]);
        clearData();
      } else {
        setDisplayAlert(true);
      }
    }
  };

  const handleReset = () => {
    setHasFilter(false);
    setDisplayAlert(false);
    setFilterArray([]);
    updateFilterData([]);
    clearData();
  };

  const deleteFilter = (index: any) => {
    const filterArrayCopy = filterArray;
    filterArrayCopy.splice(index, 1);
    setFilterArray([...filterArrayCopy]);
  };

  useEffect(() => {
    if (currentView.filters instanceof Array) {
      const selected = currentView.filters.map((obj: any) => {
        return {
          key: obj.field,
          operator: obj.operator,
          searchValue: obj.value,
        };
      });
      setFilterArray(selected);
    }
  }, [currentView.filters]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Filter
          handleSearchValue={handleSearchValue}
          handleOperator={handleOperator}
          searchValueData={searchValue}
          operatorData={operator}
          handleKey={handleKey}
          keyData={key}
        />
        <ListGroup>
          {filterArray.map((obj: any, index: any) => {
            let operator = "";
            let keyLabel = "";
            if (
              obj.key !== undefined &&
              obj.operator !== undefined &&
              obj.searchValue !== undefined
            ) {
              if (obj.operator === "lt") {
                operator = t("label.operator.lt", { ns: "application.misc" });
              } else if (obj.operator === "gt") {
                operator = t("label.operator.gt", { ns: "application.misc" });
              } else if (obj.operator === "eq") {
                operator = t("label.operator.eq", { ns: "application.misc" });
              } else if (obj.operator === "ne") {
                operator = t("label.operator.ne", { ns: "application.misc" });
              } else if (obj.operator === "lk") {
                operator = t("label.operator.lk", { ns: "application.misc" });
              }
              if (obj.key.includes("data")) {
                keyLabel = obj.key.substring(5);
              }

              // ! Return only items, not groups.
              return (
                <ListGroup.Item key={index}>
                  <b>{index + 1}:</b> {capitalizeFirstLetter(t(keyLabel))}{" "}
                  {operator.toLowerCase()} {obj.searchValue}
                  <svg
                    onClick={() => deleteFilter(index)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    width="32"
                    height="32"
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      right: "1px",
                      top: "1px",
                    }}
                  >
                    <path d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708z"></path>
                  </svg>
                </ListGroup.Item>
              );
            }
            return obj;
          })}
        </ListGroup>
      </Form.Group>

      {displayAlert ? (
        <Alert variant="warning">{t("entity_table.error_filter_search")}</Alert>
      ) : null}
      <Button
        variant="success"
        type="submit"
        onClick={() => (submitType = "add")}
        style={{ marginTop: "1rem", width: "100%" }}
        disabled={!rows.length && !hasFilter}
      >
        <Plus /> {t("entity_table.add_filter")}
      </Button>
      <Button
        variant="danger"
        type="submit"
        onClick={() => handleReset()}
        style={{ marginTop: "1rem", width: "100%" }}
        disabled={!rows.length && !hasFilter}
      >
        <Dash /> {t("entity_table.reset_filter")}
      </Button>
      <Button
        variant="primary"
        type="submit"
        onClick={() => (submitType = "search")}
        style={{ marginTop: "1rem", width: "100%" }}
        disabled={!rows.length && !hasFilter}
      >
        <Search /> {t("entity_table.search_filter")}
      </Button>
    </Form>
  );
};

export default FilterContainer;
