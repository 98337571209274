import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { TFunction, withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Form,
  Col,
  FloatingLabel,
  Accordion,
  Button,
} from "react-bootstrap";
import Select from "react-select";

import useMenuCategoryService from "src/services/menu_category.service";
import useRoleService from "src/services/role.service";
import { IServerResponse, Role } from "src/interfaces";
import { ListItem } from "../Menu/Form";

interface IFormMenuCategories {
  t: TFunction;
  type: "create" | "edit";
}

interface ICategoryMenu {
  id: number;
  name: string;
  visible: boolean;
  active: boolean;
  roleId: { value: string | number; label: string }[];
}

const FormMenuCategoryConext = createContext<{
  reloadCategory: () => void;
  categoryMenu: any;
}>({
  reloadCategory: () => void 0,
  categoryMenu: {},
});

const FormMenuCategories = ({ t, type }: IFormMenuCategories): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getCategoryMenuById: getSelectedMenu,
    create: createMenuById,
    edit: editMenuById,
  } = useMenuCategoryService();
  const [listOfRoles, setListOfRoles] = useState<Array<Role.IRole>>([]);
  const [categoryMenu, setCategoryMenu] = useReducer(
    (state: ICategoryMenu, newState: ICategoryMenu) => ({
      ...state,
      ...newState,
    }),
    { id: 0, name: "", visible: true, active: true, roleId: [] }
  );

  const { getAllRoles } = useRoleService();

  const reloadCategory = useCallback(() => {
    getSelectedMenu({ id })
      .then((response) => setCategoryMenu(response.data))
      .catch((error) => console.error(error));
  }, [getSelectedMenu, id]);

  const getRolesPaginated = useCallback(
    (page: number, limit: number) => {
      getAllRoles<IServerResponse<Role.IRole>>({ page, limit })
        .then((r) => {
          if (r.pagination.nextPage) {
            getRolesPaginated(r.pagination.nextPage, limit);
          }
          return r.data;
        })
        .then((d) => setListOfRoles((p) => [...p, ...d]))
        .catch((e: Error) => {
          setListOfRoles([]);
          console.error(e);
        });
    },
    [getAllRoles, setListOfRoles]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data: ICategoryMenu = {
        ...categoryMenu,
      };
      switch (type) {
        case "create":
          createMenuById({
            // TODO: Include phone and phone types at creating new person.
            data,
          })
            .then(() => {
              navigate(`/config/menu_category`);
            })
            .catch((error) => console.error(error));
          break;
        case "edit":
          editMenuById({ id, data: { data } })
            .then(() => {
              navigate(`/config/menu_category`);
            })
            .catch((error) => console.error(error));
          break;
        default:
          console.error("Invalid form type suitable for submit.");
          break;
      }
    },
    [createMenuById, editMenuById, id, navigate, categoryMenu, type]
  );

  useEffect(() => {
    if (type === "edit" && id) {
      getSelectedMenu({ id })
        .then((response) => {
          const resMenu = response.data;
          const data: ICategoryMenu = {
            id: resMenu[0].id,
            name: resMenu[0].name,
            visible: resMenu[0].visible,
            active: resMenu[0].active,
            roleId: resMenu.map((row: any) => ({
              value: Number(row.role_id),
              label: row.item,
            })),
          };
          setCategoryMenu({ ...data });
        })
        .catch((error) => console.error(error));
    }
  }, [getSelectedMenu, id, type]);

  useEffect(() => {
    getRolesPaginated(0, 0);
  }, [getRolesPaginated]);

  const selectOptions = useMemo(() => {
    return listOfRoles.map((u) => ({
      value: u.id,
      label: u.item,
    }));
  }, [listOfRoles]);

  return (
    <FormMenuCategoryConext.Provider value={{ reloadCategory, categoryMenu }}>
      <Form onSubmit={onSubmit}>
        <Row>
          <Accordion as="ol" flush defaultActiveKey="basic_information">
            <Accordion.Item eventKey="basic_information">
              <Accordion.Header>
                <ListItem className="mx-3">
                  {t("menu.basic_information")}
                </ListItem>
              </Accordion.Header>
              <Accordion.Body data-section="basic_information" className="p-2">
                <Row>
                  <Col>
                    <FloatingLabel
                      label={t("category_menu.name")}
                      className="my-2"
                      controlId="category_menu.name"
                    >
                      <Form.Control
                        required
                        onChange={(e) =>
                          setCategoryMenu({
                            ...categoryMenu,
                            name: e.target.value,
                          })
                        }
                        value={categoryMenu.name}
                        name="category_menu.name"
                        maxLength={50}
                        type="text"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <Form.Check
                      label={t("category_menu.visible")}
                      onChange={(e) =>
                        setCategoryMenu({
                          ...categoryMenu,
                          visible: e.target.checked,
                        })
                      }
                      checked={categoryMenu.visible}
                      name="category_menu.visible"
                      type="checkbox"
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="assign_role">
              <Accordion.Header>
                <ListItem className="mx-3">
                  {t("category_menu.assign_role")}
                </ListItem>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <Form.Group as={Col} controlId="formEntity">
                      <Form.Label>{t("category_menu.assign_role")}</Form.Label>
                      <Select
                        options={selectOptions}
                        value={categoryMenu.roleId}
                        name="category_menu.role"
                        onChange={(e: any) =>
                          setCategoryMenu({
                            ...categoryMenu,
                            roleId: e,
                          })
                        }
                        isMulti
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button variant="primary" disabled={false} type="submit">
              {t("label.action.save", { ns: "application.misc" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormMenuCategoryConext.Provider>
  );
};

export function useFormMenuCategory() {
  const context = useContext(FormMenuCategoryConext);
  if (!context) {
    throw new Error(
      "useFormMenuCategory must be used within a FormMenuCategoryConext"
    );
  }
  return context;
}

export default withTranslation()(FormMenuCategories);
