import React from "react";

export default function Alert(props: any) {
  const type = props.type ?? "danger";
  
  return (
    <React.Fragment>
      <div
        className={`alert alert-${type} alert-dismissible fade show`}
        role="alert"
      >
        <strong>{props.title}</strong>
        {` ${props.description}`}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={props.onClick}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </React.Fragment>
  );
}
