import React, { FormEvent, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Search, Trash } from "@rsuite/icons";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const SearchBar = (props: any) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLInputElement | null>(null);
  const { current } = useMemo(() => ref, [ref]);

  const handleClear = useCallback((e: any) => {
    e.preventDefault();
    if (current) {
      current.value = "";
      props.setSearchBar("");
    }
  }, [current, props]);

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (current) {
      props.setSearchBar(current.value);
    }
  }, [current, props]);

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Form.Control
          placeholder={t("entity_table.type_to_search")}
          aria-label={t("entity_table.type_to_search")}
          type="text"
          ref={ref}
        />

        <OverlayTrigger
          placement="bottom"
          children={
            <InputGroup.Text
              className="d-flex justify-content-center align-items-center"
              children={<Trash onClick={handleClear} />}
              disabled={!current || !current.value}
              variant="outline-secondary"
              type="submit"
              as={Button}
            />
          }
          overlay={
            <Tooltip id="search.button.cancel">
              {t("entity_table.cancel")}
            </Tooltip>
          }
        />

        <OverlayTrigger
          placement="bottom"
          children={
            <InputGroup.Text
              className="d-flex justify-content-center align-items-center"
              children={<Search />}
              variant="secondary"
              type="submit"
              as={Button}
            />
          }
          overlay={
            <Tooltip id="search.button.search">
              {t("entity_table.search")}
            </Tooltip>
          }
        />
        
      </InputGroup>
    </Form>

  )
}

export default SearchBar;