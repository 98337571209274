import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row } from "react-bootstrap";

interface DeleteModalProps {
  showDeleteModal: boolean;
  handleCancel: () => void;
  actiontype: string;
  handleConfirm: () => void;
}

const DeleteModal = ({
  showDeleteModal = false,
  handleCancel,
  actiontype,
  handleConfirm,
}: DeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal show={showDeleteModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {actiontype === "phone"
            ? t("user_profile.delete_phone_title")
            : t("user_profile.delete_address_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {actiontype === "phone"
          ? t("user_profile.delete_phone_message")
          : t("user_profile.delete_address_message")}
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button variant="tertiary" onClick={handleCancel}>
              {t("label.action.cancel", { ns: "application.misc" })}
            </Button>
            <Button onClick={handleConfirm}>
              {t("label.action.delete", { ns: "application.misc" })}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
