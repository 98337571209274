import useLocalStorage from "../../../hook/useLocalStorage";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

const locale = localStorage.getItem("language")?.replace(/[^a-z]/gi, "")
  || process.env.REACT_APP_DEFAULT_LOCALE
  || "en";

const TranlationSwitcher = () => {
  const { i18n } = useTranslation();
  const { storeValue: storeLanguage, storedValue: language } =
    useLocalStorage<string>("language", locale);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const locale = event.target.checked ? "en" : "es";
      i18n.changeLanguage(locale);
      storeLanguage(locale);
    },
    [i18n, storeLanguage]
  );

  const checkbox = useMemo(() => {
    return (
      <Form.Check
        type="switch"
        id="language-switch"
        alt="Switch Language"
        onChange={handleChange}
        checked={language && language === "en" ? true : false}
      />
    );
  }, [handleChange, language]);

  return (
    <Form style={{ display: "flex", marginTop: "0.5rem", marginRight: "0.5rem" }}>
      <span style={{ marginRight: "0.5rem", color: "white" }}>ES</span>
      {checkbox}
      <span style={{ color: "white" }}>EN</span>
    </Form>
  );
};

export default TranlationSwitcher;
