import { useCallback } from "react";

import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useAppSelector } from "../app/hooks";
import { getToken } from "../reducers/auth";

interface IRequestParams {
  data?: object;
  page?: number;
  limit?: number;
  entity?: string;
  params?: any;
  callback?: Function;
  filter?: URLSearchParams[] | string;
}

export default function useRoleService() {
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  return {
    // [GET] → /api/roles → Get all roles stored currently on the system
    getAllRoles: useCallback(
      function <IType>({ page = 1, limit = 10, filter = "" }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles?${pagination}`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [GET] → /api/roles/objects → Get all objects stored on the system from object table
    getAllObjects: useCallback(
      function <IType>({ page = 1, limit = 10, filter = "" }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/objects?${pagination}`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [GET] → /api/roles/actions → Get all actions provided by action permission table
    getAllActions: useCallback(
      function <IType>({ page = 1, limit = 10, filter = "" }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/actions?${pagination}`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [GET] → /api/roles/permissions/:type/:object → Get all permissions available on current object by Permission System Table [7]
    getAllObjectPermissions: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { type: "", item: "" },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/permissions/${params.type}/${params.item}?${pagination}`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [GET] → /api/api/roles/:roleId/permissions/:type/:object → Get all active permissions of role on current object by role_has_permission table
    getRoleObjectPermissions: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0, type: "", item: "" },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/permissions/${params.type}/${params.item}?${pagination}`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [PUT] → /api/roles/:roleId/permissions → Update permissions by actions number
    updateRoleObjectPermissions: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0, type: "", item: "", permissions: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/permissions`,
                  method: "PUT",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                  body: {
                    object_type: params.type,
                    object_item: params.item,
                    permissions: params.permissions,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [PUT] → /api/roles/:roleId → Update role information and positioning
    updateRole: useCallback(
      function <IType>({
        params = {
          roleId: 0,
          name: "",
          description: "",
          position: 0,
          is_admin: false,
        },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}`,
                  method: "PUT",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                  body: {
                    item: params.item,
                    position: params.position,
                    description: params.description,
                    is_admin: params.is_admin,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [POST] → /api/roles → Create role on the system
    createRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { item: "", description: "", is_admin: false },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles`,
                  method: "POST",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                  body: {
                    item: params.item,
                    description: params.description,
                    is_admin: params.is_admin,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [DELETE] → /api/roles/:roleId → Delete role on the system
    deleteRole: useCallback(
      function <IType>({ params = { roleId: 0 } }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}`,
                  method: "DELETE",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [PUT] → /api/roles/:roleId/users/:userId → Add user to role relationship
    addUserToRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0, userId: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/users/${params.userId}`,
                  method: "PUT",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [DELETE] → /api/roles/:roleId/users/:userId → Disable user to role relationship
    removeUserFromRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0, userId: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/users/${params.userId}`,
                  method: "DELETE",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [GET] → /api/roles/:roleId/users → Get users from role relationship
    getUsersFromRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/users`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [PUT] → /api/roles/:roleId/domains/:domainId → Add domain to role relationship
    addDomainToRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0, domainId: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/domains/${params.domainId}`,
                  method: "PUT",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                    pagination,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [DELETE] → /api/roles/:roleId/domains/:domainId → Delete domain to role relationship
    deleteDomainFromRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0, domainId: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/domains/${params.domainId}`,
                  method: "DELETE",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                    pagination,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),

    // [GET] → /api/roles/:roleId/domains → Get domains from role relationship
    getDomainsFromRole: useCallback(
      function <IType>({
        page = 1,
        limit = 10,
        filter = "",
        params = { roleId: 0 },
      }: IRequestParams) {
        return new Promise<IType>((resolve, reject) => {
          try {
            if (credentials && credentials.logged) {
              const pagination = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
              }).toString();

              sendRequest(
                {
                  url: `${environment.apiUrl}/roles/${params.roleId}/domains`,
                  method: "GET",
                  headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                    pagination,
                  },
                },
                resolve
              );
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      [sendRequest, credentials]
    ),
  };
}
