import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ErroHttpState {
    status : boolean;
}

const initialState: ErroHttpState = {
    status : false,
};

export const ErrorHttpSlice = createSlice({
    name : "errorHttp",
    initialState,
    reducers : {
        activate : (state)=> {
            state.status = true;
        },
        inactivated : (state) => {
            state.status = false;
        },
    }
});

export const { activate, inactivated } = ErrorHttpSlice.actions;
export const getErrorHttp = (state: RootState) => state.errorHttp;

export default ErrorHttpSlice.reducer;