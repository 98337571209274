const {
    PORT = 3000,
    REACT_APP_API_URL,
    REACT_APP_BASENAME = '/',
    REACT_APP_API_ENDPOINT = '/api',
} = process.env;

const environment = {
    port: PORT,
    url: REACT_APP_API_URL,
    basename: REACT_APP_BASENAME,
    endpoint: REACT_APP_API_ENDPOINT,
    apiUrl: `${REACT_APP_API_URL}${REACT_APP_API_ENDPOINT}`,
};

export default environment;