import DomainForm from "./DomainForm";

interface NewDomainProps {
    cancelCreate: Function;
    handleSubmitNewDomain: Function;
}

const NewDomain = ({ cancelCreate, handleSubmitNewDomain }: NewDomainProps) => {
    return (
        <DomainForm handleDomain={handleSubmitNewDomain} cancelForm={cancelCreate} />
    );
};

export default NewDomain;