import { useState, useEffect, useCallback } from 'react';

import environment from '../environment';
import HttpHandler from './http-handler';
import { getToken } from "../reducers/auth";
import { useAppSelector } from "../app/hooks";
import MenuSettings from 'src/interfaces/MenuSettings';

export default function useMenuSettings() {
    const [menuSettings, setMenuSettings] = useState<MenuSettings | null>(null);
    const credentials = useAppSelector(getToken);
    const { sendRequest } = HttpHandler();

    const getSettings = useCallback(() => {
        if (credentials && credentials.logged) {
            sendRequest({
                url: environment.apiUrl + '/menu/settings',
                method: 'GET',
                headers: {
                    Authorization: `${credentials.token_type} ${credentials.access_token}`,
                }
            }, setMenuSettings);
        }
    }, [sendRequest, credentials]);

    useEffect(() => {
        getSettings();
    }, [getSettings]);

    return menuSettings;
}
