import { DomainItemType, DomainsPageStateType } from "./types";
import { Button, Col, Container, Row } from "react-bootstrap";
import useDomainsService from "src/services/domains.service";
import { Fragment, useCallback, useEffect, useState } from "react";
import DomainDetails from "./DomainDetails";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { setDomainIdSelected, setDomainList, updateDomain, removeDomainSelected, setUsersByDomain } from "src/reducers/domain/domains";
import styled from "styled-components";
import DomainItem from "./DomainItem";
import NewDomain from "./NewDomain";
import { withTranslation, TFunction } from "react-i18next";

const DomainsMap = styled.div`
    border: 1px solid #000;
    padding: 5px;
`;

const initialState = {
    domainSelected: {},
    isNewDomain: false,
    parentDomains: [],
    domainCreated: false,
    domainUpdated: false,
};

interface DomainsPageProps {
    t: TFunction;
}

const DomainsPage = ({ t }: DomainsPageProps) => {
    const dispatch = useAppDispatch();
    const { domainIdSelected, domainList, usersByDomain } = useAppSelector(state => state.domains);
    const [{
        domainSelected,
        isNewDomain,
        domainCreated,
        domainUpdated,
    }, setState] = useState<DomainsPageStateType>(initialState);
    const { create, getMany, getOne, update, getUsersByDomain } = useDomainsService();

    // Set Parent Domains
    const setParentDomains = useCallback((domains: DomainItemType[]) => {
        setState(prevState => ({ ...prevState, parentDomains: domains.filter((domain: DomainItemType) => isParentDomain(domain)) }));
    }, []);

    // Get All Domains
    useEffect(() => {
        getMany({
            fn: ({ data }: any) => {
                dispatch(setDomainList(data));
            }
        });
    }, [getMany, dispatch]);

    // Get SubDomains for the Domain Selected
    useEffect(() => {
        if (domainIdSelected) {
            getOne({
                fn: ({ data }: any) => {
                    dispatch(updateDomain({ domain: data }));
                },
                id: domainIdSelected,
            });

            getUsersByDomain({
                fn: ({ data }: any) => {
                    dispatch(setUsersByDomain(data));
                },
                id: domainIdSelected,
            });
        }
    }, [domainIdSelected, getOne, getUsersByDomain, dispatch]);

    // Update Parent Domain List when domainList is updated
    useEffect(() => {
        setParentDomains(domainList);
    }, [domainList, setParentDomains]);

    // Get All Domain List when we create or update a domain
    useEffect(() => {
        if (domainCreated || domainUpdated) {
            getMany({
                fn: ({ data }: any) => {
                    dispatch(setDomainList(data));
                }
            });
            setState(prevState => ({
                ...prevState,
                isNewDomain: false,
                domainCreated: false,
                domainUpdated: false,
            }));
            dispatch(removeDomainSelected());
        }
    }, [domainCreated, domainUpdated, getMany, dispatch]);

    const resetState = () => {
        setState(prevState => ({ ...prevState, ...initialState, parentDomains: prevState.parentDomains }));
    };

    const isParentDomain = (domain: DomainItemType) => {
        return domain?.parent_id === 0;
    };

    const onSelect = (domainSelected: DomainItemType) => {
        if (domainSelected?.id) {
            dispatch(setDomainIdSelected(Number(domainSelected.id)));
            setState(prevState => ({ ...prevState, domainSelected }));
        }
    };

    const handleSubmitNewDomain = ({ name, description }: DomainItemType) => {
        create({
            fn: (resp: any) => {
                resp?.data && setState(prevState => ({ ...prevState, domainCreated: true }));
            },
            domain: {
                name,
                description,
                parentId: 0
            }
        });
    };

    const handleSubmitUpdateDomain = useCallback((domain: DomainItemType) => {
        update({
            fn: (resp: any) => {
                resetState();
                setState(prevState => ({ ...prevState, domainUpdated: true }));
            },
            domain: {
                ...domain,
                parentId: domain?.parent_id ?? 0
            }
        });
    }, [update]);

    const mapDomainsRender = (domain: DomainItemType) => (
        domain?.subDomains?.length ? (
            <DomainItem key={domain.id} domain={domain} onSelectDomain={onSelect} domainIdSelected={domainIdSelected}>
                {
                    Array
                        .from(domain.subDomains)
                        .sort((a, b) => {
                            // * Sort by id
                            const idx = a.id || 0;
                            const idy = b.id || 0;
                            const r = idx - idy;
                            return r;
                        })
                        .map((subDomain) => (
                            <Fragment key={subDomain.id}>{mapDomainsRender(subDomain)}</Fragment>
                        ))
                }
            </DomainItem>
        ) : (
            <DomainItem key={domain.id} domain={domain} onSelectDomain={onSelect} domainIdSelected={domainIdSelected} />
        )
    );

    const handleCancelDetailsView = useCallback(() => {
        resetState();
        dispatch(removeDomainSelected());
    }, [dispatch]);

    return (
        <Container fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={3} className="mb-3">
                    <Container>
                        <Row>
                            <Col>
                                <h1>{t("domains.domains_title")}</h1>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="d-flex justify-content-end">
                                <Button size="sm" variant="primary" onClick={() => setState(prevState => ({ ...prevState, isNewDomain: true }))}>{t("domains.action.create_new")}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DomainsMap>
                                    {
                                        Array
                                            .from(domainList)
                                            .filter(domain => isParentDomain(domain))
                                            .sort((a, b) => {
                                                // * Sort by id
                                                const idx = a.id || 0;
                                                const idy = b.id || 0;
                                                return idx - idy;
                                            })
                                            .map(domain => mapDomainsRender(domain))
                                    }
                                </DomainsMap>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={12} sm={12} md={12} lg={9}>
                    <Container fluid>
                        <Row>
                            {(domainIdSelected !== 0 && !isNewDomain) && (
                                <Col>
                                    <h2>{t("domains.domain_details.domain_details_title")}</h2>
                                    <DomainDetails
                                        domain={domainSelected}
                                        cancelDetails={handleCancelDetailsView}
                                        handleSubmitUpdateDomain={handleSubmitUpdateDomain}
                                        users={usersByDomain}
                                    />
                                </Col>
                            )}
                            {isNewDomain && (
                                <Col>
                                    <h2>{t("domains.action.create_new_domain")}</h2>
                                    <NewDomain
                                        cancelCreate={() => resetState()}
                                        handleSubmitNewDomain={handleSubmitNewDomain}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default withTranslation()(DomainsPage);
