import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { useEntityTable } from "../EntityTable/EntityTable";
import { capitalizeFirstLetter } from "../../../utils/utils";

const FormCreateView = (props: any) => {
  const { t } = useTranslation();
  const { handleCustomViewSubmit } = useEntityTable();

  const [viewNameInput, setViewNameInput] = useState<string>("");
  const [viewDescriptionInput, setViewDescriptionInput] = useState<string>("");

  const changeNameViewInputHandler = (e: any) => {
    setViewNameInput(e.target.value);
  };

  const changeDescriptionViewInputHandler = (e: any) => {
    setViewDescriptionInput(e.target.value);
  };
  const handleSubmit = (e: any) => {
    handleCustomViewSubmit(e, viewNameInput, viewDescriptionInput)
    resetForm();
  };

  const resetForm = () => {
    setViewNameInput("");
    setViewDescriptionInput("");
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>{capitalizeFirstLetter(t("entity_table.view_name", { context: "label" }))}</Form.Label>
        <Form.Control
          type="text"
          className="w-100 mb-2"
          onChange={changeNameViewInputHandler}
          value={viewNameInput}
          placeholder={capitalizeFirstLetter(t("entity_table.view_name", { context: "placeholder" }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{capitalizeFirstLetter(t("entity_table.view_description", { context: "label" }))}</Form.Label>
        <Form.Control
          placeholder={capitalizeFirstLetter(t("entity_table.view_description", { context: "placeholder" }))}
          onChange={changeDescriptionViewInputHandler}
          value={viewDescriptionInput}
          className="w-100 mb-2"
          as="textarea"
          rows={3}
        />
      </Form.Group>
      <Form.Group>
        <Button className="w-100 mb-2 mt-2" variant="primary" type="submit">
          {capitalizeFirstLetter(t("entity_table.create_view_now"))}
        </Button>
      </Form.Group>
    </Form>
  );
}

export default FormCreateView;
