import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SuccessHttpState {
  status: boolean;
  name: string;
}

const initialState: SuccessHttpState = {
  status: false,
  name: "",
};

export const SuccessHttpSlice = createSlice({
  name: "successHttp",
  initialState,
  reducers: {
    activate: (state, action:  PayloadAction<SuccessHttpState>) => {
      state.status = true;
      state.name = action.payload.name;
    },
    inactivated: (state) => {
      state.status = false;
    },
  },
});

export const { activate, inactivated } = SuccessHttpSlice.actions;
export const getSuccessState = (state: RootState) => state.successHttp;

export default SuccessHttpSlice.reducer;
