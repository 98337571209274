import { createContext, useCallback, useContext, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Table from "./Table";
import Form from "./Form";

const MenuContext = createContext<any>(null);

const Menu = () => {
  const navigate = useNavigate();
  const [listOfUsedCategories, setListOfUsedCategories] = useState<
    Array<number> | undefined
  >();

  const redirectCreate = useCallback(
    (listOfUsedCategories: number[], event?: { preventDefault: Function }) => {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      setListOfUsedCategories(listOfUsedCategories);
      navigate("/config/menu/create");
    },
    [navigate]
  );

  const redirectEdit = useCallback(
    (
      id: number,
      listOfUsedCategories: number[],
      event?: { preventDefault: Function }
    ) => {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      setListOfUsedCategories(listOfUsedCategories);
      navigate(`/config/menu/edit/${id}`);
    },
    [navigate]
  );

  return (
    <MenuContext.Provider
      value={{ redirectCreate, redirectEdit, listOfUsedCategories }}
    >
      <Routes>
        <Route path="/" element={<Table />} />
        <Route path="/create" element={<Form type="create" />} />
        <Route path="/edit/:id" element={<Form type="edit" />} />
        <Route path="*" element={<Navigate to="/config/menu" />} />
      </Routes>
    </MenuContext.Provider>
  );
};

export function useMenuContext() {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error(
      "useMenuContext() must be used within a MenuContext provider."
    );
  }
  return context;
}

export default Menu;
