import { useState } from 'react';
import xlsx from 'xlsx';

export default function useExcelReaderService() {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    
    const readFile = (file: any)=> {
        const reader = new FileReader();
        setLoading(true);
        reader.onload = () => {
            const fileContents = reader.result;
            const workhook = xlsx.read(fileContents, { type: 'array', cellDates: true });
            const sheetName = workhook.SheetNames[0];
            const worksheet = workhook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);

            setData(json);
        };
        
        reader.readAsArrayBuffer(file);
        setLoading(false);
    }
    
    return {
        readFile,
        data,
        loading,
        setData
    };
}