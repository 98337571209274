import { useTranslation } from "react-i18next";
import UserProfile from "src/interfaces/IUserProfile";
import { useEffect, useState, FormEvent } from "react";
import PhoneType from "src/interfaces/IPhoneType";
import usePersonService from "src/services/person.service";
import { IServerResponse } from "src/interfaces";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

interface PhoneModalProps {
  showPhonesModal: boolean;
  handleClose: () => void;
  phonedata: UserProfile.IPhone;
  handleConfirm: (phones: UserProfile.IPhone) => void;
}

const PhonesModal = ({
  showPhonesModal = false,
  handleClose,
  phonedata,
  handleConfirm,
}: PhoneModalProps) => {
  const [phoneTypes, setPhoneTypes] = useState<PhoneType.IPhoneType[]>([]);
  const [phoneValue, setPhoneValue] = useState<UserProfile.IPhone>(phonedata);
  const [validated, setValidated] = useState(false);
  const { getPhoneTypes } = usePersonService();
  const { t } = useTranslation();

  useEffect(() => {
    getPhoneTypes({
      fn: (r: IServerResponse<PhoneType.IPhoneType>) => setPhoneTypes(r.data),
    });
  }, [getPhoneTypes]);

  useEffect(() => {
    setPhoneValue(phonedata);
  }, [phonedata]);

  const checkValid = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      handleConfirm({ ...phoneValue });
    }

    setValidated(true);
  };

  return (
    <Modal show={showPhonesModal} onHide={handleClose} size="lg">
      <Form noValidate validated={validated} onSubmit={checkValid}>
        <Modal.Header closeButton>
          <Modal.Title>{t("user_profile.phone_modal_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="phoneNumber">
                <Form.Label>{t("user_profile.phones.phone_number")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={phoneValue?.phone_number}
                  onChange={(e) => {
                    setPhoneValue({
                      ...phoneValue,
                      phone_number: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.phones.phone_number_feeback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="phoneType">
                <Form.Label>{t("user_profile.phones.phone_type")}</Form.Label>
                <Form.Select
                  required
                  value={phoneValue?.phone_type}
                  onChange={(e) => {
                    setPhoneValue({
                      ...phoneValue,
                      phone_type: Number(e.target.value),
                      type_description: phoneTypes.find(
                        (type) => type.id === Number(e.target.value)
                      )?.type_description,
                    });
                  }}
                >
                  <option value="">{t("user_profile.choose_an_option")}</option>
                  {phoneTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type_description}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.phones.phone_type_feeback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="phoneActive">
                <Form.Label>{t("user_profile.phones.active")}</Form.Label>
                <Form.Check
                  type="switch"
                  id="active"
                  checked={phoneValue?.active}
                  onChange={(e) => {
                    setPhoneValue({
                      ...phoneValue,
                      active: !phoneValue.active,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="tertiary" onClick={handleClose}>
                {t("label.action.cancel", { ns: "application.misc" })}
              </Button>
              <Button type="submit">
                {t("label.action.save", { ns: "application.misc" })}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PhonesModal;
