import { Col, Container, Row } from "react-bootstrap";
import DomainForm from "./DomainForm";
import UsersByDomain from "./UsersByDomain";
import { DomainSelected, UsersByDomainType } from "./types";

interface DomainDetailsProps {
  domain?: DomainSelected;
  cancelDetails: Function;
  handleSubmitUpdateDomain: Function;
  users: UsersByDomainType[];
}

const DomainDetails = ({ domain, users, cancelDetails, handleSubmitUpdateDomain }: DomainDetailsProps) => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <DomainForm domain={domain} handleDomain={handleSubmitUpdateDomain} cancelForm={cancelDetails} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <UsersByDomain users={users} />
                </Col>
            </Row>
        </Container>
    );
};

export default DomainDetails;
