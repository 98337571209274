import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

import { Field, FieldInputProps } from "../../../interfaces";
import { DateTime } from "./InputTypes";

type Props = FieldInputProps & {
  field: Field;
};

const FieldInput: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  // ? Modification to target the current field declaration.
  // ? Makes copy without type.
  const modProps = Object.assign({}, props);

  type FieldType = {
    [key: string]: () => JSX.Element;
  };

  const fieldTypes: FieldType = useMemo(() => {
    if (!Boolean(modProps.type)) {
      modProps.required = false;
    }

    const { type, ...restProps } = modProps;

    const fileProps: Object = {
      type: "file",
      value: modProps.readOnly ? modProps.value : undefined,
      onChange: modProps.readOnly ? "" : modProps.onChange,
    };

    const dateProps: Object = {
      type: modProps.readOnly ? "text" : "date",
      onChange: modProps.readOnly ? null : modProps.onChange,
    };

    const formatValue = (data: string | boolean | any) => {
      let value: string | boolean = data;
      if (typeof value === "string") value = value === "true" ? true : false;
      return value;
    };

    const switchProps: Object = {
      value: formatValue(modProps.value),
      checked: formatValue(modProps.checked),
      required: modProps.checkFormSignature,
      type: modProps.readOnly ? "text" : "switch",
      onChange: modProps.readOnly ? null : modProps.onChange,
    };

    return {
      text: () => <Form.Control {...modProps} as="input" role="text" />, // ! case IFields.Text:
      number: () => <Form.Control {...modProps} type="number" role="number" />, // ! case IFields.Number:
      float: () => (
        <Form.Control {...restProps} type="number" role="number" step="0.01" />
      ),
      // ! case IFields.TextArea:
      // ? 'textArea' lowered to 'textarea'.
      // * as=IFields.TextArea.toLowerCase()
      textarea: () => (
        <Form.Control {...modProps} as="textarea" role="textarea" />
      ),
      // ! case IFields.File:
      binary: () => <Form.Control {...modProps} {...fileProps} role="file" />,
      file: () => <Form.Control {...modProps} {...fileProps} role="file" />,
      image: () => {
        const cprops = {
          type: "file",
          role: "image",
          placeholder: "",
          name: modProps.name,
          onChange: modProps.onChange,
          accept: "image/*",
          maxFileSize: 2 * 1024 * 1024,
        };

        return <Form.Control {...cprops} />;
      },
      datetime: () => (
        // ! case IFields.Date:
        <DateTime
          value={modProps.value}
          field={modProps.field}
          onChange={modProps.onChange}
        />
      ),
      date: () => <Form.Control {...modProps} {...dateProps} role="date" />,
      time: () => <Form.Control {...modProps} type="time" role="time" />,
      // ? Checkbox should not be required to accept a false value.
      // ? Pass checkFormSignature if element requires to be checked.
      boolean: () => {
        // ! case IFields.Boolean:
        if (modProps.readOnly) {
          return <Form.Control {...modProps} {...switchProps} />;
        } else {
          let props = { ...modProps, label: "" }; // label attribute can't be deleted due FieldInputProps interface
          return <Form.Switch {...props} {...switchProps} role="checkbox" />;
        }
      },
      dropdown: () => (
        // ! case IFields.Dropdown:
        <Form.Select {...modProps} role="dropdown">
          <option value="" disabled selected hidden>
            {t("label.action.select", { ns: "application.misc" })}
          </option>
          {modProps.options?.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ),

      spatial: () => <></>,
      default: () => (
        <Form.Control {...modProps} as="textarea" role="textarea" />
      ),
    };
  }, [modProps, t]);

  const createTypedField = useCallback((): JSX.Element => {
    let type = modProps.type;

    if (type === "string" || type === "object") {
      type = "text";
    }

    if (type === "numeric" || type === "integer") {
      type = "number";
    }

    if (type === "textArea") {
      type = "textarea";
    }

    if (type === "checkbox" || type === "switch") {
      type = "boolean";
    }

    const fieldType = fieldTypes[type || "default"];

    return fieldType();
  }, [modProps.type, fieldTypes]);

  return createTypedField();
};

export default FieldInput;
