class GridColumn {
  gridColumnStart: number;
  gridColumnEnd: number;
  constructor(columnStart: number, columnEnd: number) {
    this.gridColumnStart = columnStart;
    this.gridColumnEnd = columnEnd;
  }
}
export class GridOrder {
  active: GridColumn;
  name: GridColumn;
  description: GridColumn;
  private totalColumns: number = 12;
  private currentColumn: number = 12;
  constructor(level: number) {
    this.currentColumn = level + 1;
    this.active = new GridColumn(
      this.currentColumn,
      this.privateIncrementCurrent(1)
    );
    this.name = new GridColumn(
      this.currentColumn,
      this.privateIncrementCurrent(3)
    );
    this.description = new GridColumn(
      this.currentColumn,
      this.privateIncrementCurrent(3)
    );
  }
  privateIncrementCurrent(increment: number) {
    this.currentColumn = this.currentColumn + increment;
    return this.currentColumn;
  }
}
