
import React from "react";
import Button from "react-bootstrap/Button";

function ProfileButton(props: any) {
  function handleClick(e:any) {
    e.preventDefault()
    props.onClick();
  }
  return (
    <React.Fragment>
      {props.valid ? (
        <Button onClick={handleClick} variant={props.variant||"secondary"} >{props.children}  </Button>
      ) : (
        <Button onClick={handleClick} disabled>
          {props.children}
        </Button>
      )}
    </React.Fragment>
  );
}

export default ProfileButton;
