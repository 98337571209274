import { useCallback } from "react";
import { useAppSelector } from "src/app/hooks";
import environment from "src/environment";
import HttpHandler from "src/hook/http-handler";
import { getToken } from "src/reducers/auth";

interface IRequestParams {
  data?: object;
  page?: number;
  limit?: number;
  entity?: string;
  params?: any;
  callback?: Function;
  filter?: URLSearchParams[] | string;
}

const permissionAPI = `${environment.apiUrl}/permission`;

const usePermissionService = () => {
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const getMany = useCallback(
    function <IType>({ params = { roleId: "" } }: IRequestParams) {
      return new Promise<IType>((resolve, reject) => {
        try {
          if (credentials && credentials.logged) {
            const { roleId } = params;
            sendRequest(
              {
                url: `${permissionAPI}/${roleId}`,
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                },
              },
              resolve
            );
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    [sendRequest, credentials]
  );

  return {
    getMany,
  };
};

export default usePermissionService;
