import { FC, useCallback, useState } from 'react';
import { TFunction, withTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import styled from 'styled-components';

import useUserDataService from "src/services/user.service";
import { useUserItem } from '../User/UserItem';
import DomainList from './DomainList';
import Vignette from './Vignette';

interface DomainItemProps {
  ownership: Array<number>;
  fullAccess?: boolean;
  userId: number;
  owned: boolean;
  t: TFunction;
  domain: any;
  key: number;

  [key: string]: any;
}

const ListItem = styled.li((): any => ({
  listStyleType: 'none',
  '&:hover': {
    backgroundColor: 'inherit !important',
    color: 'inherit !important',
    cursor: 'pointer',
  },
}));

const DomainItem: FC<DomainItemProps> = ({ ownership, fullAccess = false, userId, owned, domain, key, t }) => {
  const { toggleDomain } = useUserDataService();
  const { setUserProperty } = useUserItem();

  const [open, setOpen] = useState<boolean>(false);

  const patchDomain = useCallback(() => {
    toggleDomain({
      userId,
      domainId: domain.id,
      fn: (response: any) => {
        if (response.data instanceof Array) {
          const domains: Array<number> = [];
          for (const row of response.data) {
            domains.push(row.domain_id);
          }
          setUserProperty({ domains });
        }
      },
    });
  }, [domain.id, setUserProperty, toggleDomain, userId]);

  return (
    <Container fluid>
      <Form.Check
        checked={owned || fullAccess}
        onChange={patchDomain}
        disabled={fullAccess}
        type="checkbox"
        label={
          <ListItem className="d-flex justify-content-start align-items-center">
            <Vignette
              open={open}
              onClick={() => setOpen(!open)}
            />
            <span>{' '.concat(domain.name)}</span>
          </ListItem>
        }
      />
      <DomainList
        fullAccess={owned || fullAccess}
        ownership={ownership}
        subdomain={domain.id}
        userId={userId}
        open={open}
      />
    </Container>
  );
};

export default withTranslation()(DomainItem);