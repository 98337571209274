import { Fragment, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Card, Row, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Field } from "src/interfaces";
interface IPropertyCardProps {
  field: Field;
  value: any;

  cardtype: "key" | "entity" | "relationship";
  extra?: {
    entity?: string;
    relationship?: string;
  };

  [key: string]: any;
}

const PropertyCard = ({
  field,
  value,
  cardtype,
  ...props
}: IPropertyCardProps) => {
  const { name, type } = field;

  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof props.entity === "string") {
      setNamespace("entities.".concat(props.entity));
    }
  }, [props.entity]);

  const border = useMemo(() => {
    switch (cardtype) {
      case "key":
        return "success";

      case "relationship":
        return "warning";

      case "entity":
      default:
        return "light";
    }
  }, [cardtype]);

  const formatDate = (date: string) => {
    // ? Date is already in ISO format.
    try {
      const dateObj = new Date(date);
      return dateObj.toUTCString();
    } catch (e) {
      return date;
    }
  };

  const format = useMemo(() => {
    if (value === null || value === undefined)
      return <Fragment children="N/A" />;

    switch (type) {
      case "dropdown":
        const option = field.options?.find((option) => option.value === value);
        return <Fragment children={option?.label || value} />;
      case "integer":
      case "number":
      case "float":
        return <Fragment children={Number(value)} />;
      case "string":
      case "text":
        return <Fragment children={String(value)} />;
      case "boolean":
        if (typeof value === "boolean") {
          return (
            <Fragment children={<Form.Switch checked={value} disabled />} />
          );
        }
        const booleanValue = value.toLowerCase() === "true" ? true : false;
        return (
          <Fragment
            children={<Form.Switch checked={booleanValue} disabled />}
          />
        );
      case "datetime":
      case "date":
        return <Fragment children={formatDate(value)} />;
      case "file":
        if (typeof value !== "string")
          return <Fragment children="Cannot display this file." />;

        if (value.includes("image/")) {
          return (
            <figure className="d-flex justify-content-center">
              <img style={{ maxHeight: "120px" }} src={value} alt={name} />
            </figure>
          );
        }

        if (value.includes("/pdf")) {
          return (
            <Fragment>
              <a
                href={value}
                download={name.concat(".pdf")}
                children={
                  <Trans
                    i18nKey="label.action.view_file"
                    values={{ name }}
                    ns="application.misc"
                  />
                }
              />
            </Fragment>
          );
        }

        return null;
      case "image":
        if (typeof value !== "string")
          return <Fragment children="Cannot display this image." />;

        return (
          <figure className="d-flex justify-content-center">
            <img style={{ maxHeight: "120px" }} src={value} alt={name} />
          </figure>
        );
      case "binary":
        return (
          <Fragment>
            <a
              href={value}
              download={name}
              children={
                <Trans
                  i18nKey="label.action.view_file"
                  values={{ name }}
                  ns="application.misc"
                />
              }
            />
          </Fragment>
        );
      default:
        return null;
    }
  }, [field.options, name, type, value]);

  return (
    <Card border={border} className="h-100 w-100 bg-body rounded shadow">
      <Card.Header className="text-wrap">
        <Row
          as="span"
          className="d-flex justify-content-start align-items-center px-3"
          children={
            props.extra?.relationship
              ? tWithEntityNamespace("fields.".concat(name), {
                  ns: "entities." + props.extra?.entity,
                })
              : tWithEntityNamespace("fields.".concat(name))
          }
        />
      </Card.Header>
      <Card.Body className="p-3" children={format || "N/A"} />
      {(props.extra?.relationship || props.extra?.entity) && (
        <Card.Footer
          className="overflow-hidden"
          children={
            <Badge
              className="w-100 text-truncate"
              children={tWithEntityNamespace(
                "relationships." + props.extra?.relationship
              )}
              bg={border}
              text={["light"].includes(border) ? "dark" : "light"}
            />
          }
        />
      )}
    </Card>
  );
};

export default PropertyCard;
