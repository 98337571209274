import React, {
  useState,
  useCallback,
  Fragment,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { CaretRightFill } from "react-bootstrap-icons";
import { withTranslation, TFunction, useTranslation } from "react-i18next";
import {
  Badge,
  Button,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import { update, selectDomain } from "../../../reducers/domain/domainSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import TranlationSwitcher from "../Input/translationSwitcher";
import useMenuSettings from "../../../hook/useMenuSettings";
import useLocalStorage from "../../../hook/useLocalStorage";
import { unauthorize } from "../../../reducers/auth";
import { useAuth } from "./../../../hook/useAuth";
import AlertHttp from "./AlertHttp";

import { Domain, IServerResponse, Permissions } from "../../../interfaces";
import usePermissionService from "src/services/permission.service";
import { setState } from "src/reducers/permissions";

interface MenuProps {
  t: TFunction;
  handleLanguage: (...args: any[]) => void;
}

const Menu: React.FC<MenuProps> = () => {
  const { t, i18n } = useTranslation();

  // ? --- Redux Hooks ---
  const dispatch = useAppDispatch();
  const domain = useAppSelector(selectDomain);

  // ? --- Custom Hooks ---
  const settings = useMenuSettings();
  const { user } = useAuth();
  const { getMany: getAPIPermissions } = usePermissionService();
  const { storeValue: storeDomain, storedValue } = useLocalStorage("domain");

  // ? --- React Hooks ---
  const navbar = useRef<HTMLDivElement>(null);
  const [domainStack, setDomainStack] = useState<Array<Domain.IDomain>>([]);

  const roleId = useMemo(() => user?.role_id, [user]);

  const handleLogout = useCallback(() => dispatch(unauthorize), [dispatch]);

  const refreshPermissions = useCallback(() => {
    getAPIPermissions<IServerResponse<Permissions.IPermissions>>({
      params: { roleId: roleId },
    })
      .then((r) => r.data)
      .then((r) => dispatch(setState({ roleId, permissions: r })))
      .catch((e: Error) => {
        console.error(e);
      });
  }, [getAPIPermissions, roleId, dispatch]);

  const domains = useMemo(() => {
    try {
      if (user.domains instanceof Array) {
        // ? Domains sorted by id.
        return (user.domains as Array<Domain.IDomain>).sort(
          (a, b) => Number(a.id) - Number(b.id)
        );
      }
      throw new Error(
        "[Navbar::domains] Instance of Array<Domain.IDomain> expected on response."
      );
    } catch (error) {
      console.error(error);
      return [] as Array<Domain.IDomain>;
    }
  }, [user.domains]);

  const domainWidget = useMemo(() => {
    const getSubDomains = (domain: Domain.IDomain | Domain.ISubDomain) => {
      if ("subDomains" in domain && domain.subDomains) {
        return domain.subDomains;
      }

      if ("children" in domain && domain.children) {
        return domains.filter((d) => domain.children?.includes(d.id as number));
      }
      return [];
    };

    const collection =
      domainStack.length > 0 ? getSubDomains(domainStack.at(-1)!) : domains;

    return (
      <Fragment>
        <NavDropdown.Header>{t("navbar.select_domain")}</NavDropdown.Header>
        <ListGroup style={{ minWidth: "350px" }} variant="flush">
          {collection?.map((domain) => (
            <ListGroup.Item
              key={domain.id}
              className="d-flex justify-content-between align-items-center"
            >
              <NavDropdown.Item
                children={domain.name}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onClick={() => {
                  storeDomain({
                    key: "domain",
                    initialValue: domain,
                  });
                  dispatch(
                    update({
                      id: domain.id.toString(),
                      name: domain.name,
                    })
                  );
                }}
              />
              {getSubDomains(domain)?.length > 0 && (
                <Badge
                  bg="dark"
                  className="h-100 p-2"
                  onClick={() => setDomainStack((stack) => [...stack, domain])}
                  children={<CaretRightFill style={{ cursor: "pointer" }} />}
                />
              )}
            </ListGroup.Item>
          ))}
          {domainStack.at(-1) && (
            <ListGroup.Item>
              <Button
                variant="dark"
                className="w-100 m-1"
                children={t("navbar.return")}
                onClick={() => setDomainStack((stack) => stack.slice(0, -1))}
              />
            </ListGroup.Item>
          )}
        </ListGroup>
      </Fragment>
    );
  }, [dispatch, domainStack, domains, storeDomain, t]);

  useEffect(() => {
    const idx = domains.find(
      (col) => Number(col.id) === Number(storedValue.parent_id)
    );

    if (idx) setDomainStack([idx]);
    else setDomainStack([]);
  }, [domains, storedValue.parent_id]);

  const loadEntityNamespace = useCallback(
    (bundle: string = "*") => {
      const ns = "entities." + bundle;
      if (!i18n.hasResourceBundle(i18n.language, ns)) {
        i18n.loadNamespaces(ns);
      }
      return ns;
    },
    [i18n]
  );

  useEffect(() => {
    if (roleId) refreshPermissions();
  }, [roleId, refreshPermissions]);

  return (
    <Fragment>
      <Navbar
        collapseOnSelect
        className="px-3"
        variant="dark"
        ref={navbar}
        expand="sm"
        bg="dark"
      >
        <Navbar.Brand className="text-truncate">
          {user.active
            ? domain.name
              ? domain.name.concat(" - ")
              : t("navbar.no_domain_selected").concat(" - ")
            : ""}
          Dynamic Apps &copy; {new Date().getFullYear()}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="da-top-navbar"
          data-bs-target="#da-top-navbar"
        />

        <Navbar.Collapse id="da-top-navbar" className="justify-content-end">
          <Nav>
            <Nav.Link as={Link} to="/">
              {t("navbar.home")}
            </Nav.Link>

            {
              // ? Display the domain menu if user is logged in.
              user.active && (
                <NavDropdown
                  align="end"
                  className="p-0"
                  id="menu.domains"
                  title={t("navbar.domains")}
                  children={domainWidget}
                />
              )
            }

            {user.active && (
              <NavDropdown id="menu.entities" title={t("navbar.entities")}>
                {settings &&
                  settings.data.main_menu.entities.map(
                    (item, index: React.Key) => (
                      <NavDropdown.Item
                        as={Link}
                        key={index}
                        to={`/${item.base_entity.object_name}`}
                        children={capitalize(
                          t("label", {
                            ns: loadEntityNamespace(
                              item.base_entity.object_name
                            ),
                            defaultValue: item.base_entity.object_name.replace(
                              /_/g,
                              " "
                            ),
                          })
                        )}
                      />
                    )
                  )}
              </NavDropdown>
            )}

            {user.active &&
              settings &&
              settings.data.main_menu.reports.length > 0 && (
                <NavDropdown id="menu.reports" title={t("navbar.reports")}>
                  {settings.data.main_menu.reports.map(
                    (reportItem: any, index: React.Key) => (
                      <NavDropdown.Item
                        as={Link}
                        key={index}
                        to={`/reports/${reportItem.report_name}`}
                        children={t(reportItem.report_label)}
                        target="_blank"
                      />
                    )
                  )}
                </NavDropdown>
              )}

            {
              // ? Display user configuration menu if user is logged in.
              user.active && (
                <NavDropdown title={user.username} id="menu.user">
                  <NavDropdown.Item as={Link} to="/config">
                    {t("navbar.settings")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    onClick={handleLogout}
                    to="/login"
                  >
                    {t("navbar.logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              )
            }

            {
              // ? Else display login and register menu on navigation bar.
              !user.active && (
                <NavDropdown
                  title={t("navbar.unauthenticated")}
                  id="collaspe-nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/login">
                    {t("navbar.login")}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/register">
                    {t("navbar.register")}
                  </NavDropdown.Item>
                </NavDropdown>
              )
            }
            <TranlationSwitcher />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AlertHttp />
    </Fragment>
  );
};

export default withTranslation()(Menu);
