import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useCallback } from "react";

// ? --- React Redux Selectors ---
import { useAppSelector } from "../app/hooks";
import { selectDomain } from "../reducers/domain/domainSlice";
import { getToken } from "../reducers/auth";

interface IGetAllParams {
  fn?: Function;
  limit?: number;
  page?: number;
  filter?: string;
  data?: Object;
  id?: number | string;
}

const PersonAPI = `${environment.apiUrl}/people`;
const PhoneTypeAPI = `${environment.apiUrl}/phoneTypes`;

export default function usePersonService() {
  const currentDomain = useAppSelector(selectDomain);
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const create = useCallback(
    function <IType = any>({ data, fn: callback = () => { } }: IGetAllParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          }

          sendRequest({
            url: PersonAPI,
            method: "POST",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          }, finish);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  // const edit = useCallback(
  //   async ({ fn, data, id }: IGetAllParams) => {
  //     if (credentials && credentials.logged) {
  //       sendRequest(
  //         {
  //           url: `${PersonAPI}/${id}`,
  //           method: "PUT",
  //           body: data,
  //           headers: {
  //             Authorization: `${credentials.token_type} ${credentials.access_token}`,
  //             domain: currentDomain.id,
  //           },
  //         },
  //         fn
  //       );
  //     }
  //   },
  //   [credentials, currentDomain.id, sendRequest]
  // );

  const edit = useCallback(
    function <IType = any>({ data, fn: callback = () => { }, id }: IGetAllParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          }
          
          sendRequest({
            url: `${PersonAPI}/${id}`,
            method: "PUT",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          }, finish);
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const _delete = useCallback(
    async ({ fn, id }: IGetAllParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${PersonAPI}/${id}`,
            method: "DELETE",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getMany = useCallback(
    async ({ fn, page = 1, limit = 10 }: IGetAllParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${PersonAPI}?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getOne = useCallback(
    function <IType = any>({ id = -1, fn: callback = () => { } }: IGetAllParams): Promise<any> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          }

          sendRequest({
            url: `${PersonAPI}/${id}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          }, finish);
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getPhoneTypes = useCallback(
    async ({ fn }: IGetAllParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${PhoneTypeAPI}/`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getUsersById = useCallback(
    function <IType = any>({ id = -1, fn: callback = () => { } }: IGetAllParams): Promise<any> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          }

          sendRequest({
            url: `${PersonAPI}/${id}/users`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          }, finish);
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const updatePerson = useCallback(
    async ({ fn, id, data}: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${PersonAPI}/${id}`,
            method: "PUT",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  return {
    create,
    edit,
    delete: _delete,
    _delete,
    getMany,
    getOne,
    getPhoneTypes,
    getUsersById,
    updatePerson
  };
}