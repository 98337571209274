import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import successHttpReducer from "../reducers/errorHttp/successHttpSlice";
import errorHttpReducer from "../reducers/errorHttp/errorHttpSlice";
import domainsReducer from "../reducers/domain/domains";
import domainReducer from "../reducers/domain/domainSlice";
import alertReducer from "../reducers/alert";
import authReducer from "../reducers/auth";
import permissionsReducer from "../reducers/permissions";

export const store = configureStore({
  reducer: {
    domains: domainsReducer,
    domain: domainReducer,
    errorHttp: errorHttpReducer,
    successHttp: successHttpReducer,
    alert: alertReducer,
    auth: authReducer,
    permissions: permissionsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
