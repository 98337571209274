import { RootState } from "../../app/store";
import { createSlice, Slice } from "@reduxjs/toolkit";

interface State {
  type: string;
  status: number;
  active: boolean;
  message: string;
  errorMessage: string;
  errors: string[];
  timestamp: number;
}

const initial: State = {
  type: "",
  status: 0,
  message: "",
  errors: [""],
  timestamp: 0,
  active: false,
  errorMessage: "",
};

const AlertSlice: Slice<State> = createSlice({
  name: "alert",
  initialState: initial,
  reducers: {
    activate: (state, action) => ({
      ...state,
      active: true,
    }),
    inactivate: (state, action) => ({
      ...state,
      active: false,
    }),
    setState: (state, action) => {
      let { status, type, message, errors, errorMessage } = action.payload;
      let timestamp = Date.now();

      type = type || "";
      status = status || 0;
      message = message || "UnknownError";
      errors = errors || ["error.unknown"];
      errorMessage = errorMessage || "UnknownError";
      return {
        ...state,
        type,
        status,
        errors,
        message,
        errorMessage,
        timestamp,
      };
    },
  },
});

export const { activate, inactivate, setState } = AlertSlice.actions;
export const getAlert = (state: RootState) => state.alert;

export default AlertSlice.reducer;
