import { useEffect, useState, useCallback, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import useEntityService from "../../../../services/entity.service";
import DymForm from "../../../UI/Form/DymForm";

const EditEntity = (props: any) => {
  const { t } = useTranslation();
  const { entity, id } = useParams();
  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  const { getSingleRecordWithRelationships } = useEntityService();
  const [data, setData] = useState<any>({
    data: [],
  });

  const getData = useCallback(async () => {
    if (entity) {
      await getSingleRecordWithRelationships({ fn: setData, entity, id });
    }
  }, [entity, id, getSingleRecordWithRelationships]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!entity || !id) {
    return null;
  }

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          {t("label.action.update", { ns: "application.misc" }).concat(
            " ",
            capitalize(tWithEntityNamespace("label").toLowerCase())
          )}
        </Col>
      </Row>
      <Row>
        <DymForm
          fetchNewData={getData}
          entity={entity}
          data={data}
          id={id}
          type="PUT"
          onSubmit={function (...args: any[]) {
            throw new Error("Function not implemented.");
          }}
          setExternalErrors={function (errors: string[]) {
            throw new Error("Function not implemented.");
          }}
        />
      </Row>
    </Container>
  );
};

export default EditEntity;
