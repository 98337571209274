import { MouseEvent, useMemo, FC } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Nav } from "react-bootstrap";
import { EyeFill, List, Trash } from "react-bootstrap-icons";

import useLocalStorage from "../../../hook/useLocalStorage";
import { Role } from "../../../interfaces";

interface RoleListItemProps {
  active: boolean;
  role: Role.IRole;
  provided: DraggableProvided;
  onRoleClick: (role: Role.IRole) => void;
  onRoleDelete: (role: Role.IRole) => void;
}

const RoleListItem: FC<RoleListItemProps> = ({
  role,
  active,
  onRoleClick,
  onRoleDelete,
  provided,
}) => {
  const { storedValue: userHierarchy } = useLocalStorage<Role.Hierarchy>(
    "UserHierarchy",
    {}
  );

  const isBlockedFrom = useMemo(() => {
    const { roles } = userHierarchy;

    if (!roles || !(typeof roles === "object")) {
      return 0;
    }

    const [higherRolePosition] = Object.values(roles)
      .map((role) => role.position)
      .map(Number)
      .filter(isFinite)
      .sort((a, b) => a - b);

    // ? If there are no roles, then the user is blocked from everything.
    // * highestRolePosition := 0;
    return higherRolePosition || 0;
  }, [userHierarchy]);

  return (
    <Nav.Link
      style={{ cursor: "pointer" }}
      onClick={(e: MouseEvent<HTMLDivElement>) => onRoleClick(role)}
      className={
        active
          ? "active"
          : "".concat(
              "d-flex flex-row justify-content-start align-items-center"
            )
      }
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={provided.innerRef}
      children={
        isBlockedFrom <= role.position
          ? [
              <EyeFill
                onClick={(e: MouseEvent<SVGElement>) => onRoleClick(role)}
              />,
              <span className="">&nbsp;</span>,
              <List
                className=""
                onClick={(e: MouseEvent<SVGElement>) => onRoleClick(role)}
              />,
              <span className="">&nbsp;</span>,
              <span className="">{role.item}</span>,
            ]
          : [
              <Trash
                className=""
                onClick={(e: MouseEvent<SVGElement>) => onRoleDelete(role)}
              />,
              <span className="">&nbsp;</span>,
              <List
                className=""
                onClick={(e: MouseEvent<SVGElement>) => onRoleClick(role)}
              />,
              <span className="">&nbsp;</span>,
              <span className="">{role.item}</span>,
            ]
      }
      as={Nav.Item}
    />
  );
};

export default RoleListItem;
