export function capitalizeFirstLetter(text: any): string {
    const string = text;
    const stringFormatted = string.charAt(0).toUpperCase() + string.slice(1);
    return changeUnderscoreForSpaces(stringFormatted);
}

export function changeSpacesForUnderscore(text: any): string {
    return text.replace(/\s/g, '_');
}

export function changeUnderscoreForSpaces(text: any): string {
    return text.replace(/_/g, ' ');
}

export function parseDotStringToObject(list: Array<{ path: string, value: any }>, obj = {}): any {
    // list = [{path: 'a.b.c', value: 'd'}, {path: 'a.b.d', value: 'e'}];
    // obj = {};
    // return {a: {b: {c: 'd', d: 'e'}}};

    list.forEach(item => {
        const path = item.path.split('.');
        let currentObj: any = obj;

        path.forEach((key, index) => {
            if (index === path.length - 1) {
                currentObj[key] = item.value;
            } else {
                if (!currentObj[key]) {
                    currentObj[key] = {};
                }
                currentObj = currentObj[key];
            }
        });
    });

    return obj;
}

export function isObject(object: any): boolean {
    return object != null && typeof object === 'object';
}

export function areDeepEqual(original: any, duplicate: any): boolean {
    const kOriginal = Object.keys(original);
    const kDuplicate = Object.keys(duplicate);
    if (kOriginal.length !== kDuplicate.length) {
        return false;
    }
    for (const key of kOriginal) {
        const vOriginal = original[key];
        const vDuplicate = duplicate[key];
        const areObjects = isObject(vOriginal) && isObject(vDuplicate);
        if (
            false
            || (areObjects && !areDeepEqual(vOriginal, vDuplicate))
            || (!areObjects && vOriginal !== vDuplicate)
        ) {
            return false;
        }
    }
    return true;
}