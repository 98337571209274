import { FC, useMemo } from "react";
import { TFunction, withTranslation } from "react-i18next";
import { CaretDownFill, CaretRightFill } from "react-bootstrap-icons";

interface VignetteProps {
  onClick?: () => void;
  open: boolean;
  t: TFunction;

  [key: string]: any;
};

const Vignette: FC<VignetteProps> = ({ onClick,  open, t }) => {
  const Icon = useMemo(() => open ? CaretDownFill : CaretRightFill, [open]);

  return (
    <Icon
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  );
};

export default withTranslation()(Vignette);