import { useState, useEffect } from "react";
const useContextMenu = () => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPoints, setContextMenuPoints] = useState({
    x: 0,
    y: 0,
  });
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [selectedContextMenuAction, setSelectedContextMenuAction] = useState("");
  const [groupingField, setGroupingField] = useState("")

  useEffect(() => {
    const handleClick = () => setShowContextMenu(false);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  
  return {
    showContextMenu,
    setShowContextMenu,
    contextMenuPoints,
    setContextMenuPoints,
    selectedFieldName,
    setSelectedFieldName,
    selectedContextMenuAction, 
    setSelectedContextMenuAction,
    groupingField, 
    setGroupingField
  };
};
export default useContextMenu;