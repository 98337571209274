import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { Field, Entity } from "src/interfaces";

const extension = "xlsx";
const mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

interface IExportExcelProps {
  isTree: boolean;
  fileName: string;
  sheetName: string;
  buttonText: string;
  template?: boolean;

  columns: Array<Field>;
  data: Array<Entity.IEntity>;
}

export const ExportExcel: React.FC<IExportExcelProps> = ({
  data, columns, isTree,
  fileName, sheetName,
  template = false,
  buttonText,
}) => {
  // ! Legacy code, original uses the tree function
  // const exportToCSV = (csvData: any, fileName: string) => {
  //   if (!props.isTree) {
  //     const ws = XLSX.utils.json_to_sheet(csvData);
  //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const data = new Blob([excelBuffer], { type: mime });
  //     FileSaver.saveAs(data, fileName.concat('.', extension));
  //   } else {
  //     let printArray: any[] = [];
  //     let arrayAux: any[] = [];
  //     let arrayOfAttr: string[] = [];

  //     for (const row of props.columns) {
  //       arrayAux.push(row.name);
  //       arrayOfAttr.push(row.attrName);
  //     }
  //     printArray.push(arrayAux);
  //     for (const row of csvData) {
  //       arrayAux = [];
  //       for (const iterator of arrayOfAttr) {
  //         row[iterator] !== undefined && row[iterator] !== ""
  //           ? arrayAux.push(row[iterator])
  //           : arrayAux.push("");
  //       }
  //       printArray.push(arrayAux);
  //       for (const row2 of row.children) {
  //         let auxArrayExcel: any[] = [];
  //         for (const attrColumn of arrayOfAttr) {
  //           auxArrayExcel.push(row2[attrColumn]);
  //         }
  //         printArray.push(auxArrayExcel);
  //       }
  //     }
  //     const ws = XLSX.utils.aoa_to_sheet(printArray, { cellStyles: true });
  //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const data = new Blob([excelBuffer], { type: mime });
  //     FileSaver.saveAs(data, fileName.concat(".", extension));
  //   }
  // };

  const fields: Array<string> = useMemo(() => {
    return columns
      ? columns.map((column: Field) => column.name)
      : [];
  }, [columns]);

  const entities = useMemo(() => {
    return data
      ? data
        .map((entity: Entity.IEntity) => {
          const current: any = {};

          if (entity.hasOwnProperty("id")) {
            current["id"] = entity.id;
          }

          fields
            .forEach((field: string) => {
              if (entity[`data_${field}`]) {
                current[field] = entity[`data_${field}`];
              }
            });

          return current;
        })
      : [];
  }, [data, fields]);

  const getCheckBoxesValues = (entities: any) => {
    const result = entities.map((entity: any) => {
      const resp = [];
      for (let prop in entity) {
        // @ts-ignore
        resp.push([prop, entity[prop] && React.isValidElement(entity[prop]) ? `${entity[prop]?.props?.checked}` : entity[prop]]);
      }
      return Object.fromEntries(resp);
    });
    return result;
  };

  const generateExcelFile = useCallback(
    () => {
      if (template) {
        const ws = XLSX.utils.aoa_to_sheet([fields]);
        const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: mime });
        return FileSaver.saveAs(blob, fileName.concat(".", extension));
      }

      switch (isTree) {
        case false:
          const newEntities = getCheckBoxesValues(entities);
          const ws = XLSX.utils.json_to_sheet(newEntities);
          const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([excelBuffer], { type: mime });
          return FileSaver.saveAs(blob, fileName.concat(".", extension));
        case true:
          // TODO: Implement tree structure
          console.warn("[ExportExcel] Tree structure not implemented yet.");
          return void 0;
        default:
          throw new Error("[ExportExcel] Cannot generate excel file by structure.");
      }

    },
    [entities, fields, fileName, isTree, sheetName, template]
  );

  return (
    <Button
      onClick={e => generateExcelFile()}
      variant="outline-success"
      children={buttonText}
      className="w-100"
    />
  );
};

export default ExportExcel;