import React from 'react';
import Form from 'react-bootstrap/Form';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';

import FormCreateView from '../Input/FormCreateView';
import FilterContainer from '../Input/filterContainer';
import RelationshipSearchContainer from '../Input/RelationshipSearchContainer';
import { useEntityTable } from './EntityTable';
import { Button } from 'react-bootstrap';
import { SingleValue } from 'react-select';
import Select from 'react-select';
import { IView } from 'src/interfaces';

const { Header, Body, Item } = Accordion;

const FiltersAccordion: React.FC = () => {
	const [t] = useTranslation();
	const {
		viewList,
		handleApplyView,
		dispatchCurrentView,
	} = useEntityTable();

	return (
		<Accordion className="my-1">
			<Item eventKey="0">
				<Header
					children={capitalize(t("entity_table.filter_by_data"))} />
				<Body>
					<FilterContainer />
				</Body>
			</Item>
			<Item eventKey="1">
				<Header
					children={capitalize(t("entity_table.filter_by_relation"))} />
				<Body>
					<RelationshipSearchContainer />
				</Body>
			</Item>
			<Item eventKey="2">
				<Header>
					{capitalize(t("entity_table.create_view"))}
				</Header>
				<Body>
					<FormCreateView />
				</Body>
			</Item>
			<Item eventKey="3">
				<Header>
					{capitalize(t("entity_table.apply_view"))}
				</Header>
				<Body>
					<Form onSubmit={handleApplyView}>
						<Form.Group>
							<Form.Label>
								{capitalize(t("entity_table.select_view"))}
							</Form.Label>
							<Select
								noOptionsMessage={() => t("label.help.no_options_available", { ns: "application.misc" })}
								className="w-100 mb-2 mt-2"
								onChange={function (
									item: SingleValue<{
										label: string;
										value: IView;
									}> = { label: "", value: { id: 0 } as IView }
								) {
									if (
										item &&
										item.value.id &&
										item.value.id !== 0
									) {
										dispatchCurrentView({
											id: item.value.id,
										});
									}
								}}
								options={viewList.map((item: IView) => ({
									label: decodeURIComponent(item.name),
									value: item,
								}))}
							/>
						</Form.Group>
						<Form.Group>
							<Button
								className="w-100 mb-2 mt-2"
								variant="primary"
								type="submit"
							>
								{capitalize(t("entity_table.apply_view"))}
							</Button>
						</Form.Group>
					</Form>
				</Body>
			</Item>
		</Accordion>
	);
};

export default FiltersAccordion;
