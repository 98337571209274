import React, {useState} from 'react'
import Menu from '../../UI/Menu/Menu'

export default function AppLayout({ children }: any) {

    const [language, setLanguage] = useState<string>('es');
    
    return (
        <React.Fragment>
            <Menu handleLanguage={setLanguage}/>
            {React.cloneElement(children, {language: language})}
        </React.Fragment>
    )
}
