import { useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import UserProfile from "src/interfaces/IUserProfile";
import { useEffect, useState, FormEvent } from "react";

interface AddressModalProps {
  showAddressModal: boolean;
  handleClose: () => void;
  addressdata: UserProfile.IAddress;
  handleConfirm: (address: UserProfile.IAddress) => void;
}

const AddressesModal = ({
  showAddressModal = false,
  handleClose,
  addressdata,
  handleConfirm,
}: AddressModalProps) => {
  const [addressValue, setAddressValue] =
    useState<UserProfile.IAddress>(addressdata);
  const [validated, setValidated] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setAddressValue(addressdata);
  }, [addressdata]);

  const checkValid = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      handleConfirm(addressValue);
    }

    setValidated(true);
  };

  return (
    <Modal show={showAddressModal} onHide={handleClose} size="lg">
      <Form noValidate validated={validated} onSubmit={checkValid}>
        <Modal.Header closeButton>
          <Modal.Title>{t("user_profile.address_modal_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="streetAddress">
                <Form.Label>{t("user_profile.addresses.street")}</Form.Label>
                <Form.Control
                  type="text"
                  value={addressValue.street}
                  onChange={(e) => {
                    setAddressValue({
                      ...addressValue,
                      street: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.addresses.street_feedback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="cityAddress">
                <Form.Label>{t("user_profile.addresses.city")}</Form.Label>
                <Form.Control
                  type="text"
                  value={addressValue.city}
                  onChange={(e) => {
                    setAddressValue({
                      ...addressValue,
                      city: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.addresses.city_feedback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="stateAddress">
                <Form.Label>{t("user_profile.addresses.state")}</Form.Label>
                <Form.Control
                  type="text"
                  value={addressValue.state}
                  onChange={(e) => {
                    setAddressValue({
                      ...addressValue,
                      state: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.addresses.state_feedback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="postalCodeAddress">
                <Form.Label>
                  {t("user_profile.addresses.postal_code")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={addressValue.postalcode}
                  onChange={(e) => {
                    setAddressValue({
                      ...addressValue,
                      postalcode: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.addresses.postal_code_feedback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="countryAddress">
                <Form.Label>{t("user_profile.addresses.country")}</Form.Label>
                <Form.Control
                  type="text"
                  value={addressValue.country}
                  onChange={(e) => {
                    setAddressValue({
                      ...addressValue,
                      country: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.addresses.country_feedback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="tertiary" onClick={handleClose}>
                {t("label.action.cancel", { ns: "application.misc" })}
              </Button>
              <Button type="submit">
                {t("label.action.save", { ns: "application.misc" })}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddressesModal;
