import i18n from 'i18next';
import { useMemo } from 'react';
import MultiBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

import environment from "../environment";
import { IServerResponse } from 'src/interfaces';
const defaultLocale = localStorage.getItem("language")?.replace(/[^a-z]/gi, '')
	|| process.env.REACT_APP_DEFAULT_LOCALE
	|| "en";

export function useLanguage() {
	const { i18n } = useTranslation();
	const current = useMemo(() => i18n.language, [i18n]);
	return current;
}

class APIResourcesBackend {
	type = 'backend';
	api = environment.apiUrl;

	generateEndpoint(language: string, nsbundle: string): string {
		// ? [GET] /api/config/resources/:language/:namespace?bundle[]=:bundle&version=:version
		const [namespace, bundle] = nsbundle.split('.');
		
		const params = new URLSearchParams({
			'version': 'active',
		});

		if (bundle) {
			params.append('bundle[]', bundle);
		}

		let endpoint = this.api;
		endpoint += '/config/resources';
		endpoint += '/'.concat(language);
		endpoint += '/'.concat(namespace);
		endpoint += '?'.concat(params.toString());

		return endpoint;
	}

	async read(language: string, nsbundle: string) {
		const url = this.generateEndpoint(language, nsbundle);
		const request = await fetch(url.toString(), {
			headers: { 'Content-Type': 'application/json' },
			method: 'GET',
		});

		if (!request.ok) {
			throw new Error('Failed to fetch resources.');
		}

		const response: IServerResponse = await request.json();
		const { data: [resource] } = response;
		const translation = resource[language];
		return translation;
	}

	init() {

	}
}

i18n
	.use(initReactI18next)
	.use(MultiBackend)
	.init({
		fallbackLng: "en",
		lng: defaultLocale,
		interpolation: { escapeValue: false },
		ns: ['application.web', 'application.misc'],
		backend: {
			backends: [
				LocalStorageBackend,
				APIResourcesBackend,
			],
			backendOptions: [
				{
					prefix: 'da.web.i18n.',
					expirationTime: 3600000,
					defaultVersion: 'active',
					store: typeof window !== 'undefined'
						? window.localStorage : null
				},
			],
		},
	})
	.then(() => {
		console.debug("[i18next] Changing language to", defaultLocale);
		localStorage.setItem("language", defaultLocale);
		i18n.changeLanguage(defaultLocale);

		const endpoint = new APIResourcesBackend()
			.generateEndpoint(defaultLocale, 'entities');

		fetch(endpoint.toString(), {
			headers: { 'Content-Type': 'application/json' },
			method: 'GET',
		})
			.then(response => response.json())
			.then(response => {
				if (response.data instanceof Array) {
					response.data.forEach((resource: any) => {
						const translation = resource[defaultLocale];
						i18n.addResourceBundle(defaultLocale, resource.$meta.name, translation);
					});
				}
			})
			.catch(console.error);
	});


export default i18n;