import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ModalForm from "../../../../UI/Modal/ModalForm/ModalForm";
import { ModalProps } from "react-bootstrap";

interface ConfirmModalProps extends ModalProps {
  isModalShowing: any;
  onClose: any;
  header: any;
  email: string;
  onContinue: Function;
}

export default class ConfirmReactivateEmail extends Component<ConfirmModalProps, any> {
  constructor(props: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }
  handleClose() {
    this.props.onClose(false);
  }

  handleContinue() {
    this.props.onContinue();
  }
  render() {
    return (
      <ModalForm
        show={this.props.isModalShowing}
        handleClose={this.props.onClose}
        header={this.props.header}
        size={this.props?.size ?? "lg"}
      >
        <div>
          <h5>{`User with email: ${this.props.email} will be reactivated`}</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "1rem",
              columnGap: "0.5remm",
              justifyContent: "space-around",
            }}
          >
            <div>
              <Button onClick={this.handleClose} variant="primary">
                Cancel
              </Button>
            </div>
            <div>
              <Button onClick={this.handleContinue} variant="success">
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </ModalForm>
    );
  }
}
