import { RootState } from "../../app/store";
import { createSlice, Slice } from "@reduxjs/toolkit";
import { ITokenStorage } from "../../interfaces";

// ? Slice to return localStorage token status

const TokenStorage: ITokenStorage = {
  access_token: "",
  token_type: "",
  expires_in: 0,
  created_at: 0,
  logged: false,
  error: false,
  errors: [],
};

const AuthSlice: Slice = createSlice({
  name: "auth",
  initialState: () => {
    // ? Check if user has stored token in localStorage.
    const persistent = localStorage.getItem("da.authorization.credentials");
    if (persistent) {
      try {
        // ? If token is stored, parse it and set it to the initial state.
        const stored = JSON.parse(persistent);
        return Object.assign({}, TokenStorage, stored);
      } catch (parseError) {
        // ? If token parsing fails, return the default state.
        return TokenStorage;
      }
    } else {
      // ? If no token is stored, return the default state.
      return TokenStorage;
    }
  },
  reducers: {
    setToken: (state: ITokenStorage, action) => {
      // ? If token is stored, parse it and set it to the initial state.
      let stored: ITokenStorage;
      try {
        switch (typeof action.payload) {
          case "string":
            stored = JSON.parse(action.payload) as ITokenStorage;
            stored = stored ?? TokenStorage;
            break;
          case "object":
            stored = action.payload;
            break;
          default:
            stored = TokenStorage;
            break;
        }
        stored = Object.assign({}, state, TokenStorage, stored);
        stored = Object.assign({}, stored, {
          logged: !(stored.error || stored.errors.length) && stored.access_token.length,
        });
        localStorage.setItem("da.authorization.credentials", JSON.stringify(stored));
        return stored;
      } catch (parseError) {
        // ? If token parsing fails, return the default state.
        stored = Object.assign({}, state, TokenStorage);
        stored = Object.assign({}, stored, {
          logged: false,
        });
        localStorage.setItem("da.authorization.credentials", JSON.stringify(stored));
        return stored;
      }
    },
    unauthorize: (state: ITokenStorage, action) => {
      // ? If token is stored,  set it to the initial state.
      let stored = Object.assign({}, state, TokenStorage);
      stored = Object.assign({}, stored, {
        logged: false,
      });

      stored.error = true;
      stored.errors = [
        ...stored.errors,
        "alert.unauthorized",
      ];
      localStorage.setItem("da.authorization.credentials", JSON.stringify(stored));
      return stored;
    },
  },
});

export const getToken = (state: RootState): ITokenStorage => state.auth;
export const isLoggedIn = (state: RootState) => state.auth.logged;
export const { setToken, unauthorize } = AuthSlice.actions;

export default AuthSlice.reducer;
