import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { TFunction, withTranslation } from "react-i18next";
import Pagination from "rsuite/Pagination";
import RTable from "rsuite/Table";
import capitalize from "lodash/capitalize";

import usePersonService from "src/services/person.service";
import { IPagination, IServerResponse } from "src/interfaces";
import { useUsersContext } from "./Users";

interface TableProps {
  t: TFunction;
}

function Table({ t }: TableProps) {
  const { phoneTypes, redirectCreate, redirectEdit, redirectDelete } = useUsersContext();
  const { getMany: getPeople } = usePersonService();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: page,
    limit: limit,
    hasPreviousPage: false,
    hasNextPage: false,
    previousPage: null,
    nextPage: null,
    startIndex: 0,
    numPages: 0,
    recordsTotal: 0,
  });
  const [people, setPeople] = useState<Array<any>>([]);
  const [sortColumn, setSortColumn] = useState<any>(null);
  const [sortType, setSortType] = useState<any>('asc');

  const loading = useMemo(() => {
    if (!people.length || !phoneTypes) {
      return true;
    }
    return false;
  }, [people, phoneTypes]);

  const onRowClickEdit = useCallback(
    (row) => redirectEdit(row.id),
    [redirectEdit],
  );

  useEffect(() => {
    const sortPeople = (people: any) => {
      if (sortColumn && sortType) {
        return people.sort((a: any, b: any) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt(0);
          }
          if (typeof y === 'string') {
            y = y.charCodeAt(0);
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }
      return people;
    };

    getPeople({
      limit,
      page,
      fn: (response: IServerResponse<{ data: Array<any>, pagination: IPagination }>) => {
        setPagination(response.pagination);
        setPeople(sortPeople(response.data));
      },
    });
  }, [getPeople, limit, sortColumn, sortType, page]);

  const handleSortColumn = (sortColumn:any, sortType:any) => {
    setSortColumn(sortColumn);
    setSortType(sortType || "asc");
  }

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="text-truncate" xxl={10} xs={10} md={6}>
          <h1>{t("config.users")}</h1>
        </Col>
        <Col className="text-truncate" xxl={2} xs={2} md={6}>
          <Button
            variant="primary"
            onClick={redirectCreate}
            className="text-nowrap g-0 m-0 p-2"
            children={t("label.action.create", { ns: "application.misc" })}
          />
        </Col>
      </Row>
      <RTable
        onRowClick={onRowClickEdit}
        title={t("config.users")}
        loading={loading}
        data={people}
        showHeader
        autoHeight
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <RTable.Column align="center" fixed>
          <RTable.HeaderCell>{capitalize(t("users.id")).trim()}</RTable.HeaderCell>
          <RTable.Cell dataKey="id" />
        </RTable.Column>

        <RTable.Column width={250} sortable>
          <RTable.HeaderCell>{capitalize(t("users.salutation")).trim()}</RTable.HeaderCell>
          <RTable.Cell dataKey="salutation" />
        </RTable.Column>

        <RTable.Column width={250} sortable>
          <RTable.HeaderCell>{capitalize(t("users.first_name")).trim()}</RTable.HeaderCell>
          <RTable.Cell dataKey="first_name" />
        </RTable.Column>

        <RTable.Column width={250} sortable>
          <RTable.HeaderCell>{capitalize(t("users.last_name")).trim()}</RTable.HeaderCell>
          <RTable.Cell dataKey="last_name" />
        </RTable.Column>

        <RTable.Column width={250} sortable>
          <RTable.HeaderCell>{capitalize(t("users.title")).trim()}</RTable.HeaderCell>
          <RTable.Cell dataKey="title" />
        </RTable.Column>

        <RTable.Column width={250} sortable>
          <RTable.HeaderCell>{capitalize(t("users.description")).trim()}</RTable.HeaderCell>
          <RTable.Cell dataKey="description" />
        </RTable.Column>

        <RTable.Column>
          <RTable.HeaderCell>
            {capitalize(t("label.action.delete", { ns: "application.misc" })).concat('?').trim()}
          </RTable.HeaderCell>
          <RTable.Cell className="d-flex justify-content-center align-items-center">
            {(rowData) => {
              return (
                <button
                  className="btn btn-danger"
                  onClick={event => redirectDelete(rowData.id, event)}
                >
                  {capitalize(t("label.action.delete", { ns: "application.misc" })).trim()}
                </button>
              );
            }}
          </RTable.Cell>
        </RTable.Column>
      </RTable>
      <Pagination
        last
        first
        ellipsis
        limit={limit}
        className="p-3"
        maxButtons={10}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
        limitOptions={[10, 30, 50]}
        next={pagination.hasNextPage}
        prev={pagination.hasPreviousPage}
        total={pagination.recordsTotal}
        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
        locale={{
          total: t("label.table.total_records", { ns: "application.misc", total: "{0}" }),
          limit: t("label.table.limit_to", { ns: "application.misc", limit: "{0}" }),
          skip: t("label.table.skip", { ns: "application.misc", page: "{0}" }),
          first: t("label.table.first", { ns: "application.misc" }),
          prev: t("label.table.prev", { ns: "application.misc" }),
          next: t("label.table.next", { ns: "application.misc" }),
          last: t("label.table.last", { ns: "application.misc" }),
          more: t("label.table.more", { ns: "application.misc" }),
        }}
      />
    </Container>
  );
}

export default withTranslation()(Table);