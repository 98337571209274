import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import Select, { Options, SingleValue } from "react-select";

import { Field } from "../../../interfaces";

const SortData = (props: any) => {
  const { entity } = props;
  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  const { t } = useTranslation();
  const [options, setOptions] = useState<
    Options<{
      value: string;
      label: string;
    }>
  >([]);

  const [selected, setSelected] = useState<{
    field: SingleValue<{ value: string; label: string }>;
    direction: SingleValue<{ value: string; label: string }>;
  }>({
    field: { value: "", label: "" },
    direction: { value: "", label: "" },
  });

  const sortOptions: Options<{ value: string; label: string }> = useMemo(
    () => [
      {
        value: "asc",
        label: t("label.sort_method.asc", { ns: "application.misc" }),
      },
      {
        value: "desc",
        label: t("label.sort_method.desc", { ns: "application.misc" }),
      },
    ],
    [t]
  );

  const [sortMethodValue, setSortMethodValue] = useState<
    Options<{
      value: string;
      label: string;
    }>
  >([]);

  useEffect(() => {
    let options: Options<{
      value: string;
      label: string;
    }> = [];
    props.fields.data.forEach((item: Field) => {
      let option: Options<{
        value: string;
        label: string;
      }> = [
        {
          value: `data_${item.name}`,
          label: tWithEntityNamespace("fields.".concat(item.name)),
        },
      ];
      options = [...options, ...option];
    });
    setOptions(options);

    if (props.selected instanceof Array) {
      if (props.selected.length > 0) {
        const [selection] = props.selected;
        setSelected({
          field: options.find(
            (item: any) => item.value === selection.field
          ) || { value: "", label: "" },
          direction: sortOptions.find(
            (item: any) => item.value === selection.direction
          ) || { value: "", label: "" },
        });
      }
    }
  }, [
    props.fields.data,
    props.selected,
    t,
    sortOptions,
    props.entity,
    tWithEntityNamespace,
  ]);

  const handleSort = (
    selectType: string,
    e: SingleValue<{ value: string; label: string }>
  ): void => {
    if (!e) {
      props.setSortData([]);
      setSelected((prev) => {
        return {
          ...prev,
          [selectType]: { value: "", label: "" },
          direction: { value: "", label: "" },
        };
      });
      setSortMethodValue([]);
      return;
    }

    switch (selectType) {
      case "field":
        props.setSortData([
          {
            field: e?.value || "",
            direction: selected.direction?.value || "asc",
          },
        ]);
        break;
      case "direction":
        props.setSortData([
          {
            field: selected.field?.value || "",
            direction: e?.value || "asc",
          },
        ]);
        const sortOption = sortOptions.filter(
          (option) => String(option.value) === e.value
        );
        setSortMethodValue(sortOption);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Select
        isClearable
        options={options}
        placeholder={t("entity_table.sort_by", { sort: "" })}
        className="w-100 g-0 mx-0 mx-md-1 my-1 my-md-0 p-0"
        onChange={handleSort.bind(this, "field")}
      />
      <Select
        onChange={handleSort.bind(this, "direction")}
        isDisabled={Boolean(selected?.field?.value === "")}
        placeholder={t("label.sort_method.description", {
          ns: "application.misc",
        })}
        className="w-100 g-0 mx-0 mx-md-1 my-1 my-md-0 p-0"
        options={sortOptions}
        isClearable
        value={sortMethodValue}
      />
    </React.Fragment>
  );
};

export default SortData;
