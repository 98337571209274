import { useCallback, useEffect, useState } from "react";

import { TFunction } from "i18next";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useMenuContext } from "./Menu";
import RTable from "rsuite/Table";
import { capitalize } from "lodash";
import { RowDataType, SortType } from "rsuite-table";
import useMenuService from "src/services/menu.service";
import { IPagination, IServerResponse } from "src/interfaces";

interface TableProps {
  t: TFunction;
}

interface IMenu {
  id: number;
  category_id: number;
  category_menu_name: string;
  menu_name: string;
  entity: string;
  visible: boolean;
  active: boolean;
}

interface IMenuFormated {
  category_id: number;
  category_menu_name: string;
  children: Array<{
    id: number;
    menu_name: string;
    entity: string;
    visible: boolean;
    active: boolean;
  }>;
}

const Table = ({ t }: TableProps) => {
  const { redirectCreate, redirectEdit } = useMenuContext();

  const { editActiveMenu: editMenuById, getManyWithoutPaginate: getAllMenu } =
    useMenuService();

  const [menu, setMenu] = useState<Array<IMenuFormated>>([]);
  const [data, setData] = useState<Array<IMenu>>([]);
  const [sortColumn, setSortColumn] = useState<any>(null);
  const [sortType, setSortType] = useState<SortType>("asc");
  const [loading, setLoading] = useState<boolean>(true);
  const [listOfUsedCategories, setListOfUsedCategories] = useState<
    Array<number> | undefined
  >([]);

  const validateInnterText = useCallback(
    (innerText: string) => {
      return innerText ===
        capitalize(
          t("label.action.inactive", {
            ns: "application.misc",
          }) || ""
        ).trim() ||
        innerText ===
          capitalize(
            t("label.action.active", { ns: "application.misc" }) || ""
          ).trim()
        ? false
        : true;
    },
    [t]
  );

  const onRowClickEdit = useCallback(
    (row: RowDataType, e: any) => {
      if (
        !Array.isArray(row.children) &&
        validateInnterText(e?.target?.innerText)
      )
        redirectEdit(row.id, listOfUsedCategories, null);
    },
    [redirectEdit, validateInnterText, listOfUsedCategories]
  );

  const handleSortColumn = (sortColumn: any, sortType: any): void => {
    setSortColumn(sortColumn);
    setSortType(sortType || "asc");
  };

  const formatedMenu = useCallback((data: Array<IMenu>) => {
    const newData: Array<IMenuFormated> = [];
    const categoriesAdded: number[] = [];
    for (const row of data) {
      if (categoriesAdded.find((x) => x === row.category_id)) {
        const index = newData.findIndex(
          (x) => x.category_id === row.category_id
        );
        newData[index].children.push({
          id: row.id,
          menu_name: row.menu_name,
          entity: row.entity,
          visible: row.visible,
          active: row.active,
        });
      } else {
        categoriesAdded.push(row.category_id);
        newData.push({
          category_id: row.category_id,
          category_menu_name: row.category_menu_name,
          children: [
            {
              id: row.id,
              menu_name: row.menu_name,
              entity: row.entity,
              visible: row.visible,
              active: row.active,
            },
          ],
        });
      }
    }
    return newData;
  }, []);

  const onRowActivateClick = useCallback(
    (row: IMenu) => {
      const { id } = row;
      const oldRow = data.findIndex((r) => r.id === row.id);
      const newRow: IMenu = { ...data[oldRow], active: !row.active };
      editMenuById({ id, data: { data: newRow } })
        .then((response) => {
          setData((prevState: Array<IMenu>) => {
            const newData = [...prevState];
            newData[oldRow] = newRow;
            return newData;
          });
        })
        .catch((error) => console.error(error));
    },
    [editMenuById, data]
  );

  useEffect(() => {
    getAllMenu({
      fn: (
        response: IServerResponse<{
          data: Array<IMenu>;
          pagination: IPagination;
        }>
      ) => {
        const res = response.data as any[] as IMenu[];
        setData(res);
        setLoading(false);
      },
    });
  }, [getAllMenu, formatedMenu]);

  useEffect(() => {
    if (data) setMenu(formatedMenu(data));
  }, [data, formatedMenu]);

  useEffect(() => {
    if (!!menu.length) {
      const categoriesId = menu.map((v) => v.category_id);
      const uniqueEntities = categoriesId.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setListOfUsedCategories(uniqueEntities);
    }
  }, [menu]);

  return (
    <Container fluid>
      <Row>
        <Col className="text-truncate" xxl={10} xs={10} md={6}>
          <h1>{t("config.menu")}</h1>
        </Col>
        <Col className="text-truncate" xxl={2} xs={2} md={6}>
          <Button
            variant="primary"
            onClick={(e) => redirectCreate(listOfUsedCategories, e)}
            className="text-nowrap g-0 m-0 p-2"
            children={t("label.action.create", { ns: "application.misc" })}
          />
        </Col>
      </Row>
      <RTable
        onRowClick={onRowClickEdit}
        title={t("config.menu") || ""}
        loading={loading}
        isTree
        defaultExpandAllRows
        data={menu}
        rowKey={"category_menu_name"}
        showHeader
        autoHeight
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(t("menu.categories") || "").trim()}
          </RTable.HeaderCell>
          <RTable.Cell dataKey="category_menu_name" />
        </RTable.Column>
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(t("menu.name_menu") || "").trim()}
          </RTable.HeaderCell>
          <RTable.Cell dataKey="menu_name" />
        </RTable.Column>
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(t("menu.entities") || "").trim()}
          </RTable.HeaderCell>
          <RTable.Cell dataKey="entity" />
        </RTable.Column>
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(t("menu.visible") || "").trim()}
          </RTable.HeaderCell>
          <RTable.Cell>
            {(rowData) => {
              const isParent =
                Array.isArray(rowData.children) && rowData.children.length > 0;
              return !isParent ? (
                <Form.Check // prettier-ignore
                  type="checkbox"
                  defaultChecked={rowData.visible}
                />
              ) : (
                <></>
              );
            }}
          </RTable.Cell>
        </RTable.Column>
        <RTable.Column flexGrow={1}>
          <RTable.HeaderCell>
            {capitalize(
              t("label.action.active_inactive", { ns: "application.misc" }) ||
                ""
            )
              .concat("?")
              .trim()}
          </RTable.HeaderCell>
          <RTable.Cell className="d-flex justify-content-center align-items-center">
            {(rowData) => {
              const isParent =
                Array.isArray(rowData.children) && rowData.children.length > 0;
              if (!isParent)
                return rowData.active ? (
                  <button
                    className="btn btn-danger"
                    onClick={(event) => onRowActivateClick(rowData as IMenu)}
                  >
                    {capitalize(
                      t("label.action.inactive", {
                        ns: "application.misc",
                      }) || ""
                    ).trim()}
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={(event) => onRowActivateClick(rowData as IMenu)}
                  >
                    {capitalize(
                      t("label.action.active", { ns: "application.misc" }) || ""
                    ).trim()}
                  </button>
                );
            }}
          </RTable.Cell>
        </RTable.Column>
      </RTable>
    </Container>
  );
};

export default withTranslation()(Table);
