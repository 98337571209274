import Modal from "react-bootstrap/Modal";
import { ModalProps } from "react-bootstrap/Modal";

interface ModalFormProps extends ModalProps {
  handleClose: () => void;
  onOpen?: () => void;
}

const ModalForm = ({handleClose, onOpen, ...props}: ModalFormProps) => {
  return (
    <Modal size={props.size||'xl'} show={props.show} onHide={handleClose} onShow={()=>{
      if(onOpen && typeof onOpen==='function'){
        onOpen()
      }
    }}>
      <Modal.Header closeButton>
        <Modal.Title>
          {
            props.header
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.children
        }
      </Modal.Body>
      {
        props.footer
        && (
          <Modal.Footer>
            {
              props.footer
            }
          </Modal.Footer>
        )
      }
    </Modal>
  );
};

export default ModalForm;
