import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useCallback } from "react";

// ? --- React Redux Selectors ---
import { useAppSelector } from "../app/hooks";
import { selectDomain } from "../reducers/domain/domainSlice";
import { getToken } from "../reducers/auth";

const apiUsersUrl = `${environment.apiUrl}/usersData`;
const usersUrl = `${environment.apiUrl}/users`;

export default function useUserDataService() {
  const currentDomain = useAppSelector(selectDomain);
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const getAll = useCallback(
    async ({ fn, limit = 10, page = 1 }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiUsersUrl}?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const edit = useCallback(
    async ({ fn, id, data }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiUsersUrl}/${id}`,
            method: "PATCH",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const create = useCallback(
    async ({ fn, data }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiUsersUrl}`,
            method: "POST",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const deleteUser = useCallback(
    async ({ fn, id, data }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiUsersUrl}/${id}`,
            method: "DELETE",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getDomains = useCallback(
    function <IType = any>({ id = null, fn: callback = () => { } }: any): Promise<any> {
      return new Promise((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          }

          sendRequest(
            {
              url: `${apiUsersUrl}/${id}/domains`,
              method: "GET",
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                domain: currentDomain.id,
              },
            },
            finish,
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const toggleDomain = useCallback(
    async ({ fn, userId, domainId }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiUsersUrl}/${userId}/domains/${domainId}`,
            method: "PATCH",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  /** Get the user profile data */
  const userProfile = useCallback(
    async ({ fn, userId }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${usersUrl}/user-profile`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
            },
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  /* Update password request */
  const updatePassword = useCallback(
    async ({ fn, data }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${usersUrl}/change-password`,
            method: "PUT",
            body: data,
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
            },
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  return {
    getAll,
    edit,
    create,
    deleteUser,
    getDomains,
    toggleDomain,
    userProfile,
    updatePassword,
  };
}
