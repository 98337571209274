import { useState } from "react";

const useLocalStorage = function <T = any>(
  keyName: string = "",
  defaultValue: any = {}
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue as T;
      }
    } catch (err) {
      return defaultValue as T;
    }
  });

  const storeValue = function <T>(value: any): T {
    const valueToStore =
      true &&
      typeof value === "object" &&
      Object.keys(value).includes("key") &&
      Object.keys(value).includes("initialValue")
        ? JSON.stringify(value.initialValue ?? "null")
        : JSON.stringify(value ?? "null");
    try {
      window.localStorage.setItem(keyName, valueToStore);
    } catch (err) {
      console.error(err);
    } finally {
      setStoredValue(JSON.parse(valueToStore));
      return JSON.parse(valueToStore) as T;
    }
  };

  return {
    storedValue,
    storeValue,
  };
};

export default useLocalStorage;
