import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useCallback } from "react";

// ? --- React Redux Selectors ---
import { useAppSelector } from "../app/hooks";
import { getToken } from "../reducers/auth";

export default function useUserService() {
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  return {
    getAllActiveUsers: useCallback(
      function <IType>({ page = 1, limit = 10, filter = "" }): Promise<IType> {
        return new Promise((resolve, reject) => {
          if (credentials && credentials.logged) {
            sendRequest(
              {
                url: `${environment.apiUrl}/users?page=${page}&limit=${limit}`,
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                },
              },
              resolve
            );
          }
        });
      },
      [credentials, sendRequest]
    ),
    getUsersByEmail: useCallback(
      function <IType>(email: string): Promise<IType> {
        return new Promise((resolve, reject) => {
          if (credentials && credentials.logged) {
            sendRequest(
              {
                url: `${environment.apiUrl}/usersdata?email=${email}`,
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                },
              },
              resolve
            );
          }
        });
      },
      [credentials, sendRequest]
    ),
    getUsersByUsername: useCallback(
      function <IType>(username: string): Promise<IType> {
        return new Promise((resolve, reject) => {
          if (credentials && credentials.logged) {
            sendRequest(
              {
                url: `${environment.apiUrl}/usersdata?username=${username}`,
                method: "GET",
                headers: {
                  Authorization: `${credentials.token_type} ${credentials.access_token}`,
                },
              },
              resolve
            );
          }
        });
      },
      [credentials, sendRequest]
    ),
  };
}
