import React from 'react'
import { FadeLoader } from 'react-spinners'

const SuspenseLoader = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>
        <FadeLoader
            size={200}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </div>
  )
}

export default SuspenseLoader