import { FormEvent, useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Form, Stack } from "react-bootstrap";
import useDomainsService from "src/services/domains.service";
import MoveDomainModal from "./MoveDomainModal";
import ParentDomainModal from "./ParentDomainModal";
import { DomainFormStateType, DomainItemType, DomainSelected } from "./types";
import styles from "./DomainForm.module.css";
import { withTranslation, TFunction } from "react-i18next";

interface DomainFormProps {
    domain?: DomainSelected | DomainItemType;
    handleDomain: Function;
    cancelForm: Function;
    t: TFunction;
}

const initialState = {
    currentDomain: {
        id: 0,
        name: '',
        description: '',
        parent_id: 0,
        subDomains: [],
    },
    validated: false,
    parentDomains: [],
    showOpenDomainMoveModal: false,
    showOpenParentDomainModal: false,
    domainSelected: {},
};

const DomainForm = ({ domain, handleDomain, cancelForm, t }: DomainFormProps) => {
    const [
        {
            currentDomain,
            validated,
            parentDomains,
            showOpenDomainMoveModal,
            showOpenParentDomainModal,
            domainSelected,
        },
        setState
    ] = useState<DomainFormStateType>(initialState);
    const { getMany } = useDomainsService();

    const onCancelForm = () => {
        setState({...initialState});
        cancelForm();
    };

    const onDomainNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            currentDomain: {
                ...prevState.currentDomain,
                name: e.target.value,
            }
        }));
    };

    const onDomainDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            currentDomain: {
                ...prevState.currentDomain,
                description: e.target.value
            }
        }));
    };

    const saveDomainDetails = useCallback((event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        setState(prevState => ({
            ...prevState,
            validated: form.checkValidity(),
        }));
        handleDomain(currentDomain);
    }, [currentDomain, handleDomain]);

    useEffect(() => {
        if (domain) {
            setState(prevState => ({
                ...prevState,
                currentDomain: domain
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                currentDomain: initialState.currentDomain,
            }));
        }
    }, [domain, handleDomain, setState]);

    useEffect(() => {
        if (domain) {
            getMany({
                fn: (resp: any) => {
                    const validDomains = resp.data.filter((d: DomainItemType) => d.id !== domain!.id);
                    setState(prevState => ({
                        ...prevState,
                        parentDomains: validDomains
                    }));
                }
            });
        }
    }, [getMany, domain]);

    const handleShowMoveDomainModal = (domain?: DomainItemType) => {
        setState(prevState => ({
            ...prevState,
            showOpenDomainMoveModal: true,
            domainSelected: domain ?? {},
        }));
    };

    const handleShowParentDomainModal = (domain?: DomainItemType | DomainSelected) => {
        setState(prevState => ({
            ...prevState,
            showOpenParentDomainModal: true,
            domainSelected: domain ?? {},
        }));
    };

    const handleDomainMoveModalConfirm = (parentDomain: DomainItemType) => {
        setState(prevState => ({...prevState, showOpenDomainMoveModal: false}));
        handleDomain({
            ...domain,
            parent_id: parentDomain.id,
        });
    };

    const handleParentDomainModalConfirm = (domain: DomainItemType) => {
        setState(prevState => ({...prevState, showOpenParentDomainModal: false}));
        handleDomain({
            ...domain,
            parent_id: 0,
        });
    };

    return (
        <Form onSubmit={saveDomainDetails} validated={validated}>
            <Form.Group className="mb-3" controlId="domainName">
                <Form.Label>{t("domains.domain_details.domain_name")}</Form.Label>
                <Form.Control
                    type="text"
                    required
                    placeholder={t("domains.domain_details.domain_name_placeholder")}
                    value={currentDomain?.name ?? ''}
                    name="domainName"
                    onChange={onDomainNameChange}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="domainDescription">
                <Form.Label>{t("domains.domain_details.domain_description")}</Form.Label>
                <Form.Control
                    type="textarea"
                    required
                    placeholder={t("domains.domain_details.domain_description_placeholder")}
                    value={currentDomain?.description ?? ''}
                    name="domainDescription"
                    onChange={onDomainDescriptionChange}
                />
            </Form.Group>
            <Form.Group className="d-flex justify-content-end">
                <Stack direction="horizontal" gap={3}>
                    <Button variant="tertiary" className="mr-2" onClick={() => onCancelForm()}>{t("label.action.cancel", { ns: "application.misc" })}</Button>
                    { domain ? (
                        <>
                            {currentDomain?.parent_id !== 0 && (
                                <Button
                                    variant="secondary"
                                    onClick={() => handleShowParentDomainModal(currentDomain)}
                                >
                                    {t("domains.action.set_as_root_domain")}
                                </Button>
                            )}
                            {
                                parentDomains?.length > 0 && (
                                    <Dropdown align="end" autoClose>
                                        <Dropdown.Toggle>{t("domains.action.move_to")}</Dropdown.Toggle>
                                        <Dropdown.Menu bsPrefix={`${styles.dropdown_menu} dropdown-menu`}>
                                            {
                                                parentDomains.filter(pd => Number(pd.id) !== Number(domain.id) && Number(domain.parent_id) !== Number(pd.id)).map((domain) => (
                                                    <Dropdown.Item
                                                        key={domain.id}
                                                        onClick={() => handleShowMoveDomainModal(domain)}
                                                    >
                                                        {domain.name}
                                                    </Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            }
                        </>
                    ) : null}
                    <Button type="submit">{t("label.action.save", { ns: "application.misc" })}</Button>
                </Stack>
            </Form.Group>
            <MoveDomainModal
                handleConfirm={handleDomainMoveModalConfirm}
                handleClose={() => setState(prevState => ({...prevState, showOpenDomainMoveModal: false}))}
                showModal={showOpenDomainMoveModal}
                domainSelected={domainSelected}
            />
            <ParentDomainModal
                handleConfirm={handleParentDomainModalConfirm}
                handleClose={() => setState(prevState => ({...prevState, showOpenParentDomainModal: false}))}
                showModal={showOpenParentDomainModal}
                domainSelected={domainSelected}
            />
        </Form>
    );
};

export default withTranslation()(DomainForm);
