import { Button } from "react-bootstrap";
import ModalForm from "src/components/UI/Modal/ModalForm/ModalForm";
import { DomainSelected } from "./types";
import { withTranslation, TFunction } from "react-i18next";

interface MoveToParentDomainProps {
  handleClose: Function;
  handleConfirm: Function;
  showModal: boolean;
  domainSelected?: DomainSelected;
  t: TFunction;
}

const MoveDomainModal = ({ handleClose, handleConfirm, showModal = false, domainSelected, t }: MoveToParentDomainProps) => {
  const modalFooter = () => (
    <>
      <Button variant="ternary" onClick={() => cancelButton()}>{t("label.action.cancel", { ns: "application.misc" })}</Button>
      <Button variant="primary" onClick={() => confirmButton()}>{t("label.action.confirm", { ns: "application.misc" })}</Button>
    </>
  );

  const cancelButton = () => {
    handleClose();
  };

  const confirmButton = () => {
    handleConfirm(domainSelected);
  };

  const domainSelectedName = domainSelected?.name;

  return (
    <ModalForm size={"lg"} show={showModal} header={t("domains.move_domain_modal.modal_header")} footer={modalFooter()} handleClose={cancelButton}>
      <p>{t("domains.move_domain_modal.modal_body", {domainSelectedName})}</p>
    </ModalForm>
  );
};

export default withTranslation()(MoveDomainModal);
