import { useEffect, useCallback } from "react";

import { IAuthStorage } from "../interfaces";
import useLocalStorage from "./useLocalStorage";
import { useAppSelector } from "../app/hooks";
import { getToken } from "../reducers/auth";
import useHttpHandler from "./http-handler";
import environment from "./../environment";

export const useAuth = () => {
  const { storedValue: user, storeValue: setUser } =
    useLocalStorage<IAuthStorage>("da.authorization.user", {
      id: 0,
      email: "",
      login: false,
      active: false,
      isAdmin: false,
      username: "",
      contact_id: 0,
      created_at: "",
      updated_at: "",
      domains: [],
      loginInfo: {
        message: "",
        remoteAddr: "",
        userAgent: "",
      },
      roles: {
        names: [],
        permissions: {},
        roles: {},
      },
      role_id: 0,
    });

  const credentials = useAppSelector(getToken);
  const { sendRequest, isLoading } = useHttpHandler();

  const handleUserData = useCallback(
    (response: { data: IAuthStorage }) => {
      if (response.data) {
        setUser(response.data);
      }
    },
    [setUser]
  );

  const Login = useCallback(() => {
    setUser({
      id: 0,
      email: "",
      login: false,
      active: false,
      isAdmin: false,
      username: "",
      contact_id: 0,
      created_at: "",
      updated_at: "",
      domains: [],
      loginInfo: {
        message: "",
        remoteAddr: "",
        userAgent: "",
      },
      roles: {
        names: [],
        permissions: {},
        roles: {},
      },
    });

    if (credentials && credentials.logged) {
      const { access_token, token_type } = credentials;

      sendRequest(
        {
          url: `${environment.apiUrl}/users/me`,
          method: "GET",
          headers: {
            Authorization: token_type.concat(" ", access_token),
          },
        },
        handleUserData
      );
    }
  }, [sendRequest, credentials]);

  useEffect(() => Login(), [Login]);

  return {
    user,
    credentials,
    isLoading,
    Login,
  };
};
