import { useCallback } from "react";
import { useAppSelector } from "src/app/hooks";
import environment from "src/environment";
import HttpHandler from "src/hook/http-handler";
import { getToken } from "src/reducers/auth";
import { selectDomain } from "src/reducers/domain/domainSlice";

interface IGetAllParams {
  fn?: Function;
  limit?: number;
  page?: number;
  filter?: string;
  data?: Object;
  id?: number | string;
}

const MenuAPI = `${environment.apiUrl}/menu`;

const useMenuService = () => {
  const currentDomain = useAppSelector(selectDomain);
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const getMany = useCallback(
    async ({ fn, page = 1, limit = 10 }: IGetAllParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${MenuAPI}?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getManyWithoutPaginate = useCallback(
    async ({ fn }: IGetAllParams) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${MenuAPI}/getAll`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getMenuById = useCallback(
    function <IType = any>({
      id = -1,
      fn: callback = () => {},
    }: IGetAllParams): Promise<any> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          };

          sendRequest(
            {
              url: `${MenuAPI}/${id}/menu`,
              method: "GET",
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                domain: currentDomain.id,
              },
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const create = useCallback(
    function <IType = any>({
      data,
      fn: callback = () => {},
    }: IGetAllParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          };

          sendRequest(
            {
              url: MenuAPI,
              method: "POST",
              body: data,
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                domain: currentDomain.id,
              },
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain, sendRequest]
  );

  const edit = useCallback(
    function <IType = any>({
      id,
      data,
      fn: callback = () => {},
    }: IGetAllParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          };

          sendRequest(
            {
              url: `${MenuAPI}/${id}/menu`,
              method: "PUT",
              body: data,
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                domain: currentDomain.id,
              },
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const editActiveMenu = useCallback(
    function <IType = any>({
      id,
      data,
      fn: callback = () => {},
    }: IGetAllParams): Promise<IType> {
      return new Promise<IType>((resolve, reject) => {
        if (credentials && credentials.logged) {
          const finish = function (response: IType | PromiseLike<IType>) {
            if (callback) {
              callback(response);
            }
            resolve(response);
          };

          sendRequest(
            {
              url: `${MenuAPI}/${id}/active`,
              method: "PUT",
              body: data,
              headers: {
                Authorization: `${credentials.token_type} ${credentials.access_token}`,
                domain: currentDomain.id,
              },
            },
            finish
          );
        }
      });
    },
    [credentials, currentDomain.id, sendRequest]
  );

  return {
    getMany,
    getMenuById,
    create,
    edit,
    getManyWithoutPaginate,
    editActiveMenu,
  };
};

export default useMenuService;
