import React, { useState, useEffect, FC, useLayoutEffect } from "react";

import Form from "react-bootstrap/Form";

import { useTranslation } from "react-i18next";
import { useEntityTable } from "../EntityTable/EntityTable";
import { Entity } from "src/interfaces";

interface FilterProps {
  handleSearchValue: (e: any) => void;
  handleOperator: (e: any) => void;
  handleKey: (e: any) => void;
  searchValueData: string;
  operatorData: string;
  keyData: string;
}

const Filter: FC<FilterProps> = ({
  handleSearchValue,
  handleOperator,
  handleKey,
  searchValueData,
  operatorData,
  keyData,
}) => {
  const { t } = useTranslation();
  const { rows, fields, entity } = useEntityTable();

  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  const [key, setKey] = useState("");
  const [operator, setOperator] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (keyData === "" && operatorData === "" && searchValueData === "") {
      setKey("");
      setOperator("");
      setSearchValue("");
    } else if (keyData !== key) {
      setKey(keyData);
    } else if (operatorData !== operator) {
      setOperator(operatorData);
    } else if (searchValueData !== searchValue) {
      setSearchValue(searchValueData);
    }
  }, [keyData, operatorData, searchValueData, key, operator, searchValue]);

  const setFilterFieldsList = () => (
    <Form.Group
      controlId="filter"
      className="filter-form-group"
      style={{
        margin: 0,
        marginBottom: ".5rem",
      }}
    >
      <Form.Select
        aria-label={t("entity_table.select_field")}
        onChange={(e) => handleKey(e.target.value)}
        disabled={!rows.length}
        value={key}
      >
        <option>{t("entity_table.select_field")}</option>

        {fields.map((item: any, index: any) => {
          return (
            <option
              children={tWithEntityNamespace("fields.".concat(item.name))}
              value={`data_${item.name}`}
              data-key={`${index}`}
              key={`${index}`}
            />
          );
        })}
      </Form.Select>
    </Form.Group>
  );

  const setOperatorList = () => {
    const stringOperators = [
      {
        value: "eq",
        operator: t("label.operator.eq", { ns: "application.misc" }),
      },
      {
        value: "ne",
        operator: t("label.operator.ne", { ns: "application.misc" }),
      },
      {
        value: "lk",
        operator: t("label.operator.lk", { ns: "application.misc" }),
      },
    ];

    const numericOperators = [
      {
        value: "lt",
        operator: t("label.operator.lt", { ns: "application.misc" }),
      },
      {
        value: "gt",
        operator: t("label.operator.gt", { ns: "application.misc" }),
      },
      {
        value: "eq",
        operator: t("label.operator.eq", { ns: "application.misc" }),
      },
      {
        value: "ne",
        operator: t("label.operator.ne", { ns: "application.misc" }),
      },
      {
        value: "lk",
        operator: t("label.operator.lk", { ns: "application.misc" }),
      },
    ];

    let operators: any[] = [];

    if (rows && fields) {
      const keyVariable = key.replace(/^data_/, "");
      let selectedValueType = "";
      for (const row of fields) {
        if (keyVariable === row.name) {
          selectedValueType = row.type;
        }
      }

      switch (selectedValueType) {
        case "number":
        case "date":
          operators = numericOperators;
          break;
        case "integer":
          operators = numericOperators.filter(
            (operator) => operator.value !== "lk"
          );
          break;
        default:
          operators = stringOperators;
          break;
      }
    }

    return (
      <Form.Group
        controlId="operator"
        className="filter-form-group"
        style={{
          margin: 0,
          marginBottom: ".5rem",
        }}
      >
        <Form.Select
          aria-label={t("entity_table.select_operator")}
          onChange={(e) => handleOperator(e.target.value)}
          disabled={!rows.length}
          value={operator}
        >
          <option>{t("entity_table.select_operator")}</option>
          {operators.map((item: any, index: any) => {
            return (
              <option value={item.value} key={index}>
                {item.operator}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
  };

  const setSearchValueInputType = () => {
    if (rows && fields) {
      const keyVariable = key.substring(5);
      let selectedValueType = "";
      for (const prop in rows[0]) {
        if (keyVariable === prop) {
          const data: Entity.keyPrefix<"data_", string> = `data_${prop.replace(
            /^data_/,
            ""
          )}`;
          selectedValueType = `${rows[0][data]}`;
        }
      }
      if (Date.parse(selectedValueType) && isNaN(selectedValueType as any)) {
        return (
          <Form.Group controlId="duedate" className="filter-form-group">
            <Form.Control
              type="date"
              name="duedate"
              value={searchValue}
              onChange={(e) => handleSearchValue(e.target.value)}
              placeholder="YYYY-MM-DD"
              required
              pattern="(?:19|20)\[0-9\]{2}-(?:(?:0\[1-9\]|1\[0-2\])/(?:0\[1-9\]|1\[0-9\]|2\[0-9\])|(?:(?!02)(?:0\[1-9\]|1\[0-2\])/(?:30))|(?:(?:0\[13578\]|1\[02\])-31))"
              disabled={!rows.length}
            />
          </Form.Group>
        );
      } else {
        return (
          <Form.Group
            controlId="value"
            className="filter-form-group"
            style={{
              margin: 0,
              marginBottom: ".5rem",
            }}
          >
            <Form.Control
              type="text"
              value={searchValue}
              disabled={!rows.length}
              placeholder={t("entity_table.enter_value")}
              onChange={(e) => handleSearchValue(e.target.value)}
            />
          </Form.Group>
        );
      }
    }
  };

  return (
    <React.Fragment>
      {
        // ? Render the fields list, dynamically relative to the entity.
        setFilterFieldsList()
      }
      {
        // ? Render operator types, dynamically relative to field.
        setOperatorList()
      }
      {
        // ? Render input type of value selection, dynamically relative to field.
        setSearchValueInputType()
      }
    </React.Fragment>
  );
};

export default Filter;
