import React, { useState } from "react";
import styles from "./MoreButton.module.css";

export default function MoreButton(props:{onClick:any}) {
  const [isRotated, setIsRotated] = useState<boolean>(false);
  return (
    <div
      onClick={() => {
        props.onClick()
        setIsRotated((previousValue:boolean)=>!previousValue);
      }}
      className={styles["icon-button"] + " " + (isRotated && styles.rotate)}
    ></div>
  );
}
