import { useState, useEffect, useReducer, useRef } from "react";
import Button from "react-bootstrap/Button";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";

import Alert from "./Domains/Alert";
import Phone from "./Phone";
import ProfileButton from "./Button";
import usePersonService from "../../../../services/person.service";
import Users from "./User/Users";
import useUserService from "../../../../services/user.service";
import { UserRepository } from "../../../../models/Admin/User";
import ConfirmReactivateEmail from "./Modals/ConfirmReactivateEmail";

import styles from "./Profile.module.css";

type userFormChanged = {
  id: number;
  formValue: UserRepository;
};

function Profile() {
  const location: any = useLocation();
  const navigate = useNavigate();
  const [[action]] = [location.pathname.split("/").slice(-1)];
  const { t } = useTranslation();
  const {
    create: createPerson,
    edit: editPerson,
    getUsersById: getUsers,
  } = usePersonService();
  const { edit: editUser, create: createUser, toggleDomain } = useUserService();

  const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
  const [transactionFinished, setTransactionFinished] =
    useState<boolean>(false);
  const [usersValid, setUsersValid] = useState(true);
  const [emailsTaken, setEmailsTaken] = useState(false);
  const [usernamesTaken, setUsernamesTaken] = useState(false);
  const [reactivateModalIsOpen, setReactivateModalIsOpen] = useState(false);
  const [usersBelongToContact] = useState<any>(null);
  function handleCloseClick() {
    setTransactionFinished(false);
  }
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const currentNewUserId = useRef(-1);

  const [users, setUsers] = useState<UserRepository[]>([]);
  const {
    id: idToEdit,
    description: description_existing_value,
    salutation: salutation_existing_value,
    first_name: first_name_existing_value,
    last_name: last_name_existing_value,
    title: title_existing_value,
    photo: photo_existing_value,
    department: department_existing_value,
    do_not_call: do_not_call_existing_value,
    primary_address_street: primary_address_street_existing_value,
    primary_address_city: primary_address_city_existing_value,
    primary_address_state: primary_address_state_existing_value,
    primary_address_postalcode: primary_address_postalcode_existing_value,
    primary_address_country: primary_address_country_existing_value,
    alt_address_street: alt_address_street_existing_value,
    alt_address_city: alt_address_city_existing_value,
    alt_address_state: alt_address_state_existing_value,
    alt_address_postalcode: alt_address_postalcode_existing_value,
    alt_address_country: alt_address_country_existing_value,
    assistant: assistant_existing_value,
    assistant_phone: assistant_phone_existing_value,
  } = location?.state?.currentData ?? {};
  const [description, set_description] = useState(
    description_existing_value ?? ""
  );

  const postalcodeMustHaveLength = 5;
  const descriptionIsValid = description.trim().length >= 2;
  const description_input_IsValid = descriptionIsValid || !formTouched;
  function handle_change_description(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_description(currentValue);
  }

  const [salutation, set_salutation] = useState(
    salutation_existing_value ?? ""
  );
  const salutationIsValid =
    salutation.trim().length >= 2 && salutation.trim().length <= 10;
  const salutation_input_IsValid = salutationIsValid || !formTouched;
  function handle_change_salutation(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_salutation(currentValue);
  }

  const [first_name, set_first_name] = useState(
    first_name_existing_value ?? ""
  );
  const first_nameIsValid = first_name.trim().length >= 2;
  const first_name_input_IsValid = first_nameIsValid || !formTouched;
  function handle_change_first_name(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_first_name(currentValue);
  }

  const [last_name, set_last_name] = useState(last_name_existing_value ?? "");
  const last_nameIsValid = last_name.trim().length >= 2;
  const last_name_input_IsValid = last_nameIsValid || !formTouched;
  function handle_change_last_name(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_last_name(currentValue);
  }

  const [title, set_title] = useState(title_existing_value ?? "");
  const titleIsValid = title.trim().length < 50 && title.trim().length >= 2;
  const title_input_IsValid = titleIsValid || !formTouched;
  function handle_change_title(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_title(currentValue);
  }

  const [photo, set_photo] = useState(photo_existing_value ?? "");
  const photoIsValid = photo.trim().length === 0;

  const [department, set_department] = useState(
    department_existing_value ?? ""
  );
  const departmentIsValid = department.trim().length === 0;

  const [do_not_call, set_do_not_call] = useState(
    do_not_call_existing_value ?? false
  );
  const do_not_callIsValid = typeof do_not_call === "boolean";
  function handle_change_do_not_call(e: any) {
    const currentValue = e.target.checked;
    setFormTouched(true);
    set_do_not_call(currentValue);
  }

  const [primary_address_street, set_primary_address_street] = useState(
    primary_address_street_existing_value ?? ""
  );
  const primary_address_streetIsValid =
    primary_address_street.trim().length < 200;
  const primary_address_street_input_IsValid =
    primary_address_streetIsValid || !formTouched;
  function handle_change_primary_address_street(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_primary_address_street(currentValue);
  }

  const [primary_address_city, set_primary_address_city] = useState(
    primary_address_city_existing_value ?? ""
  );
  const primary_address_cityIsValid = primary_address_city.trim().length < 30;
  const primary_address_city_input_IsValid =
    primary_address_cityIsValid || !formTouched;
  function handle_change_primary_address_city(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_primary_address_city(currentValue);
  }

  const [primary_address_state, set_primary_address_state] = useState(
    primary_address_state_existing_value ?? ""
  );
  const primary_address_stateIsValid = primary_address_state.trim().length < 30;
  const primary_address_state_input_IsValid =
    primary_address_stateIsValid || !formTouched;
  function handle_change_primary_address_state(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_primary_address_state(currentValue);
  }

  const [primary_address_postalcode, set_primary_address_postalcode] = useState(
    primary_address_postalcode_existing_value ?? ""
  );
  const primary_address_postalcodeIsValid =
    (primary_address_postalcode.trim().length === postalcodeMustHaveLength &&
      !isNaN(primary_address_postalcode.trim())) ||
    primary_address_postalcode.trim().length === 0;
  const primary_address_postalcode_input_IsValid =
    primary_address_postalcodeIsValid || !formTouched;
  function handle_change_primary_address_postalcode(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_primary_address_postalcode(currentValue);
  }

  const [primary_address_country, set_primary_address_country] = useState(
    primary_address_country_existing_value ?? ""
  );
  const primary_address_countryIsValid =
    primary_address_country.trim().length < 50;
  const primary_address_country_input_IsValid =
    primary_address_countryIsValid || !formTouched;
  function handle_change_primary_address_country(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_primary_address_country(currentValue);
  }

  const [alt_address_street, set_alt_address_street] = useState(
    alt_address_street_existing_value ?? ""
  );
  const alt_address_streetIsValid = alt_address_street.trim().length < 200;
  const alt_address_street_input_IsValid =
    alt_address_streetIsValid || !formTouched;
  function handle_change_alt_address_street(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_alt_address_street(currentValue);
  }

  const [alt_address_city, set_alt_address_city] = useState(
    alt_address_city_existing_value ?? ""
  );
  const alt_address_cityIsValid = alt_address_city.trim().length < 30;
  const alt_address_city_input_IsValid =
    alt_address_cityIsValid || !formTouched;
  function handle_change_alt_address_city(e: any) {
    const currentValue = e.target.value;

    setFormTouched(true);
    set_alt_address_city(currentValue);
  }

  const [alt_address_state, set_alt_address_state] = useState(
    alt_address_state_existing_value ?? ""
  );
  const alt_address_stateIsValid = alt_address_state.trim().length < 30;
  const alt_address_state_input_IsValid =
    alt_address_stateIsValid || !formTouched;
  function handle_change_alt_address_state(e: any) {
    const currentValue = e.target.value;

    setFormTouched(true);
    set_alt_address_state(currentValue);
  }

  const [alt_address_postalcode, set_alt_address_postalcode] = useState(
    alt_address_postalcode_existing_value ?? ""
  );
  const alt_address_postalcodeIsValid =
    (alt_address_postalcode.trim().length === postalcodeMustHaveLength &&
      !isNaN(alt_address_postalcode)) ||
    alt_address_postalcode.trim().length === 0;
  const alt_address_postalcode_input_IsValid =
    alt_address_postalcodeIsValid || !formTouched;
  function handle_change_alt_address_postalcode(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_alt_address_postalcode(currentValue);
  }

  const [alt_address_country, set_alt_address_country] = useState(
    alt_address_country_existing_value ?? ""
  );
  const alt_address_countryIsValid = alt_address_country.trim().length < 30;
  const alt_address_country_input_IsValid =
    alt_address_countryIsValid || !formTouched;
  function handle_change_alt_address_country(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_alt_address_country(currentValue);
  }

  const [assistant, set_assistant] = useState(assistant_existing_value ?? "");
  const assistantIsValid =
    assistant.trim().length >= 2 || assistant.trim().length === 0;
  const assistant_input_IsValid = assistantIsValid || !formTouched;
  function handle_change_assistant(e: any) {
    const currentValue = e.target.value;
    setFormTouched(true);
    set_assistant(currentValue);
  }

  const [assistant_phone, set_assistant_phone] = useState(
    assistant_phone_existing_value ?? ""
  );
  const assistant_phoneIsValid =
    assistant_phone.trim().length >= 2 || assistant_phone.trim().length === 0;
  function handle_change_assistant_phone(e: any) {
    const currentValue = e.target.value;

    setFormTouched(true);
    set_assistant_phone(currentValue);
  }

  const [phoneTypesList] = useState(location.state.phoneTypesList);
  const [isFormValid, setIsFormValid] = useState(false);
  const [phonesValid, dispatchPhonesValid] = useReducer(
    (state: any, action: any): any => {
      const id = action.id;
      const phoneObj = state.find((item: any) => item.id === id);
      if (!phoneObj) return state;
      switch (action.type) {
        case "formField":
          phoneObj.isTouched = true;
          phoneObj.value = action.value;
          const currentPhoneValue = phoneObj.value;
          if (
            isNaN(currentPhoneValue) ||
            currentPhoneValue.length >= 20 ||
            currentPhoneValue.length < 3
          ) {
            phoneObj.isValid = false;
          } else {
            phoneObj.isValid = true;
          }
          break;
        case "typeField":
          phoneObj.typeId = action.typeSelected;
          break;
        case "blurField":
          phoneObj.isTouched = true;
          break;
        case "cleanup":
          return orderBy(
            phoneTypesList.map((item: any) => {
              const existingPhone = location?.state?.phones?.phones?.find(
                (phone: any) => phone?.id === item.id
              );
              return {
                id: item.id,
                isValid: existingPhone ? true : false,
                value: existingPhone ? existingPhone.phone_number : "",
                isTouched: false,
                typeId: existingPhone ? existingPhone.id : null,
              };
            }),
            ["value"],
            ["desc"]
          );
        default:
          throw new Error("No such option");
      }
      return state.map((item: any) => {
        if (item.id === id) return phoneObj;
        return item;
      });
    },
    orderBy(
      phoneTypesList.map((item: any) => {
        const existingPhone = location?.state?.phones?.find(
          (phone: any) => phone?.id === item.id
        );
        return {
          id: item.id,
          isValid: existingPhone ? true : false,
          value: existingPhone ? existingPhone.phone_number : "",
          isTouched: false,
          typeId: existingPhone ? existingPhone.id : null,
        };
      }),
      ["value"],
      ["desc"]
    )
  );
  const typesTaken = phonesValid
    .map((item: any) => item.typeId)
    .filter((item: any) => typeof item === "number");
  const phoneTypesPerField = phoneTypesList.map((item: any) => {
    return {
      id: item.id,
      types: phoneTypesList.map((type: any) => {
        return {
          ...type,
          active:
            !typesTaken.includes(type.id) ||
            phonesValid.find((phoneItem: any) => phoneItem.id === item.id)
              ?.typeId === type.id,
        };
      }),
    };
  });
  const isPhonesFieldValid = phonesValid.map((item: any) => {
    return {
      id: item.id,
      value:
        (item.isValid && typeof item.typeId === "number") || !item.isTouched,
    };
  });
  const numberPhonesShown = useRef(
    location?.state?.phones?.length ? location.state.phones.length : 1
  );

  const [isAddbuttonValid, setIsAddbuttonValid] = useState(true);
  function cleanUpFields() {
    set_description("");
    set_salutation("");
    set_first_name("");
    set_last_name("");
    set_title("");
    set_photo("");
    set_department("");
    set_do_not_call(false);
    set_primary_address_street("");
    set_primary_address_city("");
    set_primary_address_state("");
    set_primary_address_postalcode("");
    set_primary_address_country("");
    set_alt_address_street("");
    set_alt_address_city("");
    set_alt_address_state("");
    set_alt_address_postalcode("");
    set_alt_address_country("");
    set_assistant("");
    set_assistant_phone("");
    dispatchPhonesValid({ type: "cleanup" });
  }
  function handleUsers(contact_id: number) {
    for (let user of users) {
      const data = { contact_id, ...user };
      if (user.type === "new") {
        createUser({
          fn: (data: any) => {
            if (data.status === 201) {
              const id = data.data.id;
              for (let domain of user.domains) {
                toggleDomain({
                  fn: (data: any) => {},
                  userId: id,
                  domainId: domain,
                });
              }
              setTransactionSuccess(true);
            } else {
              setTransactionSuccess(false);
            }
          },
          data,
        });
      } else {
        editUser({
          fn: (data: any) => {},
          id: user.id,
          data,
        });
      }
    }
  }
  function handleSubmit() {
    if (usersBelongToContact) {
      setReactivateModalIsOpen(true);
    } else {
      createEditUser();
    }
  }
  function createEditUser() {
    let data: any = {
      description: description === "" ? null : description,
      salutation: salutation === "" ? null : salutation,
      first_name: first_name === "" ? null : first_name,
      last_name: last_name === "" ? null : last_name,
      title: title === "" ? null : title,
      photo: photo === "" ? null : photo,
      department: department === "" ? null : department,
      do_not_call: do_not_call === false ? false : do_not_call,
      primary_address_street:
        primary_address_street === "" ? null : primary_address_street,
      primary_address_city:
        primary_address_city === "" ? null : primary_address_city,
      primary_address_state:
        primary_address_state === "" ? null : primary_address_state,
      primary_address_postalcode:
        primary_address_postalcode === "" ? null : primary_address_postalcode,
      primary_address_country:
        primary_address_country === "" ? null : primary_address_country,
      alt_address_street: alt_address_street === "" ? null : alt_address_street,
      alt_address_city: alt_address_city === "" ? null : alt_address_city,
      alt_address_state: alt_address_state === "" ? null : alt_address_state,
      alt_address_postalcode:
        alt_address_postalcode === "" ? null : alt_address_postalcode,
      alt_address_country:
        alt_address_country === "" ? null : alt_address_country,
      assistant: assistant === "" ? null : assistant,
      assistant_phone: assistant_phone === "" ? null : assistant_phone,
      phones: phonesValid
        .map((phone: any) => {
          return {
            id: phone.id,
            value: phone.value,
            phoneType: phone.typeId,
          };
        })
        .filter((phone: any) => phone.value),
    };
    if (idToEdit) {
      data = { id: idToEdit, ...data };
    }
    if (action === "create") {
      createPerson({
        fn: (data: any) => {
          try {
            if ([201, 200].includes(data.status)) {
              handleUsers(data.data.id);
              setTransactionSuccess(true);
              cleanUpFields();
              setTransactionFinished(true);
              navigate("/admin", { state: { status: data.status } });
              return data;
            }
          } catch (error: any) {
            setTransactionFinished(true);
            return;
          }
        },
        data,
      });
    } else if (action === "edit") {
      editPerson({
        fn: (data: any) => {
          try {
            if (data.status === 200) {
              handleUsers(idToEdit);
            }
          } catch (error: any) {
            setTransactionFinished(true);
            return;
          }
        },
        id: idToEdit,
        data,
      });
    }
    setTransactionFinished(true);
    setTransactionSuccess(true);
  }

  function handlePhoneChange(
    phoneObj: { id: number; value: string; phoneType: string }[]
  ) {
    dispatchPhonesValid({ type: "formField", ...phoneObj });
  }
  function handleSelectType(typeSelected: number, id: number) {
    dispatchPhonesValid({ type: "typeField", typeSelected, id });
  }
  const [phonesRow, setPhonesRow] = useState(
    phonesValid.map((phoneTypeItem: any, index: number) => {
      let hidden = true;
      if (index < numberPhonesShown.current || phoneTypeItem.value.length > 0)
        hidden = false;
      return (
        <Phone
          key={phoneTypeItem.id}
          phoneTypesList={phoneTypesPerField
            .find((type: any) => type.id === phoneTypeItem.id)
            .types.filter((type: any) => type.active === true)}
          id={phoneTypeItem.id}
          value={phoneTypeItem.value}
          isValid={true}
          handlePhoneChange={handlePhoneChange}
          hidden={hidden}
          handleSelectType={handleSelectType}
          handleBlur={handlePhoneBlur}
        ></Phone>
      );
    })
  );

  useEffect(() => {
    setPhonesRow(
      phonesValid.map((phoneTypeItem: any, index: number) => {
        let hidden = true;
        if (index < numberPhonesShown.current) hidden = false;
        return (
          <Phone
            key={phoneTypeItem.id}
            phoneTypesList={phoneTypesPerField
              .find((type: any) => type.id === phoneTypeItem.id)
              .types.filter((type: any) => type.active === true)}
            id={phoneTypeItem.id}
            value={phoneTypeItem.value}
            isValid={
              isPhonesFieldValid.find(
                (item: any) => item.id === phoneTypeItem.id
              ).value
            }
            handlePhoneChange={handlePhoneChange}
            hidden={hidden}
            handleSelectType={handleSelectType}
            handleBlur={handlePhoneBlur}
          ></Phone>
        );
      })
    );
  }, [isPhonesFieldValid, phoneTypesPerField, phonesValid]);

  function handlePhoneBlur(id: number) {
    dispatchPhonesValid({ id, type: "blurField" });
  }
  function handleNewPhone() {
    numberPhonesShown.current = ++numberPhonesShown.current;

    setPhonesRow((previousValue: any) => {
      if (previousValue.length === numberPhonesShown.current) {
        setIsAddbuttonValid(false);
      }
      return [
        ...previousValue.slice(0, numberPhonesShown.current - 1),
        <Phone
          key={numberPhonesShown.current}
          phoneTypesList={phoneTypesPerField
            .find((type: any) => type.id === numberPhonesShown.current)
            .types.filter((type: any) => type.active === true)}
          id={numberPhonesShown.current}
          value=""
          isValid={true}
          handlePhoneChange={handlePhoneChange}
          hidden={false}
          handleSelectType={handleSelectType}
          handleBlur={handlePhoneBlur}
        ></Phone>,
        ...previousValue.slice(numberPhonesShown.current),
      ];
    });
  }

  useEffect(() => {
    if (action === "create") {
      if (
        isPhonesFieldValid.every((item: any) => item.value) &&
        descriptionIsValid &&
        salutationIsValid &&
        first_nameIsValid &&
        last_nameIsValid &&
        titleIsValid &&
        photoIsValid &&
        departmentIsValid &&
        do_not_callIsValid &&
        primary_address_streetIsValid &&
        primary_address_cityIsValid &&
        primary_address_stateIsValid &&
        primary_address_postalcodeIsValid &&
        primary_address_countryIsValid &&
        alt_address_streetIsValid &&
        alt_address_cityIsValid &&
        alt_address_stateIsValid &&
        alt_address_postalcodeIsValid &&
        alt_address_countryIsValid &&
        assistantIsValid &&
        assistant_phoneIsValid &&
        usersValid &&
        !usernamesTaken &&
        !emailsTaken
      ) {
        setIsFormValid(true);
        return;
      }
      setIsFormValid(false);
    } else {
      if (
        isPhonesFieldValid.every((item: any) => item.value) &&
        descriptionIsValid &&
        salutationIsValid &&
        first_nameIsValid &&
        last_nameIsValid &&
        titleIsValid &&
        photoIsValid &&
        departmentIsValid &&
        do_not_callIsValid &&
        primary_address_streetIsValid &&
        primary_address_cityIsValid &&
        primary_address_stateIsValid &&
        primary_address_postalcodeIsValid &&
        primary_address_countryIsValid &&
        alt_address_streetIsValid &&
        alt_address_cityIsValid &&
        alt_address_stateIsValid &&
        alt_address_postalcodeIsValid &&
        alt_address_countryIsValid &&
        assistantIsValid &&
        assistant_phoneIsValid &&
        usersValid &&
        !usernamesTaken &&
        !emailsTaken &&
        typeof Number(usersBelongToContact) === "number"
      ) {
        setIsFormValid(true);
        return;
      }
      setIsFormValid(false);
    }
  }, [
    phonesValid,
    descriptionIsValid,
    salutationIsValid,
    first_nameIsValid,
    last_nameIsValid,
    titleIsValid,
    photoIsValid,
    departmentIsValid,
    do_not_callIsValid,
    primary_address_streetIsValid,
    primary_address_cityIsValid,
    primary_address_stateIsValid,
    primary_address_postalcodeIsValid,
    primary_address_countryIsValid,
    alt_address_streetIsValid,
    alt_address_cityIsValid,
    alt_address_stateIsValid,
    alt_address_postalcodeIsValid,
    alt_address_countryIsValid,
    assistantIsValid,
    assistant_phoneIsValid,
    usersValid,
    usernamesTaken,
    emailsTaken,
    usersBelongToContact,
    formTouched,
    action,
    isPhonesFieldValid,
  ]);

  useEffect(() => {
    if (!idToEdit) return;
    getUsers({
      fn: (data: any) => {
        setUsers(
          data.data.map((item: any) => {
            return {
              type: "existing",
              id: item.id,
              username: item.username,
              email: item.email,
              password: item.password,
              active: item.active,
              domains: [],
            };
          })
        );
      },
      id: idToEdit,
    });
  }, [getUsers, idToEdit]);
  const transactionResult = {
    type: transactionSuccess ? "success" : "danger",
    title: transactionSuccess ? t("success") + "!" : t("something_goes_wrong"),
    description: transactionSuccess
      ? t("person_updated")
      : t("something_goes_wrong"),
  };

  return (
    <>
      <ConfirmReactivateEmail
        isModalShowing={reactivateModalIsOpen}
        onClose={setReactivateModalIsOpen}
        header={"User to reactivate header"}
        email={
          users.find((user: any) => user.id === usersBelongToContact)?.email ??
          ""
        }
        onContinue={() => {
          createEditUser();
          setReactivateModalIsOpen(false);
        }}
      ></ConfirmReactivateEmail>
      <Container className={"mt--7"} fluid>
        <Row>
          <div>
            {transactionFinished && (
              <Alert {...transactionResult} onClick={handleCloseClick}></Alert>
            )}
          </div>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader className="bg-white border-0">
                <Row key="3" className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t("my_account")}</h3>
                  </Col>
                  <Col className="text-right" xs="2">
                    <Button
                      variant="success"
                      onClick={() => {
                        cleanUpFields();
                        navigate("/admin");
                      }}
                    >
                      {t("go_back")}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className={styles.container}>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    {t("basic_information")}
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first_name"
                          >
                            {t("first_name")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={first_name}
                            onChange={handle_change_first_name}
                            invalid={!first_name_input_IsValid}
                            value={first_name}
                            id="first_name"
                            placeholder={t("first_name")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="last_name"
                          >
                            {t("last_name")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="last_name"
                            placeholder={t("last_name")}
                            type="text"
                            defaultValue={last_name}
                            onChange={handle_change_last_name}
                            invalid={!last_name_input_IsValid}
                            value={last_name}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="title">
                            {t("title")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={title}
                            onChange={handle_change_title}
                            invalid={!title_input_IsValid}
                            value={title}
                            id="title"
                            placeholder={t("title")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="salutation"
                          >
                            {t("salutation")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={salutation}
                            onChange={handle_change_salutation}
                            invalid={!salutation_input_IsValid}
                            value={salutation}
                            id="input_saludation"
                            placeholder={t("salutation")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    {t("address")}
                  </h6>
                  <div className="pl-lg-4">
                    <Row key="4">
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="primary_address_street"
                          >
                            {t("primary_address")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={primary_address_street}
                            onChange={handle_change_primary_address_street}
                            invalid={!primary_address_street_input_IsValid}
                            value={primary_address_street}
                            id="primary_address_street"
                            placeholder={t("primary_address")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row key="5">
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="primary_address_country"
                          >
                            {t("country")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={primary_address_country}
                            onChange={handle_change_primary_address_country}
                            invalid={!primary_address_country_input_IsValid}
                            value={primary_address_country}
                            id="primary_address_country"
                            placeholder={t("country")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="primary_address_state"
                          >
                            {t("state")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="primary_address_state"
                            placeholder={t("state")}
                            defaultValue={primary_address_state}
                            onChange={handle_change_primary_address_state}
                            invalid={!primary_address_state_input_IsValid}
                            value={primary_address_state}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            {t("city")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={primary_address_city}
                            onChange={handle_change_primary_address_city}
                            invalid={!primary_address_city_input_IsValid}
                            value={primary_address_city}
                            id="primary_address_city"
                            placeholder={t("city")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="primary_address_postalcode"
                          >
                            {t("postal_code")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="primary_address_postalcode"
                            placeholder={t("postal_code")}
                            type="number"
                            defaultValue={primary_address_postalcode}
                            onChange={handle_change_primary_address_postalcode}
                            invalid={!primary_address_postalcode_input_IsValid}
                            value={primary_address_postalcode}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    {t("alt_address")}
                  </h6>
                  <div className="pl-lg-4">
                    <Row key="6">
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="alt_address_street"
                          >
                            {t("alt_address")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={alt_address_street}
                            onChange={handle_change_alt_address_street}
                            invalid={!alt_address_street_input_IsValid}
                            value={alt_address_street}
                            id="alt_address_street"
                            placeholder={t("alt_address")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row key="7">
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="alt_address_country"
                          >
                            {t("country")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={alt_address_country}
                            onChange={handle_change_alt_address_country}
                            invalid={!alt_address_country_input_IsValid}
                            value={alt_address_country}
                            id="alt_address_country"
                            placeholder={t("country")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="alt_address_state"
                          >
                            {t("state")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="alt_address_state"
                            placeholder={t("state")}
                            defaultValue={alt_address_state}
                            onChange={handle_change_alt_address_state}
                            invalid={!alt_address_state_input_IsValid}
                            value={alt_address_state}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            {t("city")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={alt_address_city}
                            onChange={handle_change_alt_address_city}
                            invalid={!alt_address_city_input_IsValid}
                            value={alt_address_city}
                            id="alt_address_city"
                            placeholder={t("city")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="alt_address_postalcode"
                          >
                            {t("postal_code")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="alt_address_postalcode"
                            placeholder={t("postal_code")}
                            type="number"
                            defaultValue={alt_address_postalcode}
                            onChange={handle_change_alt_address_postalcode}
                            invalid={!alt_address_postalcode_input_IsValid}
                            value={alt_address_postalcode}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">
                      {t("phones")}
                    </h6>
                    {phonesRow}
                    <Row key="8">
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">
                            {t("do_not_call")}
                          </label>
                          <br></br>
                          <Input
                            type="checkbox"
                            onChange={handle_change_do_not_call}
                            invalid={!do_not_callIsValid}
                            value={do_not_call}
                            checked={do_not_call}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row key="9">
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label"></label>
                          <br></br>
                          <ProfileButton
                            onClick={handleNewPhone}
                            valid={isAddbuttonValid}
                          >
                            {t("add_new")}
                          </ProfileButton>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">
                    {t("about_me")}
                  </h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>{t("about_me")}</label>
                      <Input
                        className="form-control-alternative"
                        placeholder={t("a_few_words_about_you")}
                        rows="4"
                        defaultValue={description}
                        onChange={handle_change_description}
                        invalid={!description_input_IsValid}
                        value={description}
                        id="description"
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    {t("additional_information")}
                  </h6>
                  <div className="pl-lg-4">
                    <Row key="10">
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first_name"
                          >
                            {t("assistant")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={assistant}
                            onChange={handle_change_assistant}
                            invalid={!assistant_input_IsValid}
                            value={assistant}
                            id="assistant"
                            placeholder={t("assistant")}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="assistant_phone"
                          >
                            {t("assistant_phone")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="assistant_phone"
                            placeholder={t("assistant_phone")}
                            type="text"
                            value={assistant_phone}
                            onChange={handle_change_assistant_phone}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    {t("users")}
                  </h6>
                  <div className="pl-lg-4">
                    <Users
                      usersList={users.filter(
                        (user: UserRepository) => user.active === true
                      )}
                      onChange={(data: userFormChanged) => {
                        console.log(data, "data");
                        setUsers((previousValue: any) => {
                          return previousValue
                            .map((item: userFormChanged, i: number) => {
                              if (item.id === data.id) return data;
                              return item;
                            })
                            .filter(
                              (item: any) =>
                                !(item.type === "new" && item.active === false)
                            );
                        });
                      }}
                      addNew={() => {
                        setUsers((previousValue: UserRepository[]) => {
                          const newField: any = {
                            type: "new",
                            username: "",
                            password: "",
                            email: "",
                            active: true,
                            id: currentNewUserId.current,
                            domains: [],
                          };
                          return [...previousValue, newField];
                        });
                        currentNewUserId.current = currentNewUserId.current - 1;
                      }}
                      areEmailsTaken={setEmailsTaken}
                      areUsernamesTaken={setUsernamesTaken}
                      areUsersValid={setUsersValid}
                    ></Users>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4"> </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <ProfileButton
                            onClick={handleSubmit}
                            valid={isFormValid}
                            defaultValue={assistant_phone}
                            onChange={handle_change_assistant_phone}
                            variant={"primary"}
                          >
                            {t("submit")}
                          </ProfileButton>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
