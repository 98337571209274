import { UserForm, UserRepository } from "../../../../../models/Admin/User";
import UserComponent from "./User";
import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FormGroup, Row, Col } from "reactstrap";

type userFormChanged = {
  id: number;
  formValue: UserRepository;
};

type TUsersTaken = { id: number; isTaken: boolean; xistingUserData: {} };

export default function Users(props: {
  usersList: UserRepository[];
  onChange: any;
  addNew: any;
  areUsersValid: any;
  areEmailsTaken: any;
  areUsernamesTaken: any;
}) {
  const { usersList } = props;
  const { t } = useTranslation();
  const usersTaken = useRef<TUsersTaken[]>([]);
  let allFieldsValid = false;

  function validateUsers() {
    allFieldsValid =
      usersList.every((item: UserRepository) => {
        const userForm = new UserForm(
          item.username,
          item.email,
          item.password,
          item.active,
          item.domains
        );
        return userForm.validateAll();
      }) && usersList.filter((user: any) => user.type === "new").length <= 2;
  }
  validateUsers();
  props.areUsersValid(allFieldsValid);
  function handleChange(data: userFormChanged) {
    props.areUsersValid(allFieldsValid);
    props.onChange(data);
  }

  function handleUsernameTaken(data: TUsersTaken) {
    const currentUser = usersTaken.current.find(
      (user: TUsersTaken) => user.id === data.id
    );
    if (currentUser === undefined)
      usersTaken.current = [...usersTaken.current, data];
    else {
      usersTaken.current = usersTaken.current.map((user: TUsersTaken) => {
        if (user.id === data.id) return data;
        return user;
      });
    }

    props.areUsernamesTaken(
      usersTaken.current.some((value: any) => value.isTaken === true)
    );
  }

  function handleEmailTaken(data: TUsersTaken) {
    const currentUser = usersTaken.current.find(
      (user: TUsersTaken) => user.id === data.id
    );
    if (currentUser === undefined)
      usersTaken.current = [...usersTaken.current, data];
    else {
      usersTaken.current = usersTaken.current.map((user: TUsersTaken) => {
        if (user.id === data.id) return data;
        return user;
      });
    }
    props.areEmailsTaken(
      usersTaken.current.some((value: any) => value.isTaken === true)
    );
  }
  return (
    <React.Fragment>
      <React.Fragment>
        {usersList.map((userItem: any, index: number) => {
          let usernameTaken = false;
          let emailTaken = false;
          const { id } = userItem;

          const usernameList = usersList.map((user: any) => user.username);
          const emailList = usersList.map((user: any) => user.email);

          usersList.map((userItem: any, index: number) => {
            for (let step = index + 1; step < usernameList.length; step++) {
              if (userItem.username === usernameList[step]) {
                usernameTaken = true;
                props.areUsernamesTaken(true);
              } else {
                usernameTaken = false;
                props.areUsernamesTaken(false);
              }
            }
            for (let step = index + 1; step < emailList.length; step++) {
              if (userItem.email === emailList[step]) {
                emailTaken = true;
                props.areEmailsTaken(true);
              } else {
                emailTaken = false;
                props.areEmailsTaken(false);
              }
            }
            return userItem;
          });

          return (
            <UserComponent
              key={id}
              {...userItem}
              onChange={handleChange}
              onUserTaken={handleUsernameTaken}
              onEmailTaken={handleEmailTaken}
              usernameTaken={usernameTaken}
              emailTaken={emailTaken}
            ></UserComponent>
          );
        })}
      </React.Fragment>
      <div>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Button
                disabled={!allFieldsValid}
                variant={"secondary"}
                onClick={() => {
                  props.addNew();
                }}
              >
                {t("add_new")}
              </Button>{" "}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
