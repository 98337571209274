import { useState, useEffect, useLayoutEffect } from "react";
import Select, { Options } from "react-select";
import { useTranslation } from "react-i18next";
import { Field } from "../../../interfaces";

const FilterColumns = (props: any) => {
  const { entity } = props;
  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  const { t } = useTranslation();
  const [options, setColumns] = useState<
    Options<{
      value: string;
      label: string;
    }>
  >([]);
  const [selected, setSelected] = useState<
    Options<{
      value: string;
      label: string;
    }>
  >([]);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  useEffect(() => {
    setColumns(
      props.fields.data.map((field: Field) => ({
        value: field.name,
        label: tWithEntityNamespace("fields.".concat(field.name)),
      }))
    );
    const formattedSelected = props.selected.map((field: string) => {
      const matchedField = props.fields.data.find(
        (f: Field) => f.name.toLowerCase() === field.toLowerCase()
      );
      return {
        value: field,
        label: matchedField
          ? tWithEntityNamespace("fields.".concat(matchedField.name))
          : field,
      };
    });
    setSelected(formattedSelected);
  }, [
    props.fields.data,
    props.selected,
    t,
    tWithEntityNamespace,
    props.entity,
  ]);

  const handleColumn = (column: any) => {
    const filterColumns: string[] = [];
    column.forEach((item: any, index: any) => {
      filterColumns.push(item.value);
    });
    if (
      props.mainAttr &&
      props.mainAttr !== "" &&
      !filterColumns.includes(props.mainAttr) &&
      column.length > 0
    )
      filterColumns.push(props.mainAttr);
    props.setFiltersColumnsData(filterColumns);
  };

  return (
    <Select
      isMulti
      placeholder={t("entity_table.filter_columns")}
      onChange={handleColumn}
      options={options}
      value={selected}
    />
  );
};

export default FilterColumns;
