import environment from "../environment";
import HttpHandler from "../hook/http-handler";
import { useCallback } from "react";

// ? --- React Redux Selectors ---
import { useAppSelector } from "../app/hooks";
import { selectDomain } from "../reducers/domain/domainSlice";
import { getToken } from "../reducers/auth";

const apiDomainsURL = `${environment.apiUrl}/domains`;

export default function useDomainsService() {
  const currentDomain = useAppSelector(selectDomain);
  const credentials = useAppSelector(getToken);
  const { sendRequest } = HttpHandler();

  const getMany = useCallback(
    async ({ fn }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const getOne = useCallback(
    async ({ fn, id }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}/${id}`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const create = useCallback(
    async ({ fn, domain }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}`,
            method: "POST",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              IsNew: true,
            },
            body: {
              domain,
            },
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  const update = useCallback(
    async ({ fn, domain }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}`,
            method: "PUT",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
            },
            body: {
              domain
            }
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  const getUsersByDomain = useCallback(
    async ({ fn, id }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}/${id}/users`,
            method: "GET",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain: currentDomain.id,
            },
          },
          fn
        );
      }
    },
    [credentials, currentDomain.id, sendRequest]
  );

  const setUserByDomain = useCallback(
    async ({ fn, domain, user }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}/${domain}/users/${user}`,
            method: "POST",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain,
              user,
            },
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  const deleteUserByDomain = useCallback(
    async ({ fn, domain, user }: any) => {
      if (credentials && credentials.logged) {
        sendRequest(
          {
            url: `${apiDomainsURL}/${domain}/users/${user}`,
            method: "DELETE",
            headers: {
              Authorization: `${credentials.token_type} ${credentials.access_token}`,
              domain,
              user,
            },
          },
          fn
        );
      }
    },
    [credentials, sendRequest]
  );

  return {
    create,
    getMany,
    getOne,
    update,
    getUsersByDomain,
    setUserByDomain,
    deleteUserByDomain,
  };
}
