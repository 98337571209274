import { ReactNode } from "react";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";

import {
  Eye as IconEye,
  EyeSlash as IconEyeSlash,
} from "react-bootstrap-icons";

interface RelationshipCardProps {
  t: TFunction;
  children?: ReactNode;

  onClick: (name: string) => void;
  index: string | number;
  selected: boolean;
  amount: number;
  name: string;
  entityName: string;
}

const RelationshipCard = ({
  onClick,
  selected,
  index,
  amount,
  name,
  t,
  entityName,
}: RelationshipCardProps) => {
  return (
    <Card
      key={index}
      style={{ cursor: "pointer" }}
      border="light"
      bg="light"
      onClick={() => onClick(name)}
      className="h-100 w-100 bg-body rounded shadow"
    >
      <Card.Header>
        <Row>
          <Col className="d-flex justify-content-between align-items-center">
            <span>
              {t("relationships." + name, { ns: "entities." + entityName })}
            </span>
            {selected ? <IconEyeSlash /> : <IconEye />}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {t("navbar.entities")} -&gt; {amount}
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(RelationshipCard);
