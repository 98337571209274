import { useState } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import styled from "styled-components";
import { DomainItemType } from "./types";

interface DomainItemProps {
    domain: DomainItemType;
    children?: Array<JSX.Element>;
    onSelectDomain: Function;
    domainIdSelected?: number;
}

const DomainContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const DomainLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    cursor: pointer;
`;

const DomainItemContainer = styled.div`
    padding: 5px;
    width: 100%;
`;

const SubDomainList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SubDomainsContainer = styled.div`
    display: flex;
    padding-left: 15px;

    ${DomainLabel} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;

         + ${SubDomainList} {
            ${DomainLabel} {
                font-weight: 500;
            }
         }
    }
`;

const DomainItem = ({ domain, children = [], onSelectDomain, domainIdSelected }: DomainItemProps) => {
    const [isOpen, toggleOpen] = useState(false);
    const isDomainSelected = domainIdSelected === Number(domain.id);
    return (
        <DomainContainer key={domain.id}>
            <DomainItemContainer>
                <DomainLabel
                    className={isDomainSelected ? 'text-primary' : 'text-body'}
                >
                    <span
                        onClick={() => {
                            onSelectDomain(domain);
                            toggleOpen(!isOpen);
                        }}
                    >{domain.name}</span>
                    { children?.length > 0 ? (
                        <>
                            { isOpen ? <ChevronUp onClick={() => toggleOpen(!isOpen)} /> : <ChevronDown onClick={() => toggleOpen(!isOpen)} />}
                        </>
                    ) : null}
                </DomainLabel>
                { isOpen && (
                    <SubDomainsContainer>
                        <SubDomainList>
                            {children}
                        </SubDomainList>
                    </SubDomainsContainer>
                ) }
            </DomainItemContainer>
        </DomainContainer>
    )
};

export default DomainItem;
