import React from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from "../../../app/hooks";
import { isLoggedIn } from "../../../reducers/auth";

const ProtectedRoutes = ({ children }: any): JSX.Element => {
  const loggedIn = useAppSelector(isLoggedIn);

  const checkRouteAuthorization = React.useCallback(
    function CheckRouteAuthorization(children: JSX.Element): JSX.Element {
      if (!loggedIn) {
        console.info("User is not logged in. Making redirect to login page.");
        return <Navigate replace to="/login" />;
      }

      return children;
    },
    [loggedIn]
  );

  return checkRouteAuthorization(children);
};

export default ProtectedRoutes;
