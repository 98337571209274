import {
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  FC,
  useMemo,
} from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

import { Field, Validation, FieldInputProps } from "src/interfaces";
import FieldInput from "./FieldInput";

interface InputProps {
  issues: Array<Validation.Issue>;
  label?: string;
  input: Field;
  value: any;

  onChange: Function;
  placeholder: string;
}

const Input: FC<InputProps> = (props) => {
  const { t } = useTranslation();

  // ? Read only will be applied if updating fields.
  const [value, setValue] = useState(props.value);
  const [readOnly, setReadOnly] = useState(props.input.readonly);
  useEffect(() => setValue(props.value), [props.value]);

  const formatBooleanValue = () => {
    let value = props?.value;
    if (typeof value === "string") value = value === "true" ? true : false;

    return value;
  };

  useEffect(() => {
    if (props.input.type === "boolean") {
      props.onChange((prevState: any) => {
        prevState[props.input.name] = formatBooleanValue();
        return { ...prevState };
      });
    } else if (props.placeholder) {
      props.onChange((prevState: any) => {
        prevState[props.input.name] = props.value;
        return { ...prevState };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputHandler = useCallback(
    (event: any) => {
      if (event?.target?.type === "file" || event?.target?.type === "image") {
        const reader = new FileReader();
        const fileObject = event.target.files[0];

        reader.addEventListener("load", () => {
          if (!reader.result) return;
          let modified = reader.result.toString();

          if (props.input.type === "binary") {
            modified = modified.split(",")[1];
            modified = "data:application/octet-stream;base64," + modified;
          }

          setValue(modified);
          props.onChange((prevState: any) => {
            prevState[event.target.name] = modified;
            return { ...prevState };
          });
          return;
        });

        reader.readAsDataURL(fileObject);
      }
      setValue(event.target.value);
      props.onChange((prevState: any) => {
        prevState[event.target.name] = event.target.value;
        return { ...prevState };
      });
    },
    [props]
  );

  const checkBoxHandler = (event: any) => {
    setValue(event.target.checked);
    props.onChange((prevState: any) => {
      let state = { ...prevState };
      state[event.target.name] = event.target.checked;
      return state;
    });
  };

  const dateTimeHandler = (date: string) => {
    setValue(date);
    props.onChange((prevState: any) => {
      prevState[props.input.name] = date;
      return { ...prevState };
    });
  };

  const inputValidator: FieldInputProps = useMemo(
    () => ({
      ...props.input,

      maxLength: props.input.len === 0 ? 255 : props.input.len,
      disabled: readOnly || props.input.unique,
      options: props.input.options || [],
      required: props.input.required,
      placeholder: props.input.placeholder,
      id: props.input.id ?? "",
      name: props.input.name,
      type: props.input.type,
      value: value,

      onChange: inputHandler,
    }),
    [inputHandler, props.input, readOnly, value]
  );

  if (props.input.regex && props.input.regex !== "") {
    inputValidator["pattern"] = props.input.regex;
  }

  const handleEditClick = () => setReadOnly(!readOnly);

  const openImage = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      const image = `<img width='30%' src='${props.value}' alt='${props.input.label}' />`;
      const tab = window.open();
      if (tab) {
        tab.document.open();
        tab.document.write(image);
        tab.document.close();
      }
    },
    [props.input.label, props.value]
  );

  const includesImage = useMemo(
    () =>
      (props.input.type === "file" || props.input.type === "image") &&
      props.value?.indexOf("image/") !== -1,
    [props.input.type, props.value]
  );

  const includesBinary = useMemo(
    () => props.input.type === "binary",
    [props.input.type]
  );

  const includesArchivePdf = useMemo(
    () => props.input.type === "file" && props.value?.indexOf("/pdf") !== -1,
    [props.input.type, props.value]
  );
  const getInputProps = (input: any) => {
    const commonProps = {
      field: input,
      ...inputValidator,
    };

    switch (input.type) {
      case "boolean":
        return {
          ...commonProps,
          onChange: checkBoxHandler,
          checked: value,
        };
      case "datetime":
        return {
          ...commonProps,
          onChange: dateTimeHandler,
          value: value,
        };
      default:
        return {
          ...commonProps,
          onChange: inputHandler,
          value: value,
        };
    }
  };

  return (
    <Form.Group>
      <InputGroup className="w-100 mt-2">
        <Form.Label className="w-100" children={t(props.input.label)} />

        <FieldInput {...getInputProps(props.input)} />

        <InputGroup.Text
          as="span"
          style={{ borderRadius: "0px .25rem .25rem 0px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="bi bi-pen"
            viewBox="0 0 16 16"
            width="16"
            height="16"
            fill="currentColor"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick}
          >
            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
          </svg>
        </InputGroup.Text>
      </InputGroup>

      {includesImage && (
        <Form.Text>
          <a
            href={props.value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={openImage}
            children={t("label.action.view_file", { ns: "application.misc" })}
          />
        </Form.Text>
      )}

      {includesBinary && props.value && (
        <Form.Text>
          <a
            href={props.value}
            download={props.input.label}
            children={t("label.action.view_file", { ns: "application.misc" })}
          />
        </Form.Text>
      )}

      {includesArchivePdf && (
        <Form.Text>
          <a
            href={props.value}
            download={"Archivo.pdf"}
            children={t("label.action.view_file", { ns: "application.misc" })}
          />
        </Form.Text>
      )}

      {props.issues.length > 0 && (
        <Form.Text className="text-danger">
          {props.issues.map((issue, index) => (
            <span
              key={index}
              children={t(issue.message, { ns: "application.misc" })}
            />
          ))}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default Input;
