import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { useAppSelector } from "../../../app/hooks";
import { isLoggedIn } from "../../../reducers/auth";
import LoginAuth from "../../../hook/useLoginAuth";
import { DynamicAppsLogo } from "src/assets";

import "./signInSignUp.css";

function SignIn() {
  const { t } = useTranslation("application.misc");
  const { LoginProcess } = LoginAuth.useLogin();
  const loggedIn = useAppSelector(isLoggedIn);
  const [validated, setValidated] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const daEmailField = React.useRef<HTMLInputElement>(null);
  const daPasswordField = React.useRef<HTMLInputElement>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInvalidEmail(false);

    const form = event.currentTarget;
    const emailField = form.elements.namedItem(
      "da_field_email"
    ) as HTMLInputElement;
    const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!form.checkValidity() || !regexEmail.test(emailField.value)) {
      if (!regexEmail.test(emailField.value)) {
        setInvalidEmail(true);
      }
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target as HTMLFormElement);
      const formParams = new URLSearchParams(formData as any);
      LoginProcess(formParams);
    }
    setValidated(true);
  };

  if (loggedIn) {
    console.info("User is logged in. Making redirect to home.");
    return <Navigate replace to="/" />;
  }

  return (
    <Container fluid>
      <Row className="p-3">
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <DynamicAppsLogo />
        </Col>
        <Col
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <h1>{t("login.welcome")}</h1>
          <p>{t("login.please_sign_in")}</p>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="w-100"
          >
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="da_field_email">
                  <Form.Label>{t("login.please_enter_your_email")}</Form.Label>
                  <Form.Control
                    type="email"
                    ref={daEmailField}
                    name="da_field_email"
                    placeholder={t("login.please_enter_your_email")}
                    isInvalid={invalidEmail}
                    className="email-login"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("login.email_feedback")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="da_field_password">
                  <Form.Label>
                    {t("login.please_enter_your_password")}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    ref={daPasswordField}
                    name="da_field_password"
                    placeholder={t("login.please_enter_your_password")}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("login.password_feedback")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button variant="primary" type="submit">
                  {t("login.login")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
