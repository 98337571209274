import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { Permissions } from "src/interfaces";

interface RoleState {
  roleId: number;
  permissions: Permissions.IPermissions[];
}

const initialState: RoleState = {
  roleId: 0,
  permissions: [],
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setState(state, action) {
      const { permissions, roleId } = action.payload;
      return {
        ...state,
        roleId,
        permissions,
      };
    },
    setPermission(state, action){
      state.permissions = action.payload
    }
  },
});

export const { setState, setPermission } = permissionsSlice.actions;

export const getPermissions = (state: RootState) => state.permissions;

export default permissionsSlice.reducer;
