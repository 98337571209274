import { useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState, FormEvent } from "react";
import IUser from "src/interfaces/IUser";

interface PasswordModalProps {
  showPasswordModal: boolean;
  handleClose: () => void;
  handleConfirm: (passwordToSave: IUser.IPassword) => void;
}

const passwordDefaultObject = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const PasswordModal = ({
  showPasswordModal = false,
  handleClose,
  handleConfirm,
}: PasswordModalProps) => {
  const [passwordObject, setPasswordObject] = useState<IUser.IPassword>(
    passwordDefaultObject
  );
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation();

  const checkValid = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      handleConfirm(passwordObject);
    }

    setValidated(true);
  };

  return (
    <Modal show={showPasswordModal} onHide={handleClose} size="lg">
      <Form noValidate validated={validated} onSubmit={checkValid}>
        <Modal.Header closeButton>
          <Modal.Title>{t("user_profile.change_password")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="currentPassword">
                <Form.Label>
                  {t("user_profile.password.current_password")}
                </Form.Label>
                <Form.Control
                  type="password"
                  value={passwordObject.oldPassword}
                  onChange={(e) => {
                    setPasswordObject({
                      ...passwordObject,
                      oldPassword: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.password.current_password")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label>
                  {t("user_profile.password.new_password")}
                </Form.Label>
                <Form.Control
                  type="password"
                  value={passwordObject.newPassword}
                  onChange={(e) => {
                    setPasswordObject({
                      ...passwordObject,
                      newPassword: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.password.new_password_feeback")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label>
                  {t("user_profile.password.confirm_password")}
                </Form.Label>
                <Form.Control
                  type="password"
                  value={passwordObject.confirmPassword}
                  onChange={(e) => {
                    setPasswordObject({
                      ...passwordObject,
                      confirmPassword: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("user_profile.password.new_password_feeback")}
                </Form.Control.Feedback>
                <Form.Text
                  style={{ color: "#dc3545" }}
                  hidden={
                    passwordObject.newPassword ===
                    passwordObject.confirmPassword
                  }
                >
                  {t("user_profile.password.confirm_password_feeback")}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="tertiary" onClick={handleClose}>
                {t("label.action.cancel", { ns: "application.misc" })}
              </Button>
              <Button type="submit">
                {t("label.action.save", { ns: "application.misc" })}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
