import environment from "../environment";
import HttpHandler from "../hook/http-handler";

// ? --- React Redux Selectors ---
import { useAppSelector } from "../app/hooks";
import { selectDomain } from "../reducers/domain/domainSlice";
import { getToken } from "../reducers/auth";

interface IRequestParams {
    data?: object;
    page?: number;
    limit?: number;
    entity: string;
    callback: Function;
    filter?: URLSearchParams[] | string;
}

export default function useViewService() {
    const currentDomain = useAppSelector(selectDomain);
    const credentials = useAppSelector(getToken);
    const { sendRequest } = HttpHandler();

    const paramsToString = (params: URLSearchParams[] | string, appendTo: string = ''): string => {
        if (typeof params === 'string') return params + appendTo;
        return params.reduce((acc, param) => {
            return acc + `&${param.toString()}`
        }, appendTo);
    }

    return {
        getAll: ({ page = 1, limit = 10, entity, callback, filter = '' }: IRequestParams) => {
            if (credentials && credentials.logged) {
                const filterString = paramsToString(filter);
                const withPagination = paramsToString(
                    [new URLSearchParams({
                        page: page.toString(),
                        limit: limit.toString(),
                    })],
                    filterString
                );
                sendRequest(
                    {
                        url: `${environment.apiUrl}/views/${entity}?${withPagination}`,
                        method: "GET",
                        headers: {
                            Authorization: `${credentials.token_type} ${credentials.access_token}`,
                            'domain': currentDomain.id
                        }
                    },
                    callback
                );
            }
        },
        createOne: ({ data, entity, callback }: IRequestParams) => {
            if (credentials && credentials.logged) {
                sendRequest(
                    {
                        url: `${environment.apiUrl}/views/${entity}`,
                        method: "POST",
                        headers: {
                            Authorization: `${credentials.token_type} ${credentials.access_token}`,
                            'domain': currentDomain.id
                        },
                        body: data
                    },
                    callback
                );
            }
        }
    }
}