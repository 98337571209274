import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TFunction, withTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

import useDomainsService from "src/services/domains.service";
import DomainItem from "./DomainItem";
import Vignette from "./Vignette";

const List = styled.ul((): any => ({
  listStyle: "none",
}));

export interface DomainListProps {
  ownership: Array<number>;
  fullAccess: boolean;
  subdomain?: number;
  userId: number;
  open: boolean;
  t: TFunction;

  [key: string]: any;
};

const DomainList: FC<DomainListProps> = ({ ownership, fullAccess = false, subdomain, userId, open: isOpen, t }) => {
  const { getMany, getOne } = useDomainsService();

  const [open, setOpen] = useState<boolean>(false);
  const [domains, setDomains] = useState([]);

  const getSubdomains = useCallback(async () => {
    if (open && subdomain) {
      getOne({
        id: subdomain,
        fn: (response: any) => {
          setDomains(response.data.subDomains);
        },
      });
    }
  }, [subdomain, getOne, open]);

  const getRootDomains = useCallback(async () => {
    if (open) {
      getMany({
        fn: (response: any) => {
          setDomains(response.data);
        },
      });
    }
  }, [getMany, open]);

  const owned = useMemo(() => {
    return ownership
      .map((id: number) => Number(id));
  }, [ownership]);

  useEffect(() => setOpen(isOpen), [isOpen]);
  useEffect(
    () => {
      const getDomains = subdomain ? getSubdomains : getRootDomains;
      getDomains()
        .then(() => { })
        .catch(() => { });
    },
    [getRootDomains, getSubdomains, ownership, subdomain]
  );


  return (
    <Container fluid>
      {
        !subdomain && (
          <Row>
            <Col className="d-flex justify-content-start align-items-center">
              <Vignette {... { open, onClick: () => setOpen(!open) }} />
              {" ".concat(t("domain.domain", { ns:"application.misc", count: domains.length }))}
            </Col>
          </Row>
        )
      }
      <Row as={List}>
        {
          open && domains
            .map((domain: any, index: number) => (
              <DomainItem
                key={index}
                userId={userId}
                ownership={owned}
                fullAccess={fullAccess}
                owned={owned.includes(domain.id)}
                {...{ domain }}
              />
            ))
        }
      </Row>
    </Container>
  );
};

export default withTranslation()(DomainList);