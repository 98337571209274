import { createContext, useCallback, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Table from "./Table";
import Form from "./Form";

const MenuCategoryContext = createContext<any>(null);

const MenuCategory = (): JSX.Element => {
  const navigate = useNavigate();

  const redirectCreate = useCallback(
    (event?: { preventDefault: Function }) => {
      if (event?.preventDefault) {
        event.preventDefault();
      }

      navigate("/config/menu_category/create");
    },
    [navigate]
  );

  const redirectEdit = useCallback(
    (id: number, event?: { preventDefault: Function }) => {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      navigate(`/config/menu_category/edit/${id}`);
    },
    [navigate]
  );

  return (
    <MenuCategoryContext.Provider value={{ redirectCreate, redirectEdit }}>
      <Routes>
        <Route path="/" element={<Table />} />
        <Route path="/create" element={<Form type="create" />} />
        <Route path="/edit/:id" element={<Form type="edit" />} />
        <Route path="*" element={<></>} />
      </Routes>
    </MenuCategoryContext.Provider>
  );
};

export function useMenuCategoryContext() {
  const context = useContext(MenuCategoryContext);
  if (!context) {
    throw new Error(
      "useMenuCategoryContext() must be used within a MenuCategoryContext provider."
    );
  }
  return context;
}

export default MenuCategory;
