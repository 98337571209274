import { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Field } from "src/interfaces";

interface DateTimeProps {
  field: Field;
  value: string | undefined;
  onChange?: React.Dispatch<React.SetStateAction<any>>;
  [key: string]: any;
}

export default function DateTime(props: DateTimeProps) {
  const { value, onChange } = props;
  const [ISO, setISO] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setISO(value);
    }
  }, [value]);

  const getTime = useCallback(() => {
    const date = new Date(ISO);
    const hour = date.getUTCHours().toString().padStart(2, "0");
    const minute = date.getUTCMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }, [ISO]);

  const updateDate = useCallback(
    (iso: string) => {
      onChange && onChange(iso);
    },
    [onChange]
  );

  return (
    <div className="d-flex">
      <Form.Control
        type="date"
        role="date"
        data-relate="datetime"
        className="me-2"
        value={ISO.split("T")[0]}
        onChange={(e) => {
          const time = ISO.split("T")[1];
          const iso = e.target.value + "T" + time;
          updateDate(iso);
        }}
      />

      <Form.Control
        type="time"
        role="time"
        data-relate="datetime"
        className="me-2"
        value={getTime()}
        onChange={(e) => {
          const date = ISO.split("T")[0];
          const iso = date + "T" + e.target.value + ":00Z";
          updateDate(iso);
        }}
      />
    </div>
  );
}
