import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridOrder } from "../../../../../models/Admin/GridOrder";
import useDomainsService from "../../../../../services/domains.service";
import Domain from "./Domain";

export default function Domains(props: any) {
  const { t } = useTranslation();
  const { getMany } = useDomainsService();
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    getMany({
      fn: (data: any) => {
        setDomains(data.data);
      },
    });
  }, [getMany]);
  const nestedLevel = 1;
  const gridOrder = useMemo(() => new GridOrder(nestedLevel), [nestedLevel]);
  return (
    <React.Fragment>
      <table>
        <tr
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12,1fr)",
          }}
        >
          <th style={gridOrder.active}>{t("active")}</th>
          <th style={gridOrder.name}>{t("name")}</th>
          <th style={gridOrder.description}>{t("description")}</th>
        </tr>
        {domains.map((domain: any) => {
          const checked = props.userDomains.find(
            (userDomain: number) => userDomain === domain.id
          )
            ? true
            : false;

          return (
            <Domain
              {...domain}
              checked={checked}
              onCheck={props.onCheck}
              userDomains={props.userDomains}
              updateDomains={props.updateDomains}
              nestedLevel={nestedLevel}
              gridOrder={gridOrder}
              disabled={props.disabled}
            />
          );
        })}
      </table>
    </React.Fragment>
  );
}
