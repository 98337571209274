import { FormGroup, Input, Row, Col, FormFeedback } from "reactstrap";
import Dropdown from "../Dropdown";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

function Phone(props: any) {
  const [phoneType, setPhoneType] = useState<undefined | number>(undefined);
  const id = useRef(props.id);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {!props.hidden && (
        <Row>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label">{t("type")}</label>
              <Dropdown
                onSelect={(idSelected: any) => {
                  setPhoneType(idSelected);
                  props.handleSelectType(idSelected, id.current);
                }}
                items={props.phoneTypesList.map((item: any) => {
                  return { id: item.id, value: t(item.value) };
                })}
                title={
                  phoneType === undefined
                    ? t("type")
                    : t(props.phoneTypesList[phoneType - 1]?.value)
                }
                activeOption={id.current}
              ></Dropdown>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="alt_address_state">
                {t("phone_number")}
              </label>
              <Input
                className="form-control-alternative"
                id={id.current.toString()}
                type="text"
                value={props.value}
                invalid={props.isValid ? false : true}
                onChange={(e) => {
                  props.handlePhoneChange({
                    id: id.current,
                    value: e.target.value,
                    phoneType,
                  });
                }}
                // onBlur={props.handleBlur(id.current)}
              />
              {!props.isValid && (
                <FormFeedback>{t("phone_input_error_message")}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

export default Phone;
