import {
  Dispatch,
  FC,
  FormEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row, Modal } from "react-bootstrap";
import {
  capitalize,
  toString,
  toNumber,
  isNull,
  isNaN,
  isEqual,
  trim,
} from "lodash";
import { TFunction, useTranslation, withTranslation } from "react-i18next";
import Pagination from "rsuite/Pagination";
import Table from "rsuite/Table";
import { RowDataType, SortType } from "rsuite-table";

import {
  Entity,
  Field,
  GroupTable,
  IPagination,
  IServerResponse,
  ITableLayout,
  IView,
  RelationshipKey,
} from "src/interfaces";

import useEntityService from "src/services/entity.service";
import useViewService from "src/services/view.service";

import { useAppDispatch } from "src/app/hooks";
import { activate, inactivated } from "src/reducers/errorHttp/successHttpSlice";

import { ExportExcel } from "src/components/shared/ExportExcel";
import FilterColumns from "../Input/filterColumns";
import SearchBar from "../Input/searchBar";
import SortData from "../Input/sortData";
import FiltersAccordion from "./FiltersAccordion";
import useContextMenu from "src/hook/useContextMenu";

import classes from "./contextMenu.module.css";

interface IEntityTableProps {
  entity: string;
  t: TFunction;
}

interface ITableRSuiteStatus {
  loading: boolean;
  empty: boolean;
  error: boolean;

  sortingColumn?: string;
  sortingType?: SortType;
}

type contextInitialState = {
  handleCustomViewSubmit: (
    e: FormEvent<HTMLFormElement>,
    viewNameInput: string,
    viewDescriptionInput: string
  ) => void;
  handleApplyView: (e: React.FormEvent<HTMLFormElement>) => void;
  dispatchCurrentView: Dispatch<any>;
  currentView: ITableLayout;
  viewList: Array<IView>;

  rows: Array<Entity.IEntity>;
  fields: Array<Field>;
  entity: string;
};

const context = createContext<contextInitialState | null>(null);

export function useEntityTable() {
  const contextValue = useContext(context);
  if (!contextValue) {
    throw new Error(
      "useEntityTableContext must be used within a context provider"
    );
  }
  return contextValue;
}

const EntityTable: FC<IEntityTableProps> = ({ entity }) => {
  const { t } = useTranslation();
  const { getAll, getFields, getEntityProperties, getRelationshipsKeys } =
    useEntityService();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { createOne: createEntityView, getAll: getAllEntityViews } =
    useViewService();

  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  // ? Pagination state
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [curQuery, setCurQuery] = useState<any>({});

  // Contextual Menu state
  const {
    showContextMenu,
    setShowContextMenu,
    contextMenuPoints,
    setContextMenuPoints,
    selectedFieldName,
    setSelectedFieldName,
    selectedContextMenuAction,
    setSelectedContextMenuAction,
    groupingField,
    setGroupingField,
  } = useContextMenu();

  const [baseEntity, setBaseEntity] = useState<Entity.IBaseEntity | null>(null);
  const [datasource, setDataSource] = useState<string>("default");
  const [fields, setFields] = useState<Array<Field>>([]);
  const [data, setData] = useState<Array<Entity.IEntity>>([]);
  const [relationshipKeys, setRelationshipKeys] =
    useState<IServerResponse<RelationshipKey.IKey> | null>(null);

  const [secondaryEntityFields, setSecondaryEntityFields] = useState<any[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    limit: 10,
    hasPreviousPage: false,
    hasNextPage: false,
    previousPage: null,
    nextPage: null,
    startIndex: 0,
    numPages: 1,
    recordsTotal: 0,
  });

  const paginationElement = useMemo(
    () => (
      <Pagination
        last
        first
        ellipsis
        maxButtons={5}
        limitOptions={[10, 20, 30, 40, 50]}
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        activePage={page}
        limit={pagination.limit}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        next={pagination.hasNextPage}
        total={pagination.recordsTotal}
        prev={pagination.hasPreviousPage}
        locale={{
          total: t("label.table.total_records", {
            ns: "application.misc",
            total: "{0}",
          }),
          limit: t("label.table.limit_to", {
            ns: "application.misc",
            limit: "{0}",
          }),
          skip: t("label.table.skip", { ns: "application.misc", page: "{0}" }),
          first: t("label.table.first", { ns: "application.misc" }),
          prev: t("label.table.prev", { ns: "application.misc" }),
          next: t("label.table.next", { ns: "application.misc" }),
          last: t("label.table.last", { ns: "application.misc" }),
          more: t("label.table.more", { ns: "application.misc" }),
        }}
      />
    ),
    [
      page,
      pagination.hasNextPage,
      pagination.hasPreviousPage,
      pagination.limit,
      pagination.recordsTotal,
      t,
    ]
  );

  // ? --- Table state reducer ---
  const [tsr, setTableState] = useReducer(
    (state: ITableRSuiteStatus, status: string): ITableRSuiteStatus => {
      switch (status) {
        case "error":
          return {
            loading: false,
            empty: false,
            error: true,
          };
        case "empty":
          return {
            loading: false,
            empty: true,
            error: false,
          };
        case "loading":
          return {
            loading: true,
            empty: false,
            error: false,
          };
        case "ready":
        default:
          // status startsWith 'sorting'
          if (!status.startsWith("sorting")) {
            return {
              loading: false,
              empty: false,
              error: false,
            };
          }

          const [, sortingColumn, sortType] = status.split("|");
          const sortingType: SortType = sortType as SortType;

          return {
            ...state,
            sortingColumn,
            sortingType,
          };
      }
    },
    {
      loading: true,
      empty: false,
      error: false,
    }
  );

  const [viewList, setViewList] = useState<IView[]>([]);

  // ? --- Save current view for submit or state review ---
  const [fullQueryString, setFullQueryString] = useState<string>("");

  const [currentView, dispatchCurrentView] = useReducer(
    (state: any, data: any): any => ({ ...state, ...data }),
    {
      id: 0,
      name: "",
      description: "",
      entity: entity ?? "",
      fields: [],
      filters: [],
      relationships: [],
      grouping: {},
      sorting: [],
      search: "",
    }
  );

  // ? --- Get fields of current entity ---
  useLayoutEffect(() => {
    getFields({
      entity,
      fn: (...args: Array<any>) => void 0,
    }).then((response) => {
      setFields(response.data);
    });
  }, [entity, getFields]);

  // ? --- Get Relationship Data of current entity ---
  useLayoutEffect(() => {
    if (!entity) return;

    if (datasource !== "default") return;
    const fn = (response: IServerResponse<RelationshipKey.IKey>) =>
      setRelationshipKeys(response);
    getRelationshipsKeys({ entity, fn });
  }, [datasource, entity, getRelationshipsKeys]);

  // ? --- Get data of current entity ---
  useEffect(() => {
    let queryValues = {
      page,
      limit,
      entity,
      filter: fullQueryString,
    };
    if (isEqual(queryValues, curQuery)) {
      return;
    } else {
      setCurQuery(queryValues);
    }
    setTableState("loading");
    getAll({ ...queryValues, fn: (...args: Array<any>) => void 0 })
      .then((response) => {
        setTableState("loading");
        setDataSource(
          response.datasource !== undefined ? response.datasource : "default"
        );
        let cleanSecondaryFields: any;
        // TODO: secondaryFields should be used to set the secondary fields on the table
        if (datasource === "default") {
          cleanSecondaryFields = response.data
            .map(({ extra }) => extra)
            .filter((data) => data.length > 0)
            .flat()
            //? ------ makes sure there's no duplicated secondary field
            .filter(
              (v, i, a) =>
                a.findIndex((v2) =>
                  ["place", "name"].every((k) => v2[k] === v[k])
                ) === i
            );

          setSecondaryEntityFields(cleanSecondaryFields);
        }

        // ? --- If there is no data, set empty state ---
        // TODO: Fix this, response.data should be finalData and secondaryFields should be used
        response.data.forEach((item: Entity.IEntity, index: number) => {
          const finalObj: any = item;

          // ? --- makes sure that N/A is display in those records with no data
          // ? whenever another record is displaying data within an secondary field
          if (datasource === "default") {
            cleanSecondaryFields.forEach(
              (f: any) =>
                !item[f.secondName] && (finalObj[f.secondName] = "N/A")
            );
          }

          for (let key in item) {
            isNull(item[key as keyof Entity.IEntity])
              ? (finalObj[key] = "N/A")
              : (finalObj[key] = item[key as keyof Entity.IEntity]);
            const fieldToLook = fields.find((f) => `data_${f.name}` === key);

            if (fieldToLook) {
              switch (fieldToLook.type) {
                case "number":
                  isNaN(Number(finalObj[key]))
                    ? (finalObj[key] = "N/A")
                    : (finalObj[key] = Number(finalObj[key]));
                  break;
                case "float":
                  isNaN(Number(finalObj[key]))
                    ? (finalObj[key] = "N/A")
                    : (finalObj[key] = Number(finalObj[key]).toFixed(2));
                  break;
                case "boolean":
                  const booleanValue =
                    finalObj[key].toLowerCase() === "true" ? true : false;
                  finalObj[key] = booleanValue;
                  break;
                default:
                  break;
              }
            }
          }

          response.data[index] = finalObj;
        });

        if (
          fullQueryString.includes("grouping_field") &&
          groupingField !== ""
        ) {
          const convertToNestedArray = (arr: any) => {
            const map = new Map();
            const result: any[] = [];

            arr.forEach((obj: any) => {
              if (!map.has(obj[groupingField])) {
                let mapObj: any = { children: [] };
                mapObj[groupingField] = obj[groupingField];
                map.set(obj[groupingField], mapObj);
                result.push(map.get(obj[groupingField]));
              }
              map.get(obj[groupingField]).children.push(obj);
            });
            return result;
          };
          response.data = convertToNestedArray(response.data);
        }
        setData(response.data);

        if (response.pagination.currentPage > response.pagination.numPages) {
          response.pagination.currentPage = response.pagination.numPages;
          setPage(response.pagination.numPages);
        }
        setPagination(response.pagination);
        setTableState("ready");
      })
      .catch((error) => {
        // ! dispatch(activate(error));
        setTableState("error");
      });
  }, [
    page,
    limit,
    entity,
    datasource,
    getAll,
    fields,
    fullQueryString,
    relationshipKeys,
    groupingField,
    curQuery,
  ]);

  // ? --- Get data of current entity on filter state change ---
  useEffect(() => {
    try {
      /* const groupingData: GroupTable = {
        attrGroup: "",
        isTableTree: false,
        totalizers: [],
      }; */

      const queryStrings: any = {
        entity: entity ?? "",
        relationships: "&",
        grouping: "&",
        filters: "&",
        sorting: "&",
        fields: "&",
        search: "&",
      };

      const data = {
        entity: entity ?? "",
        relationships: currentView.relationships,
        filters: currentView.filters,
        sorting: currentView.sorting,
        fields: currentView.fields,
        search: currentView.search,
        grouping: currentView.grouping,
      };

      const table: ITableLayout = data as ITableLayout;

      for (const key in table) {
        switch (key) {
          case "filters":
            queryStrings.filters += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "key[]": item.field,
                    "operator[]": item.operator,
                    "value[]": item.value,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "relationships":
            queryStrings.relationships += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "relationFilter[]": item.relation,
                    "relationValue[]": item.value,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "fields":
            queryStrings.fields += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "fields[]":
                      item.indexOf("data_") === 0 ? item : `data_${item}`,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "sorting":
            queryStrings.sorting += table[key]
              .map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "sort_field[]": item.field,
                    "sort_direction[]": item.direction,
                  }).toString()
                )
              )
              .join("&");
            break;
          case "grouping":
            if (Array.isArray(table[key])) {
              queryStrings.grouping += table[key].map((item: any) =>
                decodeURIComponent(
                  new URLSearchParams({
                    "grouping_field[]": item.field,
                  }).toString()
                )
              );
            }
            break;
          case "search":
            if (table[key].length > 0) {
              queryStrings.search += decodeURIComponent(
                new URLSearchParams({
                  search: table[key],
                }).toString()
              );
            }
            break;
          case "entity":
            break;
          default:
            break;
        }
      }

      // check if the fields query string contains any field
      if (queryStrings.fields.indexOf("fields[]") !== -1) {
        queryStrings.filters += table.filters.map((item: any) =>
          "&".concat(
            decodeURIComponent(
              new URLSearchParams({
                "fields[]":
                  item.field.indexOf("data_") === 0
                    ? item.field
                    : `data_${item.field}`,
              }).toString()
            )
          )
        );

        queryStrings.sorting += table.sorting.map((item: any) =>
          "&".concat(
            decodeURIComponent(
              new URLSearchParams({
                "fields[]":
                  item.field.indexOf("data_") === 0
                    ? item.field
                    : `data_${item.field}`,
              }).toString()
            )
          )
        );
      }

      delete queryStrings.entity;

      // ? Convert the values to a query string and set state.

      const requestString = Object.values(queryStrings).join("&");
      let query = "";

      for (const segment of requestString.split("&")) {
        if (!segment.length) continue;

        const [key, value] = segment.split("=");
        if (!key.length || !value.length) continue;

        query += "&".concat(
          decodeURIComponent(
            new URLSearchParams({
              [key]: value,
            }).toString()
          )
        );
      }

      setFullQueryString(query);
    } catch (error: unknown) {
      console.error(error);
    }
  }, [currentView, datasource, entity]);

  // ? --- Get all custom views of current entity (type ITableLayout) ---
  useEffect(() => {
    const filter = [
      new URLSearchParams({
        type: "Entity",
      }),
    ];
    getAllEntityViews({
      callback: (response: IServerResponse<IView>): void =>
        setViewList(response.data),
      entity,
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  // ? --- Help with the sorting on column selection ---
  const onSortColumn = useCallback(
    (dataKey: string, order: "asc" | "desc" | undefined) => {
      const allFields = [
        ...fields,
        ...secondaryEntityFields.map((field) => ({
          ...field,
          name: field.secondName
            ? field.secondName.replace(/^data_/, "")
            : field.name,
        })),
      ];
      const field = allFields.find(
        (field) => field.name === dataKey.replace(/^data_/, "")
      );
      let sorted = [...data];
      if (field) {
        switch (field.type) {
          case "textarea":
          case "string":
          case "email":
          case "text":
          case "varchar":
          case "char":
          case "enum":
            setTableState("loading");
            sorted = data.sort((a, b) => {
              const valueA = toString(a[`data_${field.name}`]) || "";
              const valueB = toString(b[`data_${field.name}`]) || "";
              return valueA.localeCompare(valueB);
            });

            if (order === "asc") {
              sorted = sorted.reverse();
            }

            setData([...sorted]);
            setTableState("ready");
            setTableState(
              "sorting".concat(
                "|",
                "data_".concat(field.name),
                "|",
                order || "desc"
              )
            );
            break;
          case "decimal":
          case "integer":
          case "int":
          case "number":
          case "float":
          case "mediumint":
          case "smallint":
          case "tinyint":
          case "year":
            setTableState("loading");
            sorted = data.sort((a, b) => {
              const valueA = toNumber(a[`data_${field.name}`]) || 0;
              const valueB = toNumber(b[`data_${field.name}`]) || 0;
              return valueA - valueB;
            });

            if (order === "asc") {
              sorted = sorted.reverse();
            }

            setData([...sorted]);
            setTableState("ready");
            setTableState(
              "sorting".concat(
                "|",
                "data_".concat(field.name),
                "|",
                order || "desc"
              )
            );
            break;
          case "checkbox":
          case "boolean":
            setTableState("loading");
            sorted = data.sort((a, b) => {
              const valueA = Boolean(a[`data_${field.name}`] === "true");
              const valueB = Boolean(b[`data_${field.name}`] === "true");
              return valueA === valueB ? 0 : valueA ? 1 : -1;
            });

            if (order === "asc") {
              sorted = sorted.reverse();
            }

            setData([...sorted]);
            setTableState("ready");
            setTableState(
              "sorting".concat(
                "|",
                "data_".concat(field.name),
                "|",
                order || "desc"
              )
            );
            break;
          case "datetime":
          case "date":
          case "timestamp":
            setTableState("loading");
            sorted = data.sort((a, b) => {
              const valueA =
                Date.parse(a[`data_${field.name}`]) || new Date().getTime();
              const valueB =
                Date.parse(b[`data_${field.name}`]) || new Date().getTime();
              return valueA - valueB;
            });

            if (order === "asc") {
              sorted = sorted.reverse();
            }

            setData([...sorted]);
            setTableState("ready");
            setTableState(
              "sorting".concat(
                "|",
                "data_".concat(field.name),
                "|",
                order || "desc"
              )
            );
            break;
          default:
            break;
        }
      }
    },
    [data, fields, secondaryEntityFields]
  );

  // ? --- Handle apply of a custom view (type ITableLayout) ---
  const handleApplyView = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // resetDataTable();
      const view: IView | undefined = viewList.find(
        (item: IView) => item.id === currentView.id
      );
      if (view) {
        const { data } = view.view_data.widgets[0];
        const table: ITableLayout = data as ITableLayout;
        dispatchCurrentView({
          entity: entity,

          id: view.id || 0,
          name: view.name || "",
          description: view.description || "",

          search: table.search || "",
          fields: table.fields || [],
          filters: table.filters || [],
          sorting: table.sorting || [],
          grouping: table.grouping || {},
          relationships: table.relationships || [],
        });
      }
    },
    [currentView, dispatchCurrentView, entity, viewList]
  );

  // ? --- Handle create of a custom view (type ITableLayout) ---
  const handleCustomViewSubmit = useCallback(
    (
      e: React.FormEvent<HTMLFormElement>,
      viewNameInput: string,
      viewDescriptionInput: string
    ) => {
      e.preventDefault();
      dispatchCurrentView({
        name: viewNameInput,
        description: viewDescriptionInput,
      });
      let dataGroupBy: GroupTable = {
        totalizers: [], // totalizerOperations,
        attrGroup: "", // attrMain,
        isTableTree: true,
      };
      const view: IView = {
        active: true,
        shareable: false,
        name: viewNameInput,
        description: viewDescriptionInput,
        view_type: "Entity",
        view_data: {
          columns: 1,
          widgets: [
            {
              type: "TableLayout",
              data: {
                entity: currentView.entity,
                fields: currentView.fields,
                filters: currentView.filters,
                relationships: currentView.relationships,
                sorting: currentView.sorting,
                grouping: dataGroupBy,
                search: currentView.search,
              },
            },
          ],
        },
      };
      function callback(response: IServerResponse<IView>): void {
        dispatch(activate({ status: true, name: "View" }));
        setTimeout(() => {
          dispatch(inactivated());
        }, 3000);

        const filter = [
          new URLSearchParams({
            type: "Entity",
          }),
        ];
        getAllEntityViews({
          callback: (response: IServerResponse<IView>): void =>
            setViewList(response.data),
          entity,
          filter,
        });
      }

      createEntityView({
        data: view,
        callback,
        entity,
      });
    },
    [
      currentView,
      dispatch,
      entity,
      getAllEntityViews,
      setViewList,
      createEntityView,
    ]
  );

  // ? --- Handle navigate to specific entity ---
  const handleNavigate = useCallback(
    (rowData: RowDataType) => {
      switch (datasource) {
        case "default":
          if (rowData.id) {
            navigate("/".concat(entity, "/show/", rowData.id));
          }

          break;
        default:
          const unique = fields.find((field) => field.unique);
          if (!unique) return;

          const key: Entity.keyPrefix<"data_", string> = `data_${unique.name}`;
          if (!rowData[key]) return;

          navigate("/".concat(entity, "/show/", rowData[key]));
      }

      return void 0;
    },
    [datasource, entity, fields, navigate]
  );

  // *** Render developer log ***

  // ? FE - Fix z-index bug on filter header.
  // * [data-render-selector="entitytable.filterheader"] {
  // * position: "relative"
  // * zIndex: 15
  // * }
  const titleCaption = useMemo(() => {
    if (!entity) return "";

    let value = entity;
    value = tWithEntityNamespace("label");
    value = trim(value);
    value = capitalize(value);
    value = value.concat(
      baseEntity?.use_adapter ? `(${baseEntity?.datasource})` : ""
    );

    return value;
  }, [
    baseEntity?.datasource,
    baseEntity?.use_adapter,
    entity,
    tWithEntityNamespace,
  ]);

  useEffect(() => {
    getEntityProperties({ entity, fn: () => {} }).then((response) => {
      if (response.data.length) {
        const [base] = response.data;
        setBaseEntity(base);
      }
    });
  }, [entity, getEntityProperties]);

  return (
    <context.Provider
      value={{
        dispatchCurrentView,
        handleCustomViewSubmit,
        handleApplyView,
        currentView,
        viewList,
        entity,
        fields,
        rows: data,
      }}
    >
      <Container fluid className="g-0 m-0 p-0">
        <Row
          className="d-flex flex-nowrap flex-column flex-md-row"
          data-render-selector="entitytable.filterheader"
          style={{ position: "relative", zIndex: 15 }}
        >
          <Col className="g-0 m-0 p-1 d-flex flex-nowrap justify-content-start align-items-center">
            <h2 children={titleCaption} />
          </Col>
          <Col className="g-0 m-0 p-1">
            {/** ? Requires mainAttr ? */}
            <FilterColumns
              fields={{ data: fields }}
              selected={currentView.fields}
              setFiltersColumnsData={(data: any) => {
                dispatchCurrentView({
                  fields: data,
                });
              }}
              entity={entity}
            />
          </Col>
          <Col
            className="g-0 m-0 p-1 d-flex flex-wrap flex-md-nowrap justify-content-around"
            style={{ flex: 2 }}
          >
            <SortData
              setFiltersColumnsData={(data: any) => {
                dispatchCurrentView({
                  fields: data,
                });
              }}
              setSortData={(data: any) => {
                dispatchCurrentView({
                  sorting: data,
                });
              }}
              selected={currentView.sorting}
              fields={{ data: fields, selected: currentView.fields }}
              entity={entity}
            />
          </Col>
          <Col className="g-0 m-0 p-1">
            <SearchBar
              setSearchBar={(data: string) => {
                dispatchCurrentView({
                  search: data,
                });
              }}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-nowrap flex-column flex-md-row">
          <Col className="g-0 m-0 p-1 order-2 order-md-1">
            <Table
              className="w-100 h-100"
              renderEmpty={() => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {t("entity_table.empty_entity")}
                </div>
              )}
              sortColumn={tsr.sortingColumn}
              onSortColumn={onSortColumn}
              onRowClick={handleNavigate}
              sortType={tsr.sortingType}
              loading={tsr.loading}
              data={data}
              autoHeight
              isTree={groupingField !== ""}
              defaultExpandAllRows={true}
              rowKey={selectedFieldName}
              locale={{
                emptyMessage: t("entity_table.no_data"),
                loading: t("entity_table.loading"),
              }}
            >
              {datasource === "default" && (
                <Table.Column align="center" fixed>
                  <Table.HeaderCell>
                    {capitalize(t("entity_table.id")).trim()}
                  </Table.HeaderCell>
                  <Table.Cell dataKey="id" />
                </Table.Column>
              )}
              {fields.map((field) => (
                <Table.Column
                  key={field.name}
                  width={250}
                  sortable={!["set", "blob"].includes(field.type)}
                >
                  <Table.HeaderCell>
                    {capitalize(
                      tWithEntityNamespace("fields.".concat(field.name)).trim()
                    )}
                  </Table.HeaderCell>
                  <Table.Cell
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowContextMenu(true);
                      setContextMenuPoints({
                        x: e.pageX,
                        y: e.pageY,
                      });
                      setSelectedFieldName(`data_${field.name}`);
                    }}
                    dataKey={"data_".concat(field.name)}
                    renderCell={(cellData: any) => {
                      try {
                        if (typeof cellData === "boolean") {
                          return <Form.Switch checked={cellData} disabled />;
                        }

                        if (typeof cellData !== "object") {
                          return cellData;
                        }

                        return JSON.stringify(cellData);
                      } catch (error: unknown) {
                        console.error(error);
                        return "N/A";
                      }
                    }}
                  />
                </Table.Column>
              ))}
              {datasource !== "default" ??
                secondaryEntityFields.map((field) => (
                  <Table.Column key={field.secondName} width={250} sortable>
                    <Table.HeaderCell>
                      {" "}
                      {capitalize(field.secondName).trim()}{" "}
                    </Table.HeaderCell>
                    <Table.Cell
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setShowContextMenu(true);
                        setContextMenuPoints({
                          x: e.pageX,
                          y: e.pageY,
                        });
                        setSelectedFieldName(`data_${field.name}`);
                      }}
                      dataKey={field.secondName}
                    ></Table.Cell>
                  </Table.Column>
                ))}

              {datasource === "default" && (
                <Table.Column width={250}>
                  <Table.HeaderCell>
                    {capitalize(t("entity_table.date_created")).trim()}
                  </Table.HeaderCell>
                  <Table.Cell
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowContextMenu(true);
                      setContextMenuPoints({
                        x: e.pageX,
                        y: e.pageY,
                      });
                      setSelectedFieldName(`date_created`);
                    }}
                    dataKey="date_created"
                  />
                </Table.Column>
              )}
              {datasource === "default" && (
                <Table.Column width={250}>
                  <Table.HeaderCell>
                    {capitalize(t("entity_table.date_updated")).trim()}
                  </Table.HeaderCell>
                  <Table.Cell
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setShowContextMenu(true);
                      setContextMenuPoints({
                        x: e.pageX,
                        y: e.pageY,
                      });
                      setSelectedFieldName(`date_updated`);
                    }}
                    dataKey="date_updated"
                  />
                </Table.Column>
              )}
            </Table>
            <Row className="" children={paginationElement} />
          </Col>
          <Col
            className="g-0 m-0 p-1 order-1 order-md-2"
            xxl={2}
            xl={3}
            lg={3}
            md={3}
          >
            <ExportExcel
              data={data}
              columns={fields}
              sheetName={entity}
              isTree={true && false}
              buttonText={t("entity_table.export_to", { destination: "Excel" })}
              fileName={entity.concat("_", new Date().toISOString())}
            />
            <FiltersAccordion />
          </Col>
        </Row>
      </Container>

      <Modal
        onContextMenu={(e: MouseEvent) => e.preventDefault()}
        show={showContextMenu}
        className={classes.menu}
        style={{ top: contextMenuPoints.y, left: contextMenuPoints.x }}
      >
        <Modal.Body>
          <ul>
            <li
              onClick={() => {
                if (selectedContextMenuAction !== "group") {
                  setSelectedContextMenuAction("group");
                  setGroupingField(selectedFieldName);
                  dispatchCurrentView({
                    grouping: [{ field: selectedFieldName }],
                  });
                } else {
                  dispatchCurrentView({ grouping: [] });
                  setSelectedContextMenuAction("");
                  setGroupingField("");
                }
              }}
            >
              {selectedContextMenuAction !== "group"
                ? capitalize(t("entity_table.group_by_label")).trim()
                : capitalize(t("entity_table.ungroup_by_label")).trim()}
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </context.Provider>
  );
};

export default withTranslation()(EntityTable);
