import React from "react";
import { useTranslation } from "react-i18next";
import MoreButton from "./MoreButton";

export default function ShowDomains(props:any) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <tr
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(12,1fr)",
          marginBottom:'1em'
        }}
      >
        <td style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
          <div style={{ display: "flex" }}>
            <MoreButton onClick={() => {props.onShowDomains()}}></MoreButton>
          </div>
        </td>
        <td style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            <strong> {t("domains")}</strong>
          </label>
        </td>
      </tr>
    </React.Fragment>
  );
}
