import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import capitalize from "lodash/capitalize";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import useEntityService from "./../../../services/entity.service";
import {
  Field,
  Validation,
  Entity,
  RelationshipKey,
  IServerResponse,
} from "../../../interfaces";
import DymSelect from "../Input/DymSelect";
import Input from "../Input/Input";

interface DymFormProps {
  type: "POST" | "PUT";

  onSubmit: (...args: any[]) => any;
  fetchNewData: (...args: any[]) => any;
  setExternalErrors: (errors: Array<string>) => any;

  originRelationship?: Array<RelationshipKey.ILinkedRelationship>;
  entity: string;
  id?: string | number;

  data?: Entity.IEntity | any;
}

const DymForm: React.FC<DymFormProps> = ({
  id,
  entity,
  type,
  originRelationship,
  fetchNewData,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  const {
    createRecord,
    updateRecord,
    deleteRecord,
    getSingleRecordWithRelationships,
    getRelationshipsKeys,
    validateRecord,
    getFields,
  } = useEntityService();

  const [modified, setModified] = useState<any>({});
  const [fields, setFields] = useState<Array<Field>>([]);
  const [state, setState] = useState<Entity.IEntity | null>(null);
  const [errors, setErrors] = useState<Array<Validation.Result>>([]);
  const [relationshipsKeys, setRelationshipsKeys] =
    useState<RelationshipKey.IKey>({});
  const [relationships, setRelationships] = useState<{
    [key: string]: Array<Entity.IEntity>;
  }>({});

  const [datasource, setDatasource] = useState<string>("");

  const payload = useMemo(() => {
    const payload: any = {
      relationships: [],
    };

    if (state) {
      for (const key in state) {
        if (key.startsWith("data_")) {
          const suffix = key.replace(/^data_/i, "");
          // Gets the field that matches the suffix of the property createad on payload
          const matchingField = fields.find((field) => field.name === suffix);
          // Only added to payload if field is not empty and has no default value
          if (
            state[`data_${suffix}`] !== "" &&
            matchingField?.default_value !== ""
          ) {
            payload[suffix] = state[`data_${suffix}`];
          }
        }
      }
    }

    const modifiedObject = Object.keys(modified).reduce((current, key) => {
      if (modified[key] === undefined) {
        modified[key] = state?.[`data_${key}`];
        return modified;
      }
      return modified;
    }, modified);

    if (Object.keys(modifiedObject).length) {
      for (const key in modifiedObject) {
        payload[key] = modifiedObject[key];
      }
    }

    for (const relationship_name in relationshipsKeys) {
      const related =
        relationshipsKeys[relationship_name].l_entity === entity
          ? relationshipsKeys[relationship_name].r_entity
          : relationshipsKeys[relationship_name].l_entity;

      const listOfRelationships = relationships.hasOwnProperty(
        relationship_name
      )
        ? relationships[relationship_name]
        : [];

      payload.relationships.push({
        data: listOfRelationships,
        name: relationship_name,
        entity: related,
      });
    }

    if (originRelationship) {
      for (const relationship of originRelationship) {
        payload.relationships.push(relationship);
      }
    }

    return payload;
  }, [
    state,
    modified,
    originRelationship,
    fields,
    relationshipsKeys,
    entity,
    relationships,
  ]);

  const handleValidate = useCallback(
    async (entity) => {
      const response = await validateRecord({
        fn: () => void 0,
        entity,
        data: payload,
      });

      return response.errors;
    },
    [payload, validateRecord]
  );

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      // eslint-disable-next-line react-hooks/exhaustive-deps
      type = type ?? "POST";

      if (typeof entity !== "string") {
        throw new Error("Cannot submit form without entity defined.");
      }

      const errors = await handleValidate(entity);
      if (errors.length) {
        console.error(errors);
        setErrors(errors);
        return;
      }

      switch (type) {
        case "POST":
          const post = await createRecord({
            fn: (post: IServerResponse<Entity.IEntity>) => {
              const { datasource } = post;
              switch (datasource) {
                case "default":
                  const [{ id }] = post.data;
                  if (originRelationship) {
                    onSubmit(id);
                    return;
                  }
                  navigate(`/${entity}/show/${id}`);
                  break;
                default:
                  const unique = fields.find((field) => field.unique);
                  if (unique) {
                    const [created] = post.data as any;
                    const id = created[unique.name];
                    if (originRelationship) {
                      onSubmit(id);
                      return;
                    }

                    if (id) {
                      navigate(`/${entity}/show/${id}`);
                    }
                  } else {
                    navigate(`/${entity}`, { state: { data: post.data } });
                  }
              }

              fetchNewData();
            },
            data: payload,
            entity,
          });

          if (post.error) {
            console.error(post.errors);
            return;
          }
          break;
        case "PUT":
          const put = await updateRecord({
            fn: () => void 0,
            data: payload,
            entity,
            id,
          });
          if (put.error) {
            console.error(put.errors);
            return;
          }
          fetchNewData();
          navigate(`/${entity}/show/${id}`);
          break;
        default:
          throw new Error(`The method ${type} is not implemented.`);
      }
    },
    [
      createRecord,
      navigate,
      relationships,
      relationshipsKeys,
      updateRecord,
      payload,
    ]
  );

  const handleCancel = useCallback(() => {
    switch (type) {
      case "POST":
        navigate(`/${entity}`);
        break;
      case "PUT":
        navigate(`/${entity}/show/${id}`);
        break;
      default:
        throw new Error(`The method ${type} is not implemented.`);
    }
  }, [navigate, type, entity, id]);

  const handleDelete = useCallback(() => {
    deleteRecord({
      fn: () => void 0,
      entity,
      id,
    })
      .then((response) => {
        if (response.error) {
          console.error(response.errors);
          return;
        }
        navigate("/".concat(entity));
      })
      .catch((error) => console.error(error));
  }, [deleteRecord, entity, id, navigate]);

  const inputs = useMemo<Array<React.ReactElement>>(() => {
    let items: Array<Field> = [];
    items = fields.sort((a, b) => a.name.localeCompare(b.name));

    return items.map((field, index): JSX.Element => {
      const name = field.name.toLowerCase();
      const isRequired = field.required ? "*" : "";
      const placeholder = tWithEntityNamespace("fields.".concat(field.name));
      const label = tWithEntityNamespace("fields.".concat(field.name)).concat(
        " ",
        isRequired
      );
      return (
        <Col
          xxl={field.size === 0 ? 4 : 12 / field.size}
          xl={field.size === 0 ? 4 : 12 / field.size}
          lg={field.size === 0 ? 4 : 12 / field.size}
          md={field.size === 0 ? 6 : 12 / field.size}
          sm={field.size === 0 ? 12 : 12 / field.size}
          xs={field.size === 0 ? 12 : 12 / field.size}
          key={field.id || index}
        >
          {type === "POST" && (
            <Input
              issues={errors
                .map((error) => (error.field === name ? error.issues : []))
                .flat()}
              label={tWithEntityNamespace("fields.".concat(field.name))}
              input={{
                ...field,
                name: name,
                label: label,
                placeholder: placeholder,
              }}
              placeholder={placeholder}
              onChange={setModified}
              value={
                field.type === "boolean"
                  ? /^(true|false)$/.test(state?.[`data_${name}`])
                  : ""
              }
            />
          )}
          {type === "PUT" && (
            <Input
              issues={errors
                .map((error) => (error.field === name ? error.issues : []))
                .flat()}
              label={tWithEntityNamespace("fields.".concat(field.name))}
              input={{ ...field, name: name, label: label }}
              value={state?.[`data_${name}`]}
              placeholder={placeholder}
              onChange={setModified}
            />
          )}
        </Col>
      );
    });
  }, [fields, tWithEntityNamespace, type, errors, state]);

  useEffect(() => {
    if (typeof entity !== "string") {
      throw new Error(
        "[DymForm::getFields] Load cannot continue without entity defined."
      );
    }

    getFields({
      fn: () => void 0,
      entity,
    })
      .then((response) => {
        if (response.error) {
          console.error(response.errors);
          return;
        }
        const fixedFields = response.data.map((field) => {
          if (field.type === "boolean") return { ...field };
          return field;
        });

        setFields(fixedFields);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [getFields, entity]);

  useEffect(() => {
    if (typeof entity !== "string") {
      throw new Error(
        "[DymForm::getRelationshipKeys] Load cannot continue without entity defined."
      );
    }

    getRelationshipsKeys({
      fn: () => void 0,
      entity,
    })
      .then((response) => {
        const [keys] = response.data;
        setRelationshipsKeys(keys);
      })
      .catch(console.error);
  }, [entity, getRelationshipsKeys]);

  useEffect(() => {
    if (!id && type === "PUT") {
      throw new Error(
        "[DymForm::getSingleRecordWithRelationships] Load for PUT method cannot continue without id defined."
      );
    }

    if (typeof entity !== "string") {
      throw new Error(
        "[DymForm::getSingleRecordWithRelationships] Load cannot continue without entity defined."
      );
    }

    if (type === "PUT") {
      getSingleRecordWithRelationships({
        fn: () => void 0,
        entity,
        id,
      })
        .then((response) => {
          if (response.error) {
            console.error(response.errors);
            return;
          }

          const [record] = response.data;
          setDatasource(response.datasource);
          setState(record);
        })
        .catch(console.error);
    }
  }, [entity, getSingleRecordWithRelationships, id, type]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="m-2">{inputs}</Row>

      {type === "POST" && !originRelationship && (
        <Row className="m-2">
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            {Object.keys(relationshipsKeys).map((relationship_name, index) => {
              const related =
                relationshipsKeys[relationship_name].l_entity === entity
                  ? relationshipsKeys[relationship_name].r_entity
                  : relationshipsKeys[relationship_name].l_entity;

              return (
                <DymSelect
                  key={index}
                  targetEntity={related}
                  onChange={setRelationships}
                  relationshipsKeys={relationshipsKeys[relationship_name]}
                />
              );
            })}
          </Col>
        </Row>
      )}

      <Row className="m-2">
        <Col
          xxl={4}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className="d-flex justify-content-end w-100 my-2"
        >
          <ButtonGroup>
            {id && type === "PUT" && (
              <Button
                variant="secondary"
                className="mr-2"
                onClick={handleCancel}
              >
                {capitalize(
                  t("label.action.cancel", { ns: "application.misc" })
                )}
              </Button>
            )}

            {id && type === "PUT" && datasource === "default" && (
              <Button variant="danger" className="mr-2" onClick={handleDelete}>
                {capitalize(
                  t("label.action.delete", { ns: "application.misc" })
                )}
              </Button>
            )}

            <Button variant="primary" type="submit">
              {capitalize(
                t(
                  type === "POST"
                    ? "label.action.create"
                    : type === "PUT"
                    ? "label.action.update"
                    : "label.action.submit",
                  { ns: "application.misc" }
                )
              )}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default DymForm;
