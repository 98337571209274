import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TFunction,
  Trans,
  useTranslation,
  withTranslation,
} from "react-i18next";

import { capitalize } from "lodash";
import { ArrowLeft } from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

import RelationTable from "../../../UI/RelationTable/RelationTable";
import useEntityService from "../../../../services/entity.service";
import useLocalStorage from "../../../../hook/useLocalStorage";
import RelationshipCard from "./RelationshipCard";
import PropertyCard from "./PropertyCard";
import {
  Field,
  Entity,
  IServerResponse,
  RelationshipKey,
  Domain,
} from "src/interfaces";
import { useAuth } from "src/hook/useAuth";
import { useAppSelector } from "src/app/hooks";
import { selectDomain } from "src/reducers/domain/domainSlice";

interface SingleEntityProps {
  children?: React.ReactNode;
  t: TFunction;
}

const SingleEntity = withTranslation()(({ t }: SingleEntityProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { entity, id } = useParams();
  const {
    getSingleRecordWithRelationships,
    getRelationshipsKeys,
    getFields,
    updateDomainRecord,
  } = useEntityService();

  const [namespace, setNamespace] = useState<string | undefined>();
  const { t: tWithEntityNamespace } = useTranslation(namespace);

  useLayoutEffect(() => {
    if (typeof entity === "string") {
      setNamespace("entities.".concat(entity));
    }
  }, [entity]);

  const { storedValue: entityFields, storeValue: setEntityFields } =
    useLocalStorage<Array<Field>>("SingleEntity.Fields", []);

  const [datasource, setDatasource] = useState<string>("");

  const [entityData, setEntityData] = useState<Entity.IEntity>();

  const [keyRelationships, setKeyRelationships] =
    useState<RelationshipKey.IKey>({});

  const [openRelationships, setOpenRelationships] = useState<string[]>([]);

  // ? Memoize properties array (from entity) to render.
  const properties = useMemo(() => {
    if (!entityFields || !entityData) return [];

    const properties: Array<JSX.Element> = [];
    for (const field of entityFields) {
      properties.push(
        <Col
          xxl="2"
          xl="3"
          lg="4"
          md="12"
          sm="12"
          key={field.name}
          className="p-2 g-0"
          children={
            <PropertyCard
              field={field}
              value={entityData?.[`data_${field.name}`]}
              cardtype={field.indexable ? "key" : "entity"}
              entity={entity}
            />
          }
        />
      );
    }

    return properties.flat();
  }, [entityFields, entityData, entity]);

  const relationships = useMemo(() => {
    if (!entityData || !keyRelationships) return [];

    // ? Relationship extra fields.
    if (!entityData.extra?.length) return [];

    // ? Relationship data.
    if (!entityData?.relationships?.length) return [];

    const relationships: Array<Array<JSX.Element>> = [];
    for (let index = 0; index < entityData.extra.length; index++) {
      const { name, data: fields } = entityData.extra[index];
      const keys = keyRelationships[name];
      if (!keys) continue;

      const found = entityData.relationships?.find(
        (relationship) => relationship.name === name
      );
      if (!found) continue;

      const [relationship] = found.data ?? [];
      if (!relationship) continue;

      const items: Array<JSX.Element> = [];
      let linkedEntity: string = "";

      switch (keys.type) {
        case "one-to-one":
        case "one-to-many":
          if (entity === keys.l_entity) continue;
          linkedEntity = keys.l_entity;
          break;
        case "many-to-one":
          if (entity === keys.r_entity) continue;
          linkedEntity = keys.r_entity;
          break;
        case "many-to-many":
        default:
          continue;
      }

      fields.forEach((field, index) => {
        items.push(
          <Col
            xxl="2"
            xl="3"
            lg="4"
            md="12"
            sm="12"
            key={index}
            className="p-2 g-0"
            children={
              <PropertyCard
                field={field}
                cardtype="relationship"
                extraEntityName={linkedEntity}
                value={relationship[`data_${field.name}`]}
                extra={{ relationship: keys.name, entity: linkedEntity }}
                entity={entity}
              />
            }
          />
        );
      });

      relationships.push(items);
    }

    return relationships.flat();
  }, [entity, entityData, keyRelationships]);

  const domain = useAppSelector(selectDomain);

  const domains = useMemo(() => {
    try {
      if (user.domains instanceof Array) {
        return (user.domains as Array<Domain.IDomain>)
          .sort((a, b) => Number(a.id) - Number(b.id))
          .filter((userDomain) => Number(domain.id) !== userDomain.id);
      }
      throw new Error(
        "[Navbar::domains] Instance of Array<Domain.IDomain> expected on response."
      );
    } catch (error) {
      console.error(error);
      return [] as Array<Domain.IDomain>;
    }
  }, [user.domains, domain.id]);

  const handleToggleRelationship = useCallback(
    (relationship: string) => {
      setOpenRelationships((prev) => {
        return prev.includes(relationship)
          ? prev.filter((rel) => rel !== relationship)
          : [...prev, relationship];
      });
    },
    [setOpenRelationships]
  );

  const handleReloadSingleEntity = useCallback(() => {
    if (!entity || !id) return;

    const fn = (response: IServerResponse<Entity.IEntity>) => {
      const [entityData] = response.data;
      setDatasource(response.datasource);
      setEntityData(entityData);
    };
    getSingleRecordWithRelationships({ entity, id: id, fn });

    setOpenRelationships([]);
  }, [entity, getSingleRecordWithRelationships, id]);

  // ? Effect to apply the entity data.
  useEffect(handleReloadSingleEntity, [
    entity,
    id,
    getSingleRecordWithRelationships,
    handleReloadSingleEntity,
  ]);

  // ? Effect to apply the entity fields.
  useEffect(() => {
    if (!entity) return;
    const fn = (response: IServerResponse<Field>) =>
      setEntityFields<Array<Field>>(response.data);
    getFields({ entity, fn });

    // ! Infinite loop applied if setEntityFields is a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, getFields]);

  // ? Effect to apply the key relationships.
  useEffect(() => {
    if (!entity) return;
    const fn = (response: IServerResponse<RelationshipKey.IKey>) => {
      const [keyRelationships] = response.data;
      setKeyRelationships(keyRelationships);
    };
    getRelationshipsKeys({ entity, fn });
  }, [entity, getRelationshipsKeys]);

  const onChangeDomain = useCallback(
    async (domain: Domain.IDomain) => {
      if (!entity || !id) return;
      await updateDomainRecord({
        fn: () => void 0,
        data: {
          domain: domain.id,
        },
        entity,
        id: Number(id),
      });

      return navigate(`/${entity}`);
    },
    [entity, id, navigate, updateDomainRecord]
  );

  // ! The object name and the id are required to render.
  if (!entity || !id) return null;

  return (
    <Container fluid className="p-4">
      <Row className="d-flex justify-content-between">
        <Col xs="2">
          <Button
            onClick={() => navigate(`/${entity}`)}
            variant="outline-secondary"
          >
            <ArrowLeft />{" "}
            {" ".concat(t("label.action.return", { ns: "application.misc" }))}
          </Button>
        </Col>
        {domains.length > 1 && datasource === "default" && (
          <Col xs="2">
            <Dropdown className="w-100">
              <Dropdown.Toggle variant="success">
                {t("label.action.move_to_another_domain", {
                  ns: "application.misc",
                })}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {domains.map((domain) => (
                  <Dropdown.Item
                    key={domain.name}
                    onClick={() => onChangeDomain(domain)}
                  >
                    {domain.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}

        <Col xs="2">
          <Button
            variant="primary"
            className="w-100"
            children={t("label.action.modify", {
              ns: "application.misc",
            }).concat(" ", tWithEntityNamespace("label").toLowerCase())}
            onClick={() => navigate(`/${entity}/edit/${id}`)}
          />
        </Col>
      </Row>

      <Row className="m-1">
        <Col className="p-2 text-truncate">
          <h1>{capitalize(tWithEntityNamespace("label") || "Entity")}</h1>
        </Col>
      </Row>

      <Row className="m-1" children={properties} />
      <Row className="m-1" children={relationships} />

      <Row className="m-1">
        <Col className="p-2 text-truncate">
          <h1>
            {t("relationship.relationship", {
              ns: "application.misc",
              count: Object.keys(keyRelationships).length,
            })}
          </h1>
        </Col>
      </Row>

      <Row
        className="m-1"
        children={entityData?.relationships?.map((relationship, index) => (
          <Col
            key={index}
            className="p-2 g-0"
            xxl="2"
            xl="3"
            lg="4"
            md="12"
            sm="12"
          >
            <RelationshipCard
              onClick={() => handleToggleRelationship(relationship.name)}
              selected={openRelationships.includes(relationship.name)}
              amount={relationship.pagination.recordsTotal}
              name={relationship.name}
              index={index}
              entityName={entity}
            />
          </Col>
        ))}
      />

      <Row
        className="m-1"
        children={[
          !openRelationships.length && (
            <div className="alert alert-info text-center m-3">
              <Trans
                i18nKey="label.help.no_relationship_selected"
                components={{ b: <b /> }}
                ns="application.misc"
              />
            </div>
          ),
          openRelationships.map((relationship, index) => (
            <RelationTable
              handleReloadSingleEntity={handleReloadSingleEntity}
              relationship={keyRelationships[relationship]}
              item={relationship}
              key={index}
            />
          )),
        ]}
      />
    </Container>
  );
});

export default SingleEntity;