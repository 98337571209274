import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { DomainItemType, UsersByDomainType } from "src/components/pages/Config/Domains/types";

interface DomainsState {
    domainIdSelected: number,
    domainList: DomainItemType[] | [],
    usersByDomain: UsersByDomainType[],
}

interface UpdateDomainState {
    domain: DomainItemType
}

const initialState: DomainsState = {
    domainIdSelected: 0,
    domainList: [],
    usersByDomain: [],
};

const setSubDomain = (domains: DomainItemType[], domain: DomainItemType) => {
    const domainIndex = domains.findIndex(d => Number(d.id) === Number(domain.id));
    if (domainIndex > -1) {
        domains[domainIndex] = domain;
    } else {
        for (let d of domains) {
            if (d?.subDomains?.length) {
                setSubDomain(d.subDomains, domain);
            }
        }
    }
    return domains;
};

const domainsSlice = createSlice({
    name: 'domains',
    initialState,
    reducers: {
        // setDomainSelected
        setDomainIdSelected(state, action: PayloadAction<number>) {
            state.domainIdSelected = action.payload;
        },
        setDomainList(state, action: PayloadAction<DomainItemType[]>) {
            state.domainList = action.payload;
        },
        updateDomain(state, action: PayloadAction<UpdateDomainState>) {
            const { domain } = action.payload;
            let domains = cloneDeep(state.domainList);
            domains = setSubDomain(domains.filter(domain => domain.parent_id === 0), domain);
            state.domainList = domains;
        },
        // removeDomainSelected
        removeDomainSelected(state) {
            state.domainIdSelected = 0;
        },
        setUsersByDomain(state, action: PayloadAction<UsersByDomainType[]>) {
            state.usersByDomain = action.payload;
        },
        // clear
        clearDomainState(state) {
            state = initialState;
        },
    }
});

export const { setDomainIdSelected, setDomainList, updateDomain, removeDomainSelected, setUsersByDomain, clearDomainState } = domainsSlice.actions;

export default domainsSlice.reducer;